import styled from "styled-components";

let randomColor = ["#9A96F9", "#82C4BB", "#F9828B", "#3FADB1"];

export const TableDateContainer = styled.div`
  display: grid;
  padding-left: 30px;
  border-left: 3px solid ${(props) => randomColor[props.index % 4]};
  margin-top: 20px;
`;
export const TableWeeklyContainer = styled.div`
  display: grid;
  /* border-left: 3px solid ${(props) => randomColor[props.index % 4]}; */
  margin-top: 20px;
`;
export const TimeActivityTableRow = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.manual
      ? `2.5fr 1.5fr 1.5fr 180px 2fr`
      : props.plan && props.plan === "monitored"
      ? `2.5fr 130px  minmax(180px, 2.5fr) minmax(120px, 1fr) minmax(120px, 1fr) 100px`
      : `350px 150px`};
  grid-column-gap: 10px;
  justify-content: ${({ plan }) =>
    plan && plan === "unmonitored" ? "start" : "space-between"};
  align-items: center;
  padding-bottom: ${(props) => props.title && `10px`};
  :nth-of-type(even) {
    background-color: ${(props) => !props.title && `#f8f9fc`};
  }
  @media (max-width: 1366px) {
    grid-template-columns: ${(props) =>
      props.manual && `2.7fr 1.3fr 1.3fr 140px 1.8fr`};
  }
`;
export const TimeActivityWeeklyTableRow = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.manual
      ? `2.5fr 1.5fr 1.5fr 180px 2fr`
      : props.plan && props.plan === "monitored"
      ? `2.5fr 130px 2fr 1.5fr 1.5fr 120px`
      : `350px 150px 200px`};
  grid-column-gap: 10px;
  justify-content: ${({ plan }) =>
    plan && plan === "unmonitored" ? "start" : "space-between"};
  align-items: center;
  padding-bottom: ${(props) => props.title && `10px`};
  :nth-of-type(even) {
    background-color: ${(props) => !props.title && `#f8f9fc`};
  }
  @media (max-width: 1366px) {
    grid-template-columns: ${(props) =>
      props.manual && `2.7fr 1.3fr 1.3fr 140px 1.8fr`};
  }
`;

export const CustomReportTableRow = styled.div`
  display: flex;
  /* grid-template-columns: ${(props) =>
    `250px 250px 120px 200px 120px 120px 120px 200px 120px 120px 120px 130px auto`}; */
  /* grid-column-gap: 10px; */
  gap: 10px;
  padding-left: 0px;
  justify-content: start;
  align-items: center;
  padding-bottom: ${(props) => props.title && `10px`};
  :nth-of-type(even) {
    background-color: ${(props) => !props.title && `#f8f9fc`};
  }
  /* width: 900px; */
`;

export const ColoredLine = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.border.secondary};
  margin-right: -30px;
`;
export const TableItem = styled.div`
  display: grid;
  padding: 12px 12px 12px 15px;
  align-content: center;
  width: ${({ width }) => width};
  @media (max-width: 1600px) {
    padding: ${(props) => props.manual && `12px 5px 12px 10px`};
  }
`;
export const TableTitleItem = styled.div`
  display: grid;
  padding: 0 12px 0px 15px;
  align-content: flex-start;
  width: ${({ width }) => width};
  @media (max-width: 1600px) {
    padding: ${(props) => props.manual && `0 5px 0px 10px`};
  }
`;
export const GraphContainer = styled.div`
  display: grid;
  grid-template-columns: 80%;
  justify-content: flex-start;
  width: 100%;
  max-width: 700px;
  padding: 40px 0;
  @media (min-width: 1500px) {
    justify-content: center;
  }
`;

export const NotesText = styled.div`
  font-size: ${({ theme }) => theme.font.pageText.size};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.blue};
  cursor: pointer;
`;

// ----- MANUAL TIME REPORT -----
export const ColoredBox = styled.div`
  display: grid;
  grid-row-gap: 3px;
  border-radius: 6px;
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  padding: 5px 10px;
  text-transform: capitalize;
  color: ${({ type, theme }) =>
    type === "add"
      ? theme.colors.main.primary
      : type === "delete"
      ? theme.colors.main.error
      : theme.colors.main.purpleMedium};
  background-color: ${({ type, theme }) =>
    type === "add"
      ? theme.colors.main.primaryLight
      : type === "delete"
      ? `#FFF3F3`
      : `#ECF0FC`};
`;

// ----- APPS & URLS -----
export const AppUrlTitle = styled.p`
  color: ${({ theme }) => theme.colors.main.textSecondary};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  margin: 15px 0 0 0;
`;

export const AppUrlCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 12px;
  margin-top: 15px;
`;

// ----- Filter -----
export const FilterContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto minmax(auto, 140px);
  grid-column-gap: 20px;
  justify-content: start;
  margin: 30px 0 20px 0;
  @media (max-width: 1366px) {
    grid-column-gap: 15px;
  }
`;
