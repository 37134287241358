import { useState, useEffect, Fragment } from "react";
import ReportsFilter from "../reportsFilterContainer";
import ManualTimeTable from "./ManualTimeTable";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";
import ExportModal from "../ExportModal";
import {
  PageTitle,
  CardTitle,
  CommonGrid,
  Container,
} from "../../../styledComponents/common";
import { ColoredButton, ExportButtonIcon, ExportButtonWithIcon } from "../../../styledComponents/buttons";
import { ActivityTextSection } from "../../../styledComponents/members";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";

import exportImg from "../../../assets/img/icons/export_icon.svg";

const ManualTime = ({
  selectedOrganization,
  manualTimeData,
  noData,
  isLoading,
}) => {
  const [showGraph, setShowGraph] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [sortType, setSortType] = useState({
    label: "Date (New to old)",
    value: "NewToOld",
  });
  const [pdfExport, setPdfExport] = useState(false);
  const [sortedState, setSortedState] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");

  const changeSortType = (type) => {
    setSortType(type);
  };

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const onClickExport = () => {
    setPdfExport(!pdfExport);
  };

  const changeProjectInTable = (value) => {
    setSelectedProject(value);
  };

  useEffect(() => {
    if (selectedOrganization?.id) {
      setSelectedProject("");
      setSortType({
        label: "Date (New to old)",
        value: "NewToOld",
      });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (manualTimeData && manualTimeData.length > 0) {
      let presentState = manualTimeData;
      let sortedList = presentState.slice().reverse();
      setTimeout(() => {
        setSortedState(sortedList);
      }, 200);
    }
  }, [manualTimeData]);

  return (
    <div className="content">
      <PageTitle>Manual Time Report</PageTitle>
      <ReportsFilter
        title="manualTime"
        sortType={sortType}
        changeSortType={changeSortType}
        changeProjectInTable={changeProjectInTable}
      />
      <Container
        style={{ padding: `30px`, marginTop: `20px`, background: `#FCFDFE` }}
      >
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            {manualTimeData && manualTimeData.length > 0 && !isLoading && (
              <CommonGrid alignItem="center">
                <ActivityTextSection
                  gap="5px"
                  style={{ alignContent: `start` }}
                >
                  <CardTitle>
                    {selectedProject === "" ? "All Projects" : selectedProject}
                  </CardTitle>
                </ActivityTextSection>
                <ExportButtonWithIcon onClick={() => popupToggle()}>
                  <ExportButtonIcon src={exportImg} alt="" />
                  Export
                </ExportButtonWithIcon>
              </CommonGrid>
            )}

            {sortType.value === "NewToOld"
              ? manualTimeData?.length > 0 && (
                  <Fragment>
                    <ExportModal
                      isOpen={popupOpen}
                      toggle={popupToggle}
                      onClickExport={onClickExport}
                      title="manualTime"
                      manualTimeData={manualTimeData}
                    />
                    {manualTimeData.map((report, index) => (
                      <ManualTimeTable
                        report={report}
                        key={index}
                        index={index}
                      />
                    ))}
                  </Fragment>
                )
              : sortedState &&
                sortedState.length > 0 && (
                  <Fragment>
                    <ExportModal
                      isOpen={popupOpen}
                      toggle={popupToggle}
                      onClickExport={onClickExport}
                      title="manualTime"
                      manualTimeData={sortedState}
                    />
                    {sortedState.map((report, index) => (
                      <ManualTimeTable
                        report={report}
                        key={index}
                        index={index}
                      />
                    ))}
                  </Fragment>
                )}
            {manualTimeData?.length === 0 && noData && (
              <NoDataComponent title="Sorry! there is no Manual Time Report." />
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default ManualTime;
