import {
    GET_IS_INSTALLED_JIRA,
    GET_IS_INSTALLED_JIRA_SUCCESS,
    GET_IS_INSTALLED_JIRA_FAILURE,
    CONNECT_JIRA,
    CONNECT_JIRA_SUCCESS,
    CONNECT_JIRA_FAILURE,
    SYNC_JIRA,
    SYNC_JIRA_SUCCESS,
    SYNC_JIRA_FAILURE,
    DELETE_JIRA,
    DELETE_JIRA_SUCCESS,
    DELETE_JIRA_FAILURE,
    UPDATE_JIRA,
    UPDATE_JIRA_SUCCESS,
    UPDATE_JIRA_FAILURE,
    GET_IS_ACTIVE_JIRA,
    GET_IS_ACTIVE_JIRA_SUCCESS,
    GET_IS_ACTIVE_JIRA_FAILURE,
    INVITE_MEMBER_BULK,
    INVITE_MEMBER_BULK_SUCCESS,
    INVITE_MULTIPLE_MEMBERS_FAILURE,
    UPDATE_CREDENTIALS,
    UPDATE_CREDENTIALS_SUCCES,
    UPDATE_CREDENTIALS_FAILURE,
    GET_CREDENTIALS,
    GET_CREDENTIALS_SUCCES,
    GET_CREDENTIALS_FAILURE,
    GET_JIRA_INFO,
    GET_JIRA_INFO_SUCCESS,
    GET_JIRA_INFO_FAILURE,
  } from "../../../modules/constants";
  
  import { createNotification } from "../../../modules/notificationManager";
  
  // ------------------------------------
  // Action handler methods
  // ------------------------------------

  export const handleGetIsInstalledJiraRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  export const handleGetIsInstalledJiraRequestSuccess = (state, action) => {
    const {
      payload: { data },
    } = action;
  
    if (data.status === 200) {
      return {
        ...state,
        isLoading: false,
        isInstalledJira: data.data.is_installed,
      };
    }
    return {
      ...state,
      isLoading: false,
    };
  };
  export const handleGetIsInstalledJiraRequestFailure = (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  };
  export const handleConnectJiraRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  export const handleConnectJiraRequestSuccess = (state, action) => {
    const {
      payload: { data },
    } = action;
  
    if (data.status === 200 || data.status === 201) {
      createNotification(
        "success",
        "Jira connected.",
        1000
      );
      return {
        ...state,
        isLoading: false,
        isInstalledJira: true,
        jiraInfo: data.data.user_project_task,
      };
    }
    return {
      ...state,
      isLoading: false,
    };
  };
  export const handleConnectJiraRequestFailure = (state, action) => {
    createNotification(
      "error",
      "Could not connect Jira.",
      1000
    );
    return {
      ...state,
      isLoading: false,
    };
  };
  
  export const handleSyncJiraRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
      syncIsLoading: true,
    };
  };
  export const handleSyncJiraRequestSuccess = (state, action) => {
    const {
      payload: { data },
    } = action;
  
    if (data.status === 200) {
      createNotification(
        "success",
        "Jira synced.",
        1000
      );
      return {
        ...state,
        isLoading: false,
        syncIsLoading: false,
        jiraInfo: data.data,
      };
    }
    return {
      ...state,
      isLoading: false,
      syncIsLoading: false,
    };
  };
  export const handleSyncJiraRequestFailure = (state, action) => {
    createNotification(
      "error",
      "Could not sync Jira.",
      1000
    );
    return {
      ...state,
      isLoading: false,
      syncIsLoading: false,
    };
  };
  
  export const handleDeleteJiraRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  export const handleDeleteJiraRequestSuccess = (state, action) => {
    const {
      payload: { data },
    } = action;
  
    if (data.status === 200 || data.status === 204) {
      createNotification(
        "success",
        "Jira deleted.",
        1500
      );
      return {
        ...state,
        isLoading: false,
        isActiveJira: false,
        isInstalledJira: false,
        jiraInfo: null,
      };
    }
    return {
      ...state,
      isLoading: false,
    };
  };
  export const handleDeleteJiraRequestFailure = (state, action) => {
    createNotification(
      "error",
      "Could not delete Jira.",
      1000
    );
    return {
      ...state,
      isLoading: false,
    };
  };
  
  
  export const handleGetIsActiveJiraRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  export const handleGetIsActiveJiraRequestSuccess = (state, action) => {
    const {
      payload: { data },
    } = action;
  
    if (data.status === 200) {
      return {
        ...state,
        isLoading: false,
        isActiveJira: data.data.is_active,
      };
    }
    return {
      ...state,
      isLoading: false,
    };
  };
  export const handleGetIsActiveJiraRequestFailure = (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  };
  
  export const handleUpdateJiraRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  export const handleUpdateJiraRequestSuccess = (state, action) => {
    const {
      payload: { data },
    } = action;
  
    if (data.status === 200) {
      createNotification(
        "success",
        "Jira updated.",
        1000
      );
      return {
        ...state,
        isLoading: false,
        isActiveJira: data.data.is_active,
      };
    }
    return {
      ...state,
      isLoading: false,
    };
  };
  export const handleUpdateJiraRequestFailure = (state, action) => {
    createNotification(
      "error",
      "Could not update Jira.",
      1000
    );
    return {
      ...state,
      isLoading: false,
    };
  };
  
  export const handleUpdateCredentialsRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  export const handleUpdateCredentialsRequestSuccess = (state, action) => {
    const {
      payload: { data },
    } = action;
  
    if (data.status === 200) {
      createNotification(
        "success",
        "Credentials updated.",
        1000
      );
      return {
        ...state,
        isLoading: false,
        jiraInfo: data.data,
      };
    }
    return {
      ...state,
      isLoading: false,
    };
  };
  export const handleUpdateCredentialsRequestFailure = (state, action) => {
    createNotification(
      "error",
      "Could not update credentials.",
      1000
    );
    return {
      ...state,
      isLoading: false,
    };
  };
  
  export const handleInviteBulkRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  export const handleInviteBulkRequestSuccess = (state, action) => {
    const {
      payload: { data },
    } = action;
  
    if (data.status === 200 || data.status === 201) {
      return {
        ...state,
        isLoading: false,
      };
    }
    return {
      ...state,
      isLoading: false,
    };
  };
  export const handleInviteBulkRequestFailure = (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  };
  
  export const handleGetJiraCredentialsRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  export const handleGetJiraCredentialsRequestSuccess = (state, action) => {
    const {
      payload: { data },
    } = action;
  
    if (data.status === 200) {
      return {
        ...state,
        isLoading: false,
        jiraCredentials: data.data,
      };
    }
    return {
      ...state,
      isLoading: false,
    };
  };
  export const handleGetJiraCredentialsRequestFailure = (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  };
  
  export const handleGetJiraInfoRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  export const handleGetJiraInfoRequestSuccess = (state, action) => {
    const {
      payload: { data },
    } = action;
  
    if (data.status === 200) {
      return {
        ...state,
        isLoading: false,
        jiraInfo: data.data,
      };
    }
    return {
      ...state,
      isLoading: false,
    };
  };
  export const handleGetJiraInfoRequestFailure = (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  };
  
  // Action Handlers
  const ACTION_HANDLERS = {
  
    [GET_IS_INSTALLED_JIRA]: handleGetIsInstalledJiraRequest,
    [GET_IS_INSTALLED_JIRA_SUCCESS]: handleGetIsInstalledJiraRequestSuccess,
    [GET_IS_INSTALLED_JIRA_FAILURE]: handleGetIsInstalledJiraRequestFailure,
    [GET_IS_ACTIVE_JIRA]: handleGetIsActiveJiraRequest,
    [GET_IS_ACTIVE_JIRA_SUCCESS]: handleGetIsActiveJiraRequestSuccess,
    [GET_IS_ACTIVE_JIRA_FAILURE]: handleGetIsActiveJiraRequestFailure,
  
    [CONNECT_JIRA]: handleConnectJiraRequest,
    [CONNECT_JIRA_SUCCESS]: handleConnectJiraRequestSuccess,
    [CONNECT_JIRA_FAILURE]: handleConnectJiraRequestFailure,
  
    [DELETE_JIRA]: handleDeleteJiraRequest,
    [DELETE_JIRA_SUCCESS]: handleDeleteJiraRequestSuccess,
    [DELETE_JIRA_FAILURE]: handleDeleteJiraRequestFailure,
  
    [SYNC_JIRA]: handleSyncJiraRequest,
    [SYNC_JIRA_SUCCESS]: handleSyncJiraRequestSuccess,
    [SYNC_JIRA_FAILURE]: handleSyncJiraRequestFailure,
  
    [UPDATE_JIRA]: handleUpdateJiraRequest,
    [UPDATE_JIRA_SUCCESS]: handleUpdateJiraRequestSuccess,
    [UPDATE_JIRA_FAILURE]: handleUpdateJiraRequestFailure,
  
    [UPDATE_CREDENTIALS]: handleUpdateCredentialsRequest,
    [UPDATE_CREDENTIALS_SUCCES]: handleUpdateCredentialsRequestSuccess,
    [UPDATE_CREDENTIALS_FAILURE]: handleUpdateCredentialsRequestFailure,
  
    [INVITE_MEMBER_BULK]: handleInviteBulkRequest,
    [INVITE_MEMBER_BULK_SUCCESS]: handleInviteBulkRequestSuccess,
    [INVITE_MULTIPLE_MEMBERS_FAILURE]: handleInviteBulkRequestFailure,
  
    [GET_CREDENTIALS]: handleGetJiraCredentialsRequest,
    [GET_CREDENTIALS_SUCCES]: handleGetJiraCredentialsRequestSuccess,
    [GET_CREDENTIALS_FAILURE]: handleGetJiraCredentialsRequestFailure,
  
    [GET_JIRA_INFO]: handleGetJiraInfoRequest,
    [GET_JIRA_INFO_SUCCESS]: handleGetJiraInfoRequestSuccess,
    [GET_JIRA_INFO_FAILURE]: handleGetJiraCredentialsRequestFailure,
  };
  
  // default initial state
  const initialState = {
    isLoading: false,
    syncIsLoading: false,
  
    isInstalledJira: false,
    isActiveJira: false,
    jiraInfo: null,
    jiraCredentials: null,
  };
  
  export default function JiraIntegrationsReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
  
    return handler ? handler(state, action) : state;
  }
  