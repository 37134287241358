import { useState, useEffect } from "react";

import {
  PageTitle,
  ContentHeader,
  CommonFlex,
  CommonImage,
  CommonText,
  CommonGrid,
} from "../../../../styledComponents/common";

import {
  PrimaryButton,
  WhiteButton,
  ColoredButtonWithIcon,
  ButtonIcon,
  RefreshButton,
} from "../../../../styledComponents/buttons";
import { IntegrationNameContainer, WorkflowCard, WorkflowsContainer } from "../../Setup/integrationSetupStyles";

import BackButtonComponent from "../../../../components/BackButtonComponent/BackButtonComponent";

import ToggleButton from "../../../../components/ToggleButton/ToggleButton";

import refreshIcon from "../../../../assets/img/icons/refresh_white.svg";
import jiraIcon from "../../../../assets/img/integrations/jira.svg";
import SettingsIcon from "../../../../assets/img/Sidebar/settings.svg";

import Loader from "react-spinners/PulseLoader";

const JiraDetails = (props) => {
  const [integration, setIntegration] = useState(null);
  
  const [workflow, setWorkflow] = useState([]);

  const [syncClicked, setSyncClicked] = useState(false);

  const {
    history,
    selectedOrganization,

    isLoading,
    syncIsLoading,
    syncJiraIntegration,
    deleteJiraIntegration,
    isActiveJira,
    isInstalledJira,
    getIsActiveJira,
    updateJiraIntegration,
    jiraInfo,
  } = props;

  useEffect(() => {
    if( selectedOrganization && selectedOrganization.id ){
      const payload = {
        organization_id: selectedOrganization.id,
      };
      getIsActiveJira(payload);

      const jiraIntegration = {
        id: "jira",
        name: "jira",
        tag: "jira",
        display_name: "Jira",
        description: "Apploye to Jira Integration",
        is_installed: isInstalledJira,
        icon: jiraIcon,
        frontend_steps: []
      };

      const jiraWorkflow = {
          "id": "jira",
          "name": "Sync projects and issues from Jira",
          "description": "Import your Jira projects and issues to Apploye projects and tasks",
          "tag": "jira",
          "associated_resource": "all",
          "is_enabled": isActiveJira
        };

      setIntegration(jiraIntegration);
      setWorkflow(jiraWorkflow);
    }
  }, [selectedOrganization, isActiveJira]);


  const handleUpdateJira = (isActive) => {
    if ( selectedOrganization && selectedOrganization.id ) {
      const payload = {
        organization_id: selectedOrganization.id,
        is_active: isActive,
      };

      updateJiraIntegration(payload);
    }
  };

  const handleSettingsClick = () => {
    history.push({
      pathname: `/user/integrations/jira-update-credentials`,
    });
  };

  const syncJira = () => {
    setSyncClicked(true);
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      syncJiraIntegration(payload);
    }
  };

  const deleteJira = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      deleteJiraIntegration(payload);
    }
  };

  useEffect(() => {
    if( !isInstalledJira ){
      history.push({
        pathname: `/user/integrations/`,
      });
    }
  }, [isInstalledJira]);

  useEffect(() => {
    if( syncClicked && jiraInfo ){
      history.push({
        pathname: `/user/integrations/jira-sync`,
      });
    }
  }, [jiraInfo]);


  return (
    <div className="content">
      <PageTitle>Jira Details</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          subTitle="Back to all integrations"
          onClick={() => history.push("/user/integrations")}
        />
      </ContentHeader>
      <CommonFlex justifyContent="space-between" margin="40px 0 ">
        <IntegrationNameContainer>
          <CommonFlex gap="12px">
            <CommonImage size="40px" src={integration && integration.icon} />
            <CommonText fontWeight="600" fontSize="16px">
              {integration && integration.display_name}
            </CommonText>
          </CommonFlex>
        </IntegrationNameContainer>

        <CommonFlex>
          <RefreshButton
            isLoading={syncIsLoading}
            onClick={() => {
              syncJira();
            }}
            padding="0 15px"
          >
            <ButtonIcon src={refreshIcon} alt="" size="22px" />
            Sync Projects & Tasks
          </RefreshButton>
          <WhiteButton 
            color="#FE5969;"
            onClick={() => {
              deleteJira();
            }}
          >
            Delete
          </WhiteButton>

          <CommonImage 
            size="33px" 
            src={SettingsIcon} 
            margin="0 0 0 20px"
            onClick={() => {
              handleSettingsClick();
            }}
            style={{cursor: "pointer"}}
          />
        </CommonFlex>
      </CommonFlex>
      {workflow ? (
        <WorkflowsContainer>
          <WorkflowCard key={workflow.id} enabled={workflow.is_enabled}>
            <CommonFlex justifyContent="space-between">
              <CommonGrid columns="auto">
                <CommonText fontWeight="600">{workflow.name}</CommonText>
                <CommonText $label fontWeight="500">
                  {workflow.description}
                </CommonText>
              </CommonGrid>
              {isLoading && !syncIsLoading ?(
                <Loader
                  size={10}
                  color="#c2cce1"
                />
              ): (
                <CommonFlex gap="12px">
                  <ToggleButton
                    active={isActiveJira}
                    handleClick={() => {
                      if (!isActiveJira) {
                        handleUpdateJira(true);
                      }else{
                        handleUpdateJira(false);
                      }
                    }}
                  />
                  <CommonText $label fontWeight="500">
                    {isActiveJira ? "ON" : "OFF"}
                  </CommonText>
                </CommonFlex>
              )}
            </CommonFlex>
          </WorkflowCard>
        </WorkflowsContainer>
      ) : null}

    </div>
  );
};

export default JiraDetails;
