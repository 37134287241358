import { DotLottieReact } from "@lottiefiles/dotlottie-react";

export default function Animation({
  src,
  loop = false,
  autoplay = true,
  style = {
    height: "100px",
    width: "100px",
  },
}) {
  return (
    <DotLottieReact
      src={src}
      loop={loop}
      autoplay={autoplay}
      style={style}
      renderConfig={{ autoResize: false }}
    />
  );
}
