import { memo, useState, useCallback } from "react";
import { NavLink } from "react-router-dom";

import {
  SeeMoreContainer,
  SeeMoreDiv,
  SeeMoreArrow,
  LinkText,
  SidebarCollapseList,
  SidebarLinkCollapse,
  SidebarListItem,
  SidebarLink,
  SingleLinkContainer,
  LinkIcon,
  NewBadge,
} from "../sidebarStyles";

import { activeRoute } from "../sidebarHelpers";

import DesktopAppIcon from "../../../assets/img/Sidebar/desktop.svg";
import MobileAppIcon from "../../../assets/img/Sidebar/mobile.svg";
import ReadBlogIcon from "../../../assets/img/Sidebar/read-blog.svg";
import InviteMemberIcon from "../../../assets/img/Sidebar/invite-member.svg";
import PublicApiIcon from "../../../assets/img/Sidebar/public_api_icon.svg";
import HelpIcon from "../../../assets/img/Sidebar/help.svg";

import arrowDown from "../../../assets/img/Sidebar/sidebar-arrow-down.svg";
import arrowUp from "../../../assets/img/Sidebar/sidebar-arrow-up.svg";

const seeMoreData = [
  {
    name: "Invite Member",
    link: "/user/member-invitation",
    icon: InviteMemberIcon,
    isExternal: false,
    Highlight: false,
  },
  {
    name: "Desktop App",
    link: "https://apploye.com/download",
    icon: DesktopAppIcon,
    isExternal: true,
    Highlight: false,
  },
  {
    name: "Mobile App",
    link: "https://apploye.com/download",
    icon: MobileAppIcon,
    isExternal: true,
    Highlight: false,
  },
  {
    name: "Public API",
    link: "/user/public-api",
    icon: PublicApiIcon,
    isExternal: false,
    new: true,
    Highlight: true,
  },
  {
    name: "Read Blog",
    link: "https://apploye.com/blog/",
    icon: ReadBlogIcon,
    isExternal: true,
    Highlight: false,
  },
  {
    name: "Help",
    link: "https://apploye.com/help/",
    icon: HelpIcon,
    isExternal: true,
    Highlight: false,
  },
];

export const SeeMoreView = memo(({ organization, location }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isAdmin =
    organization?.role === "owner" || organization?.role === "admin";

  const toggleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return (
    <SeeMoreContainer>
      <SeeMoreDiv onClick={toggleOpen} isActive={isOpen}>
        <SeeMoreArrow src={arrowDown} alt="" collapsed={!isOpen} />
        <LinkText>See More</LinkText>
      </SeeMoreDiv>
      <SidebarLinkCollapse isOpen={isOpen}>
        <SidebarCollapseList style={{ marginTop: "5px" }}>
          {seeMoreData.map((item, index) => (
            <SidebarListItem
              key={index}
              active={
                !item.isExternal &&
                activeRoute(item.link, location) &&
                item.Highlight
              }
            >
              {item.isExternal ? (
                <SidebarLink
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SingleLinkContainer>
                    <LinkIcon
                      src={item.icon}
                      alt=""
                      style={{ marginLeft: "-3px" }}
                    />
                    <LinkText>{item.name}</LinkText>
                    {item.new && (
                      <NewBadge>{item.beta ? "BETA" : "NEW"}</NewBadge>
                    )}
                  </SingleLinkContainer>
                </SidebarLink>
              ) : isAdmin ? (
                <NavLink to={item.link} activeClassName="">
                  <SingleLinkContainer>
                    <LinkIcon
                      src={item.icon}
                      alt=""
                      style={{ marginLeft: "-3px" }}
                    />
                    <LinkText>{item.name}</LinkText>
                    {item.new && (
                      <NewBadge>{item.beta ? "BETA" : "NEW"}</NewBadge>
                    )}
                  </SingleLinkContainer>
                </NavLink>
              ) : null}
            </SidebarListItem>
          ))}
        </SidebarCollapseList>
      </SidebarLinkCollapse>
    </SeeMoreContainer>
  );
});

export default SeeMoreView;
