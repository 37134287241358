import { useState, useEffect, Fragment } from "react";
import { CommonText, CommonGrid, Container } from "styledComponents/common";
import AppsUrlsPercentageBar from "../../../components/ProgressBar/AppsUrlsPercentageBar";
import moment from "moment";
import AppLists from "../../ActivityNew/Apps/AppLists";
import UrlLists from "../../ActivityNew/URLs/UrlLists";
import FirstRoundLetter from "../../../components/ColorLetterRound/FirstRoundLetter";
import { formatDurationToHoursMinutes } from "../../../utils/helper";
import { ActivityTextSection } from "../../../styledComponents/members";
import {
  AppsTimeSection,
  AppTimeItem,
  AppUrlCard,
} from "../../../styledComponents/Activity";
import {
  TableDateContainer,
  AppUrlTitle,
  AppUrlCardContainer,
} from "styledComponents/reports";
import RoundImageNameComp from "../../../components/RoundImageName/RoundImageNameComp";

const AppsUrlSection = ({ report, date, index }) => {
  const getAppData = (appList) => {
    AppLists.map((item) => {
      appList.map((app) => {
        if (
          app.app === item.winId ||
          app.app === item.macId ||
          app.app === item.linuxId
        ) {
          app.name = item.name;
          app.icon = item.icon;
        }
      });
    });
    return appList.sort((a, b) => {
      const aName = a.name ? a.name?.toLowerCase() : a.app?.toLowerCase();
      const bName = b.name ? b.name?.toLowerCase() : b.app?.toLowerCase();
      return aName.localeCompare(bName);
    });
  };

  const getUrlData = (urlList) => {
    UrlLists.map((item) => {
      urlList.map((url) => {
        if (trimUrl(url.url) === item.name) {
          url.name = item.name;
          url.icon = item.icon;
        }
      });
    });
    return urlList.sort((a, b) => {
      const aName = a.name ? a.name?.toLowerCase() : a.url?.toLowerCase();
      const bName = b.name ? b.name?.toLowerCase() : b.url?.toLowerCase();
      return aName.localeCompare(bName);
    });
  };

  const trimUrl = (url) => {
    return url.replace("http://", "").replace("https://", "").split(/[/?#]/)[0];
  };

  // const urlCheck = (value) => {
  //   if (value == "http://localhost:3000" || value == "localhost:3000")
  //     return false;
  //   try {
  //     var request;
  //     if (window.XMLHttpRequest) request = new XMLHttpRequest();
  //     else request = new ActiveXObject("Microsoft.XMLHTTP");
  //     request.open("GET", value, false);
  //     request.send();
  //     if (request.status === 404) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   } catch (ex) {
  //     //  alert("got");
  //     return false;
  //   }
  // };
  return (
    <Fragment>
      {report && Object.keys(report).length > 0 && (
        <div style={{ marginTop: `20px` }}>
          <CommonText>{moment(date).format("D MMM, YYYY")}</CommonText>
          <TableDateContainer index={index} style={{ paddingLeft: `20px` }}>
            {Object.keys(report).map((info, i) => {
              if (
                (report[info].app_data && report[info].app_data.length > 0) ||
                (report[info].url_data && report[info].url_data.length > 0)
              ) {
                return (
                  <Container
                    style={{
                      margin: `0 0 20px 0`,
                    }}
                    padding="25px 20px"
                    bgColor="#fcfdfe"
                    key={i}
                  >
                    <CommonGrid>
                      <RoundImageNameComp
                        imageSize="40px"
                        imgSource={report[info].member_avatar}
                        name={report[info].member_name}
                        fill
                        fontWeight="600"
                        size="14px"
                        color={report[info].member_color}
                      />
                      <AppsTimeSection>
                        <AppTimeItem index={1}>
                          <CommonText name title>
                            {formatDurationToHoursMinutes(
                              report[info].duration
                            )}
                          </CommonText>
                          <CommonText $label fontWeight="500">
                            Total Time
                          </CommonText>
                        </AppTimeItem>
                      </AppsTimeSection>
                    </CommonGrid>

                    {report[info].app_data &&
                      report[info].app_data.length > 0 && (
                        <>
                          <AppUrlTitle>Apps</AppUrlTitle>

                          <AppUrlCardContainer>
                            {getAppData(report[info].app_data).map(
                              (app, index) => (
                                <AppUrlCard key={index}>
                                  {app.icon && (
                                    <div>
                                      <img src={app.icon} width="100%" alt="" />
                                    </div>
                                  )}
                                  {!app.name && (
                                    <FirstRoundLetter
                                      text={app.app}
                                      backColor={index}
                                      size="36px"
                                      fontSize="16px"
                                    />
                                  )}
                                  <ActivityTextSection gap="3px">
                                    <CommonText name>
                                      {app.name
                                        ? app.name.length > 19
                                          ? `${app.name.slice(0, 17)}...`
                                          : app.name
                                        : app.app.length > 19
                                        ? `${app.app.slice(0, 17)}...`
                                        : app.app}
                                    </CommonText>
                                    <CommonText fontSize="13px">
                                      {formatDurationToHoursMinutes(app.time)}
                                    </CommonText>
                                  </ActivityTextSection>

                                  <div />
                                  <CommonGrid
                                    columns="1fr auto"
                                    justifyContent="start"
                                    padding="4px 0 0 0"
                                  >
                                    <AppsUrlsPercentageBar
                                      percent={
                                        (app.time / report[info].duration) *
                                          100 >
                                        100
                                          ? 100
                                          : (app.time / report[info].duration) *
                                            100
                                      }
                                    />
                                    <CommonText>
                                      {`${
                                        parseInt(
                                          (app.time / report[info].duration) *
                                            100
                                        ) > 100
                                          ? 100
                                          : parseInt(
                                              (app.time /
                                                report[info].duration) *
                                                100
                                            )
                                      }%`}
                                    </CommonText>
                                  </CommonGrid>
                                </AppUrlCard>
                              )
                            )}
                          </AppUrlCardContainer>
                        </>
                      )}
                    {report[info].url_data &&
                      report[info].url_data.length > 0 && (
                        <>
                          <AppUrlTitle style={{ marginTop: `20px` }}>
                            URLs
                          </AppUrlTitle>

                          <AppUrlCardContainer>
                            {getUrlData(report[info].url_data).map(
                              (url, index) => (
                                <AppUrlCard key={index}>
                                  {/* {urlCheck(`${trimUrl(url.url)}`) ? (
                                  <div>
                                    <img
                                      src={`https://www.google.com/s2/favicons?sz=64&domain_url=${trimUrl(
                                        url.url
                                      )}`}
                                      width="100%"
                                      alt=""
                                    />
                                  </div>
                                ) : ( */}
                                  <FirstRoundLetter
                                    text={trimUrl(url.url)}
                                    backColor={index}
                                    size="36px"
                                    fontSize="16px"
                                  />
                                  {/* )} */}
                                  <ActivityTextSection gap="3px">
                                    <CommonText
                                      name
                                      style={{
                                        wordWrap: "break-word",
                                        hyphens: "auto",
                                      }}
                                    >
                                      {url.name ? url.name : trimUrl(url.url)}
                                    </CommonText>
                                    <CommonText fontSize="13px">
                                      {formatDurationToHoursMinutes(url.time)}
                                    </CommonText>
                                  </ActivityTextSection>

                                  <div />
                                  <CommonGrid
                                    columns="1fr auto"
                                    justifyContent="start"
                                    padding="4px 0 0 0"
                                  >
                                    <AppsUrlsPercentageBar
                                      percent={
                                        (url.time / report[info].duration) *
                                          100 >
                                        100
                                          ? 100
                                          : (url.time / report[info].duration) *
                                            100
                                      }
                                    />
                                    <CommonText>
                                      {`${
                                        parseInt(
                                          (url.time / report[info].duration) *
                                            100
                                        ) > 100
                                          ? 100
                                          : parseInt(
                                              (url.time /
                                                report[info].duration) *
                                                100
                                            )
                                      }%`}
                                    </CommonText>
                                  </CommonGrid>
                                </AppUrlCard>
                              )
                            )}
                          </AppUrlCardContainer>
                        </>
                      )}
                  </Container>
                );
              } else {
                return null;
              }
            })}
          </TableDateContainer>
        </div>
      )}
    </Fragment>
  );
};

export default AppsUrlSection;
