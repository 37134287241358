import { memo } from "react";
import { NavLink } from "react-router-dom";

import {
  SingleLinkContainer,
  CollapseArrowIcon,
  SidebarLinkCollapse,
  SidebarListItem,
  SidebarCollapseList,
  SidebarCollapseListItem,
  SidebarLink,
  LinkIcon,
  LinkText,
  NewBadge,
} from "../sidebarStyles";

import {
  getCollapseInitialState,
  activeRoute,
  getCollapseLinks,
} from "../sidebarHelpers";

import { PulsatingDot } from "../../LiveFeed/CurrentlyTracking/CurrentlyTracking";

import { ICON_MAP } from "../iconMapping";

import arrowDown from "../../../assets/img/Sidebar/sidebar-arrow-down.svg";
import arrowUp from "../../../assets/img/Sidebar/sidebar-arrow-up.svg";

export const RegularLink = memo(({ path, location, sidebarMini }) => (
  <SidebarListItem
    active={activeRoute(path.layout + path.path, location)}
    name={path.name}
  >
    <NavLink to={path.layout + path.path} activeClassName="">
      {path.icon !== undefined && (
        <SingleLinkContainer name={path.name} sidebarMini={sidebarMini}>
          {path.live ? (
            <PulsatingDot
              active={activeRoute(path.layout + path.path, location)}
              size="10px"
              margin="10px 7px 7px 9px"
            />
          ) : (
            <LinkIcon src={ICON_MAP[path.icon]} alt="" />
          )}
          <LinkText>{path.name}</LinkText>
          {path.new && <NewBadge>{path.beta ? "BETA" : "NEW"}</NewBadge>}
        </SingleLinkContainer>
      )}
    </NavLink>
  </SidebarListItem>
));

export const CollapsibleLink = memo(
  ({
    path,
    index,
    collapseState,
    toggleCollapse,
    organization,
    location,
    sidebarMini,
  }) => (
    <SidebarCollapseListItem
      key={index}
      collapse
      active={getCollapseInitialState(path.views)}
    >
      <SidebarLink
        active={getCollapseInitialState(path.views)}
        aria-expanded={collapseState[path.state]}
        onClick={(e) => {
          e.preventDefault();
          toggleCollapse(path);
        }}
      >
        {path.icon !== undefined && (
          <SingleLinkContainer sidebarMini={sidebarMini}>
            {path.live ? (
              <PulsatingDot
                active={getCollapseInitialState(path.views)}
                size="10px"
                margin="10px 7px 7px 9px"
              />
            ) : (
              <LinkIcon src={ICON_MAP[path.icon]} alt="" />
            )}
            <LinkText>{path.name}</LinkText>
            {path.new && <NewBadge>{path.beta ? "BETA" : "NEW"}</NewBadge>}
            <CollapseArrowIcon
              src={arrowDown}
              alt=""
              collapsed={!collapseState[path.state]}
            />
          </SingleLinkContainer>
        )}
      </SidebarLink>
      <SidebarLinkCollapse isOpen={collapseState[path.state]}>
        <SidebarCollapseList>
          {getCollapseLinks(
            organization,
            path.views,
            location,
            sidebarMini,
            path.collapse
          )}
        </SidebarCollapseList>
      </SidebarLinkCollapse>
    </SidebarCollapseListItem>
  )
);
