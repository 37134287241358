import { connect } from "react-redux";
import EditInvoice from "./EditInvoice";

// Actions
import { getClientList, createClient } from "../../Clients/clientsActions";
import {
  getInvoiceDetails,
  getInvoiceHistory,
  updateInvoice,
  createInvoiceItem,
  updateInvoiceItem,
  deleteInvoiceItem,
  createInvoiceTax,
  updateInvoiceTax,
  getInvoiceTax,
  updateInvoiceDiscount,
  createInvoiceUpdateSendLog,
  sendInvoiceEmail,
  invoiceLinkProject,
  clearInvoiceLinkedProject,
  isMailSendFromEdit,
} from "../invoiceActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  isLoading: state.invoice.isLoading,
  updateInvoiceLoading: state.invoice.updateInvoiceLoading,
  createItemLoading: state.invoice.createItemLoading,
  updateItemLoading: state.invoice.updateItemLoading,
  deleteItemLoading: state.invoice.deleteItemLoading,
  createTaxLoading: state.invoice.createTaxLoading,
  updateTaxLoading: state.invoice.updateTaxLoading,
  updateDiscountLoading: state.invoice.updateDiscountLoading,
  linkProjectLoading: state.invoice.linkProjectLoading,
  sendMailLoading: state.invoice.sendMailLoading,
  clientIsLoading: state.client.isLoading,
  clientList: state.client.clientList,
  projectsList: state.project.projectShortList,
  createdClientId: state.client.createdClientId,
  invoiceDetails: state.invoice.invoiceDetails,
  invoiceHistory: state.invoice.invoiceHistory,
  callback: state.invoice.callback,
  updatedInvoiceItemInfo: state.invoice.updatedInvoiceItemInfo,
  deletedInvoiceItem: state.invoice.deletedInvoiceItem,
  createdInvoiceTaxId: state.invoice.createdInvoiceTaxId,
  updatedInvoiceTax: state.invoice.updatedInvoiceTax,
  invoiceTaxInfo: state.invoice.invoiceTaxInfo,
  updatedInvoiceDiscount: state.invoice.updatedInvoiceDiscount,
  profileData: state.profile.profileData,
  updateInvoiceSendLog: state.invoice.updateInvoiceSendLog,
  sendInvoiceEmailInfo: state.invoice.sendInvoiceEmailInfo,
  linkedProjectState: state.invoice.linkedProjectState,
  isMailSend: state.invoice.isMailSend,
  invoiceNumberAlreadyExists: state.invoice.invoiceNumberAlreadyExists,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getClientList: (details) => dispatch(getClientList({ ...details })),
  getInvoiceDetails: (details) => dispatch(getInvoiceDetails({ ...details })),
  getInvoiceHistory: (details) => dispatch(getInvoiceHistory({ ...details })),
  updateInvoice: (details) => dispatch(updateInvoice({ ...details })),
  createClient: (details) => dispatch(createClient({ ...details })),
  createInvoiceItem: (details) => dispatch(createInvoiceItem({ ...details })),
  updateInvoiceItem: (details) => dispatch(updateInvoiceItem({ ...details })),
  deleteInvoiceItem: (details) => dispatch(deleteInvoiceItem({ ...details })),
  createInvoiceTax: (details) => dispatch(createInvoiceTax({ ...details })),
  updateInvoiceTax: (details) => dispatch(updateInvoiceTax({ ...details })),
  getInvoiceTax: (details) => dispatch(getInvoiceTax({ ...details })),
  updateInvoiceDiscount: (details) =>
    dispatch(updateInvoiceDiscount({ ...details })),
  createInvoiceUpdateSendLog: (details) =>
    dispatch(createInvoiceUpdateSendLog({ ...details })),
  sendInvoiceEmail: (details) => dispatch(sendInvoiceEmail({ ...details })),
  invoiceLinkProject: (details) => dispatch(invoiceLinkProject({ ...details })),
  clearInvoiceLinkedProject: () => dispatch(clearInvoiceLinkedProject()),
  isMailSendFromEdit: (details) => dispatch(isMailSendFromEdit(details)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(EditInvoice);
