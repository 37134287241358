import {
    GET_IS_INSTALLED_JIRA,
    GET_IS_ACTIVE_JIRA,
    CONNECT_JIRA,
    SYNC_JIRA,
    DELETE_JIRA,
    UPDATE_JIRA,
    UPDATE_CREDENTIALS,
    INVITE_MEMBER_BULK,
    GET_JIRA_INFO,
    GET_CREDENTIALS
  } from "../../../modules/constants";
  
  
  export function getJiraInfo(payload) {
    return {
      type: GET_JIRA_INFO,
      payload,
    };
  }
  export function getJiraCredentials(payload) {
    return {
      type: GET_CREDENTIALS,
      payload,
    };
  }
  export function getIsInstalledJira(payload) {
    return {
      type: GET_IS_INSTALLED_JIRA,
      payload,
    };
  }
  export function getIsActiveJira(payload) {
    return {
      type: GET_IS_ACTIVE_JIRA,
      payload,
    };
  }
  
  export function connectJiraIntegration(payload) {
    return {
      type: CONNECT_JIRA,
      payload,
    };
  }
  
  export function syncJiraIntegration(payload) {
    return {
      type: SYNC_JIRA,
      payload,
    };
  }
  
  export function deleteJiraIntegration(payload) {
    return {
      type: DELETE_JIRA,
      payload,
    };
  }
  
  export function updateJiraIntegration(payload) {
    return {
      type: UPDATE_JIRA,
      payload,
    };
  }
  
  export function updateJiraCredentials(payload) {
    return {
      type: UPDATE_CREDENTIALS,
      payload,
    };
  }
  
  export function inviteMemberBulk(payload) {
    return {
      type: INVITE_MEMBER_BULK,
      payload,
    };
  }
  