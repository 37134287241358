import styled from "styled-components";
import { NextButtonIcon } from "../../onboardingStepsStyles";

export const SelectPlanContainer = styled.div``;
export const SelectPlanHeader = styled.div`
  margin-bottom: 30px;
`;
export const HeaderSpan = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.main.textSecondary};

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
`;

export const NoCardRequiredDiv = styled.div`
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 10px;
  padding: 8px 15px;
  margin-top: 20px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.main.violetLight};
  border: 2px solid ${({ theme }) => theme.colors.main.violet};

  @media screen and (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
`;
export const NoCardRequiredText = styled.span`
  color: ${({ theme }) => theme.colors.main.violet};
  font-size: 15px;
  font-weight: 600;
`;

export const GetStartedButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 2px;
  width: 85%;
  padding: ${({ isLoading }) => (isLoading ? "5px 20px" : "10px 20px")};
  border-radius: 8px;
  background-color: ${({ type, theme }) =>
    type === "green"
      ? theme.colors.main.primary
      : type === "blue"
      ? theme.colors.main.blue
      : theme.colors.main.planGray};
  color: ${({ theme }) => theme.colors.main.white};
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  ${NextButtonIcon} {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%);
  }

  &:hover {
    background-color: ${({ type, theme }) =>
      type === "green"
        ? theme.colors.hover.primary
        : type === "blue"
        ? theme.colors.hover.blue
        : theme.colors.hover.planGray};
    ${NextButtonIcon} {
      transform: translateX(5px);
      transition: transform 0.3s ease;
    }
  }
`;

export const PlanCardsContainer = styled.div`
  display: flex;
  grid-column-gap: 50px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    justify-items: center;
  }
`;
export const SinglePlanCardContainer = styled.div`
  min-width: ${({ width }) => width ?? "300px"};
  min-height: 410px;
  padding: 35px;
  border-radius: 15px;
  background-color: ${({ type, theme }) =>
    type === "green"
      ? theme.colors.main.primaryLight
      : type === "blue"
      ? theme.colors.main.blueLight
      : theme.colors.main.planGrayLight};
  /* border: 2px solid
    ${({ type, theme }) =>
    type === "green"
      ? theme.colors.main.primaryLight
      : type === "blue"
      ? theme.colors.main.blueLight
      : theme.colors.main.planGrayLight}; */

  /* &:has(${GetStartedButton}:hover) {
    border-color: ${({ type, theme }) =>
    type === "green"
      ? theme.colors.main.primary
      : type === "blue"
      ? theme.colors.main.blue
      : theme.colors.main.planGray};
  } */
`;
export const SinglePlanCardTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
`;
export const FeatureDivContainer = styled.div``;
export const SingleFeatureDiv = styled.div`
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  margin-bottom: 12px;
`;
