import { Fragment, useState, useEffect } from "react";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";
import {
  CommonFlex,
  Container,
  TableText,
} from "../../styledComponents/common";
import {
  ClientTableContainer,
  TableItem,
  StatusBox,
  ActionContainer,
  InvoicedCapsule,
} from "../../styledComponents/clients";
import DeleteClientModal from "./DeleteClientModal";
import Select from "../../components/ReactSelectDropdown";
import FilterDropDownStyle from "../../components/DropdownStyle/FilterDropDownStyle";
import { CommImageNameComp } from "../Members/CommonComponents";
import { getFullName } from "../../utils/helper";

const ClientsTable = ({
  history,
  isLoading,
  clientList: initialClientList,
  clientType,
  isModalOpen,
  modalToggle,
  handleDelete,
  handleArchive,
}) => {
  const [clientList, setClientList] = useState([]);
  const [clientDeleteId, setClientDeleteId] = useState("");

  const activeTabOptions = [
    { value: "edit", label: "Edit" },
    { value: "archive", label: "Archive" },
    { value: "delete", label: "Delete" },
  ];
  const archiveTabOptions = [
    { value: "edit", label: "Edit" },
    { value: "unarchive", label: "Unarchive" },
    { value: "delete", label: "Delete" },
  ];

  const onActionSelect = (action, id) => {
    switch (action) {
      case "edit":
        history.push(`/user/edit-client?id=${id}`);
        break;
      case "archive":
        handleArchive(id, "archive");
        break;
      case "delete":
        toggleDeleteModal(id);
        break;
      case "unarchive":
        handleArchive(id, "active");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (initialClientList && initialClientList.length > 0) {
      let list = [];
      if (clientType === "active") {
        list = initialClientList.filter((client) => client.is_active);
      } else if (clientType === "archived") {
        list = initialClientList.filter((client) => !client.is_active);
      }
      list.sort((a, b) => a.name.localeCompare(b.name)); // Sort by name
      setClientList(list);
    } else {
      setClientList([]);
    }
  }, [initialClientList, clientType]);

  useEffect(() => {
    if (!isModalOpen) {
      setClientDeleteId("");
    }
  }, [isModalOpen]);

  const toggleDeleteModal = (id) => {
    setClientDeleteId(id);
    modalToggle();
  };
  
  return (
    <Container style={{ paddingBottom: `20px` }}>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : clientList.length > 0 ? (
        <>
          <DeleteClientModal
            id={clientDeleteId || ""}
            isOpen={isModalOpen}
            toggle={modalToggle}
            handleDelete={handleDelete}
          />
          <ClientTableContainer heading>
            <TableItem>
              <TableText>Client Name</TableText>
            </TableItem>
            <TableItem>
              <TableText>Contact</TableText>
            </TableItem>
            <TableItem>
              <TableText>Office Location</TableText>
            </TableItem>
            <TableItem>
              <TableText>Invoiced?</TableText>
            </TableItem>
            <TableItem>
              <TableText>Actions</TableText>
            </TableItem>
          </ClientTableContainer>

          {clientList.map((client, index) => (
            <Fragment key={index}>
              <ClientTableContainer>
                <TableItem
                  onClick={() =>
                    client.id &&
                    history.push(`/user/client-details?id=${client.id}`)
                  }
                  style={{
                    maxWidth: `200px`,
                    cursor: "pointer",
                  }}
                >
                  <CommImageNameComp
                    imgSource={null}
                    name={client.name}
                    color={client.color ?? null}
                    size="36px"
                    index={index}
                    hoverUnderline
                  />
                </TableItem>
                <TableItem>
                  <CommonFlex
                    direction="column"
                    alignItems="start"
                    gap="5px"
                    style={{
                      maxWidth: `240px`,
                    }}
                  >
                    <TableText
                      name
                      wrap="normal"
                      style={{
                        maxWidth: `240px`,
                        wordWrap: "break-word",
                      }}
                    >
                      {client.email}
                    </TableText>
                    <TableText name>{client.phone_number}</TableText>
                  </CommonFlex>
                  <TableText name>{client.phone}</TableText>
                </TableItem>
                <TableItem>
                  <TableText name>{client.office_location || "-"}</TableText>
                </TableItem>
                <TableItem>
                  <InvoicedCapsule
                    width="fit-content"
                    invoiced={
                      client.status === "Invoiced Before" ? true : false
                    }
                  >
                    {client.status}
                  </InvoicedCapsule>
                </TableItem>
                <TableItem>
                  <ActionContainer>
                    <Select
                      isSearchable={false}
                      value={{ value: "", label: "Actions" }}
                      options={
                        clientType === "active"
                          ? activeTabOptions
                          : archiveTabOptions
                      }
                      placeholder="Actions"
                      onChange={(e) => {
                        onActionSelect(e.value, client.id);
                      }}
                      styles={FilterDropDownStyle({
                        height: "39px",
                        width: `150px`,
                        menuWidth: `150px`,
                      })}
                    />
                  </ActionContainer>
                </TableItem>
              </ClientTableContainer>
            </Fragment>
          ))}
        </>
      ) : (
        <NoDataComponent
          title={`No ${
            clientType === "active" ? "active" : "archived"
          } clients found.`}
        />
      )}
    </Container>
  );
};

export default ClientsTable;
