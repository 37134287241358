import { connect } from 'react-redux';
import JiraSync from './JiraSync';

import { inviteMemberBulk, getJiraInfo } from '../jiraIntegrationsActions';


const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,

  isLoading: state.jiraIntegrations.isLoading,
  syncIsLoading: state.jiraIntegrations.syncIsLoading,
  jiraInfo: state.jiraIntegrations.jiraInfo,
})

const mapDispatchToProps = (dispatch) => ({
  inviteMemberBulk: (payload) => dispatch(inviteMemberBulk(payload)),
  getJiraInfo: (payload) => dispatch(getJiraInfo(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(JiraSync)