import React, { useState, useEffect, useRef } from "react";

import { TabLeftGrid } from "../../styledComponents/members";
import {
  PageTitle,
  Container,
  HeaderContainer,
  InputWithLabelSection,
  FilterLabel,
  InputField,
} from "../../styledComponents/common";
import {
  TabButtonContainer,
  TabButton,
  ColoredButtonWithIcon,
  ButtonIcon,
} from "../../styledComponents/buttons";

import EditOrganizationModal from "./EditOrganizationModal";
import OrganizationsTable from "./OrganizationsTable";

import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";

import Plus from "../../assets/img/icons/plus_white.svg";
import { useDispatch } from "react-redux";
import { setPathName } from "../../filterReducer";

const Organizations = ({
  getOrganizationTypes,
  getOrganizationPlans,
  getOrganizationList,
  orgUpdateLoading,
  organizations,
  orgCreated,
  createdOrgId,
  createdOrgIsActive,
  location,
  history,
  updateOrganization,
  selectOrganization,
  createOrganization,
  deleteOrganization,
  isLoading,
  filters,
}) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(
    filters?.pathname === "/user/organizations" && filters?.activeTab
      ? filters?.activeTab
      : "1"
  );
  const [search, setSearch] = useState(
    filters?.pathname === "/user/organizations" && filters?.search
      ? filters?.search
      : ""
  );
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [organizationModalVisible, setOrganizationModalVisible] =
    useState(false);
  const [activeList, setActiveList] = useState([]);
  const [inactiveList, setInactiveList] = useState([]);

  useEffect(() => {
    dispatch(
      setPathName({
        pathname: location.pathname,
        activeTab,
        search,
      })
    );
  }, [activeTab, search]);

  useEffect(() => {
    getOrganizationTypes();
    getOrganizationPlans();
    getOrganizationList();
  }, []);

  useEffect(() => {
    if (!orgUpdateLoading) {
      setOrganizationModalVisible(false);
      setSelectedOrganization(null);
    }
  }, [orgUpdateLoading]);

  const prevProps = useRef({ organizations, orgCreated, createdOrgId });

  useEffect(() => {
    filter(organizations.list);
  }, [organizations.list, search]);

  useEffect(() => {
    if (orgCreated && orgCreated !== prevProps.current.orgCreated) {
      if (
        createdOrgId &&
        createdOrgId !== prevProps.current.createdOrgId &&
        createdOrgId !== null &&
        createdOrgIsActive === false
      ) {
        if (location.pathname === "/user/organizations") {
          history.push(`/user/subscription?organization_id=${createdOrgId}`);
        }
      }
    }
    prevProps.current = { organizations, orgCreated, createdOrgId };
  }, [organizations, orgCreated, createdOrgId, search, organizations.list]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleModal = () => {
    setOrganizationModalVisible(!organizationModalVisible);
    setSelectedOrganization(null);
  };

  const archive = (org) => {
    updateOrganization({ id: org.id, is_active: false });
  };

  const unarchive = (organization) => {
    selectOrganization(organization);
    history.push("/user/current-plan");
  };

  const edit = (id) => {
    const selectedOrganization = organizations.list.find(
      (org) => org.id === id
    );
    setSelectedOrganization(selectedOrganization);
    setOrganizationModalVisible(true);
  };

  const createOrganizationHandler = (data) => {
    createOrganization(data);
  };

  const onTextChange = (e) => {
    const search = e.target.value;
    if (search.length - search.length > 1) {
      return;
    }
    setSearch(search);
    filter(organizations.list, search);
  };

  const filter = (data) => {
    let activeList = [];
    let inactiveList = [];
    data.forEach((e) => {
      if (search && e.name.toLowerCase().indexOf(search.toLowerCase()) === -1) {
        return;
      }
      if (e.is_active) {
        activeList.push(e);
      } else if (!e.is_active) {
        inactiveList.push(e);
      }
    });
    setActiveList(activeList);
    setInactiveList(inactiveList);
  };

  const types = organizations && organizations.types;
  const plans = organizations && organizations.plans;

  return (
    <div className="content">
      <EditOrganizationModal
        isOpen={organizationModalVisible}
        toggle={toggleModal}
        mode={selectedOrganization ? "update" : "create"}
        updateOrganization={updateOrganization}
        types={types}
        plans={plans}
        organization={selectedOrganization}
        isLoading={orgUpdateLoading}
      />
      <PageTitle>Organizations</PageTitle>
      <HeaderContainer>
        <TabLeftGrid>
          <InputWithLabelSection>
            <FilterLabel>Type</FilterLabel>
            <TabButtonContainer>
              <TabButton
                onClick={() => toggle("1")}
                selected={activeTab}
                index={"1"}
              >
                Active
              </TabButton>
              <TabButton
                onClick={() => toggle("2")}
                selected={activeTab}
                index={"2"}
              >
                Archived
              </TabButton>
            </TabButtonContainer>
          </InputWithLabelSection>

          <InputWithLabelSection>
            <FilterLabel>Search Organization</FilterLabel>
            <InputField
              type="text"
              placeholder="Search organization by name"
              value={search}
              onChange={(e) => onTextChange(e)}
            />
          </InputWithLabelSection>
        </TabLeftGrid>

        <ColoredButtonWithIcon
          onClick={() => history.push("/user/add-organization")}
        >
          <ButtonIcon src={Plus} alt="" />
          Add Organization
        </ColoredButtonWithIcon>
      </HeaderContainer>
      <Container padding="0 0 10px 0" marginTop="0">
        {isLoading ? (
          <ComponentCircleLoader />
        ) : activeTab === "1" ? (
          activeList.length ? (
            <OrganizationsTable
              edit={edit}
              toggleArchive={archive}
              organizationsList={activeList}
              deleteOrganization={deleteOrganization}
            />
          ) : (
            <NoDataComponent
              padding="4vh 0"
              title={
                search.trim()
                  ? "No organization found with this name!"
                  : "You don't have active organizations"
              }
            />
          )
        ) : activeTab === "2" ? (
          inactiveList.length ? (
            <OrganizationsTable
              edit={edit}
              toggleArchive={unarchive}
              organizationsList={inactiveList}
              deleteOrganization={deleteOrganization}
            />
          ) : (
            <NoDataComponent
              padding="4vh 0"
              title={
                search.trim()
                  ? "No organization found with this name!"
                  : "You don't have archived organizations"
              }
            />
          )
        ) : (
          <NoDataComponent
            padding="4vh 0"
            title={
              search.trim()
                ? "No organization found with this name!"
                : `You don't have any ${
                    activeTab === "1"
                      ? " active"
                      : activeTab === "2"
                      ? " archived"
                      : ""
                  } organizations`
            }
          />
        )}
      </Container>
    </div>
  );
};

export default Organizations;
