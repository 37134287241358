import { connect } from 'react-redux';
import JiraIntegration from './JiraIntegration';

import { connectJiraIntegration, updateJiraCredentials, getJiraCredentials } from './jiraIntegrationsActions';

const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,

  isLoading: state.jiraIntegrations.isLoading,
  syncIsLoading: state.jiraIntegrations.syncIsLoading,
  jiraInfo: state.jiraIntegrations.jiraInfo,
  jiraCredentials: state.jiraIntegrations.jiraCredentials,
})

const mapDispatchToProps = (dispatch) => ({
  connectJiraIntegration: (payload) =>
    dispatch(connectJiraIntegration(payload)),
  updateJiraCredentials: (payload) => 
    dispatch(updateJiraCredentials(payload)),
  getJiraCredentials: (payload) => 
    dispatch(getJiraCredentials(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(JiraIntegration)