import BackButtonComponent from "../../../../components/BackButtonComponent/BackButtonComponent";
import ComponentCircleLoader from "../../../../components/Loaders/ComponentCircleLoader";
import moment from "moment";
import { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  CardTitle,
  CommonFlex,
  CommonGrid,
  CommonText,
  ContentHeader,
  PageTitle,
} from "../../../../styledComponents/common";
import { Container } from "../../../../styledComponents/createProject";
import CustomReportTable from "./CustomReportTable";
import { ActivityTextSection } from "../../../../styledComponents/members";
import { ColoredButton, ExportButtonIcon, ExportButtonWithIcon, WhiteButton } from "../../../../styledComponents/buttons";
import ReportDeleteModal from "./ReportDeleteModal";
import ExportModal from "../../../../custom_modules/ReportsNew/ExportModal";
import NoDataComponent from "../../../../components/NoDataComponent/NoDataComponent";

import exportImg from "../../../../assets/img/icons/export_icon.svg";

const CustomReportDetails = ({
  history,
  customReportDetails,
  getCustomReportDetails,
  organization,
  isLoading,
  deleteCustomReports,
  isDeleteLoading,
  deletedReports,
}) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const reportId = new URLSearchParams(useLocation().search).get("id");

  const handleDeleteReport = () => {
    deleteCustomReports({
      organization_id: organization.id,
      report_ids: [reportId],
    });
  };
  const popupToggle = () => {
    setExportModalOpen(!exportModalOpen);
  };
  useEffect(() => {
    if (organization?.id && reportId)
      getCustomReportDetails({
        organization_id: organization.id,
        report_id: reportId,
      });
  }, [organization]);

  useEffect(() => {
    if (deletedReports?.includes(reportId)) {
      history.push("/user/reports/custom-report");
      setIsDeleteModalVisible(false);
    }
  }, [deletedReports]);

  const processedReport = useMemo(() => {
    if (
      customReportDetails?.timesheets &&
      customReportDetails.timesheets.length > 0
    ) {
      if (customReportDetails.features.includes("task_wise")) {
        const taskMap = new Map();
        customReportDetails.timesheets.forEach((timesheet) => {
          if (
            !taskMap.has(
              `${timesheet.user_id}${
                timesheet.task_ids[0] ? timesheet.task_ids[0] : "-"
              }`
            )
          ) {
            taskMap.set(
              `${timesheet.user_id}${
                timesheet.task_ids[0] ? timesheet.task_ids[0] : "-"
              }`,
              {
                userId: timesheet.user_id,
                memberName: timesheet.member_name,
                memberEmail: timesheet.member_email,
                memberRole: timesheet.role,
                memberColor: timesheet.member_color,
                avatar: timesheet.avatar,
                totalTimeWorked: 0,
                idleTime: 0,
                averageActivity: 0,
                cumulativeAverageActivity: 0,
                manualTime: 0,
                projectList: new Set(),
                taskList: new Set(),
                taskName: timesheet?.task_name ? timesheet?.task_name : "-",
                notes: [],
              }
            );
          }

          const taskData = taskMap.get(
            `${timesheet.user_id}${
              timesheet.task_ids[0] ? timesheet.task_ids[0] : "-"
            }`
          );

          taskData.totalTimeWorked +=
            timesheet.end_timestamp - timesheet.start_timestamp;
          taskData.idleTime +=
            timesheet.idle_times?.reduce(
              (total, idle) =>
                total + idle.end_timestamp - idle.start_timestamp,
              0
            ) ?? 0;

          taskData.manualTime +=
            timesheet.manual_times?.reduce(
              (total, manual) => total + manual.duration,
              0
            ) || 0;

          taskData.projectList.add(timesheet.project_name);
          taskData.taskList.add(timesheet.task_name);

          if (timesheet.notes.length > 0) {
            taskData.notes = timesheet.notes.map((note) => note.content);
          }
          if (timesheet.activities && timesheet.activities.length > 0) {
            taskData.cumulativeAverageActivity += timesheet.activities.reduce(
              (sum, activity) =>
                sum +
                activity.activity *
                  (activity.end_timestamp - activity.start_timestamp),
              0
            );
          }
        });
        return Array.from(taskMap.values())
          .map((taskData) => ({
            ...taskData,
            projects: Array.from(taskData.projectList).join(", "),
            tasks: Array.from(taskData.taskList).join(", "),
            idleTimePercentage: (
              (taskData.idleTime / taskData.totalTimeWorked) *
              100
            ).toFixed(2),
            manualTimePercentage: (
              (taskData.manualTime / taskData.totalTimeWorked) *
              100
            ).toFixed(2),
            activeTime: taskData.cumulativeAverageActivity / 100,
            neutralTime: parseInt(
              taskData.totalTimeWorked -
                taskData.cumulativeAverageActivity / 100 -
                taskData.idleTime
            ),
            averageActivity: parseInt(
              taskData.cumulativeAverageActivity / taskData.totalTimeWorked
            ),
          }))
          .sort((a, b) => a.memberName.localeCompare(b.memberName));
      } else {
        const userMap = new Map();
        customReportDetails?.timesheets?.forEach((timesheet) => {
          if (!userMap.has(timesheet.user_id)) {
            userMap.set(timesheet.user_id, {
              userId: timesheet.user_id,
              memberName: timesheet.member_name,
              memberEmail: timesheet.member_email,
              memberRole: timesheet.role,
              memberColor: timesheet.member_color,
              avatar: timesheet.avatar,
              totalTimeWorked: 0,
              idleTime: 0,
              averageActivity: 0,
              cumulativeAverageActivity: 0,
              manualTime: 0,
              projectList: new Set(),
              taskList: new Set(),
              taskName: timesheet?.task_name ? timesheet?.task_name : "-",
              notes: [],
            });
          }

          const userData = userMap.get(timesheet.user_id);
          userData.totalTimeWorked +=
            timesheet.end_timestamp - timesheet.start_timestamp;
          userData.idleTime +=
            timesheet.idle_times?.reduce(
              (total, idle) =>
                total + idle.end_timestamp - idle.start_timestamp,
              0
            ) ?? 0;

          userData.manualTime +=
            timesheet.manual_times?.reduce(
              (total, manual) => total + manual.duration,
              0
            ) || 0;

          userData.projectList.add(timesheet.project_name);
          userData.taskList.add(timesheet.task_name);

          if (timesheet.notes.length > 0) {
            userData.notes = timesheet.notes.map((note) => note.content);
          }
          if (timesheet.activities && timesheet.activities.length > 0) {
            userData.cumulativeAverageActivity += timesheet.activities.reduce(
              (sum, activity) =>
                sum +
                activity.activity *
                  (activity.end_timestamp - activity.start_timestamp),
              0
            );
          }
        });

        return Array.from(userMap.values())
          .map((userData) => ({
            ...userData,
            projects: Array.from(userData.projectList).join(", "),
            tasks:
              customReportDetails.task_ids?.length > 0
                ? Array.from(userData.taskList).join(", ")
                : "(All Tasks)",
            idleTimePercentage: (
              (userData.idleTime / userData.totalTimeWorked) *
              100
            ).toFixed(2),
            manualTimePercentage: (
              (userData.manualTime / userData.totalTimeWorked) *
              100
            ).toFixed(2),
            activeTime: userData.cumulativeAverageActivity / 100,
            neutralTime: parseInt(
              userData.totalTimeWorked -
                userData.cumulativeAverageActivity / 100 -
                userData.idleTime
            ),
            averageActivity: parseInt(
              userData.cumulativeAverageActivity / userData.totalTimeWorked
            ),
          }))
          .sort((a, b) => a.memberName.localeCompare(b.memberName));
      }
    }
  }, [customReportDetails]);

  const processedReportSums = useMemo(() => {
    if (processedReport?.length > 0) {
      let totalNotes = 0;
      let totalTime = 0;
      let totalIdleTime = 0;
      let totalManualTime = 0;
      let totalNeutralTime = 0;
      let totalActiveTime = 0;
      const allProjects = new Set();
      const allTasks = new Set();

      processedReport.forEach((report) => {
        totalTime += report.totalTimeWorked;
        totalIdleTime += report.idleTime;
        totalManualTime += report.manualTime;
        totalNeutralTime += report.neutralTime;
        totalActiveTime += report.activeTime;
        report.projectList.forEach((project) => {
          allProjects.add(project);
        });
        report.taskList.forEach((task) => {
          task?.length > 0 && allTasks.add(task);
        });
        totalNotes += report.notes.length ? report.notes.length : 0;
      });
      return {
        totalTime,
        totalNotes,
        totalIdleTime,
        totalManualTime,
        uniqueProjects: Array.from(allProjects).length,
        uniqueTasks: Array.from(allTasks).length,
        totalActiveTime,
        totalNeutralTime,
        manualTimePercentage: ((totalManualTime / totalTime) * 100).toFixed(2),
        idleTimePercentage: ((totalIdleTime / totalTime) * 100).toFixed(2),
      };
    }
    return {};
  }, [processedReport]);

  return (
    <div className="content">
      <PageTitle>Custom Report Details</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => history.push("/user/reports/custom-report")}
          subTitle="Back to Custom Reports"
        />
      </ContentHeader>
      <Container
        style={{
          padding: `20px`,
          marginTop: `20px`,
          background: `#FCFDFE`,
          overflow: "hidden",
        }}
      >
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            {customReportDetails ? (
              <>
                <ExportModal
                  isOpen={exportModalOpen}
                  toggle={popupToggle}
                  title="customReport"
                  reportName={customReportDetails?.name}
                  startDate={customReportDetails?.start_date}
                  endDate={customReportDetails?.end_date}
                  features={customReportDetails?.features}
                  customReportData={processedReport}
                  customReportSums={processedReportSums}
                />
                <ReportDeleteModal
                  isOpen={isDeleteModalVisible}
                  toggle={() => setIsDeleteModalVisible(!isDeleteModalVisible)}
                  handleDelete={handleDeleteReport}
                  isLoading={isDeleteLoading}
                />
                <CommonGrid alignItem="center">
                  <ActivityTextSection
                    gap="5px"
                    style={{ alignContent: `start` }}
                  >
                    <CommonFlex>
                      {" "}
                      <CardTitle>{customReportDetails?.name}</CardTitle>
                      <CommonText>
                        {" "}
                        {moment(customReportDetails?.start_date).format(
                          "D MMMM, YYYY"
                        )}
                        {" - "}
                        {moment(customReportDetails?.end_date).format(
                          "D MMMM, YYYY"
                        )}
                      </CommonText>{" "}
                    </CommonFlex>
                  </ActivityTextSection>
                  <CommonGrid>
                    {processedReport && (
                      <ExportButtonWithIcon onClick={() => popupToggle()}>
                        <ExportButtonIcon src={exportImg} alt="" />
                        Export
                      </ExportButtonWithIcon>
                    )}
                    <WhiteButton
                      type="delete"
                      // height="40px"
                      // width="40px"
                      padding="0 20px"
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      Delete
                    </WhiteButton>
                  </CommonGrid>
                </CommonGrid>
                {customReportDetails?.timesheets?.length > 0 ? (
                  <CustomReportTable
                    report={processedReport}
                    features={customReportDetails?.features}
                  />
                ) : (
                  <NoDataComponent title="No Data In Selected Date Range" />
                )}
              </>
            ) : (
              <NoDataComponent title="Report not found" />
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default CustomReportDetails;
