import {
  OnboardingPreviousButton,
  PreviousButtonIcon,
} from "../onboardingStepsStyles";

import previousIcon from "../../../../assets/img/onboardingImg/up_arrow.svg";

export default function PreviousButton({
  horizontal = false,
  handleClick,
  type,
}) {
  return (
    <OnboardingPreviousButton
      horizontal={horizontal}
      onClick={() => handleClick(type)}
    >
      <PreviousButtonIcon horizontal src={previousIcon} alt="" />
    </OnboardingPreviousButton>
  );
}
