import { useState, useEffect, useRef, Fragment } from "react";
import {
  AssigneeSection,
  DetailsAssigneeRoundImageSection,
  AssigneeRoundImage,
  RoundPersonImage,
} from "../../../styledComponents/createProject";
import {
  CommonText,
  ShowMoreAssigneeList,
  AssignListText,
} from "../../../styledComponents/common";

import AssigneeTooltip from "../../../components/Tooltip/AssigneeTooltip";
import FirstRoundLetterComp from "../../../components/FirstRoundLetter/FirstRoundLetter";

export const AssigneeImagesSection = ({ assigneeManage }) => {
  const [moreAssignee, setMoreAssignee] = useState(false);
  const moreRef1 = useRef(null);
  const moreRef2 = useRef(null);

  useEffect(() => {
    if (assigneeManage && assigneeManage.length < 11) {
      setMoreAssignee(false);
    }
  }, [assigneeManage]);

  //function
  useOutsideClickHandler(moreRef1, moreRef2);

  const showMoreAssignee = () => {
    setMoreAssignee(true);
  };

  function useOutsideClickHandler(moreRef1, moreRef2) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          moreRef1.current &&
          !moreRef1.current.contains(event.target) &&
          moreRef2.current &&
          !moreRef2.current.contains(event.target)
        ) {
          setMoreAssignee(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [moreRef1, moreRef2, moreAssignee]);
  }
  return (
    <DetailsAssigneeRoundImageSection
      position={assigneeManage.length}
      style={{ marginRight: `auto` }}
    >
      {assigneeManage.length > 10 && (
        <AssigneeRoundImage
          index="more"
          onClick={() => {
            showMoreAssignee();
          }}
          ref={moreRef2}
          style={{
            border: `1px solid #fff`,
            paddingLeft: "3px",
          }}
        >
          {`+${assigneeManage.length - 10}`}
        </AssigneeRoundImage>
      )}
      {assigneeManage
        .filter((person, fi) => fi < 10)
        .map((filteredPerson, i) => (
          <AssigneeRoundImage
            key={i}
            index={assigneeManage.length < 4 ? i + 1 : i + 2}
          >
            <AssigneeTooltip toolTipText={filteredPerson.name} />
            {filteredPerson.avatar ? (
              <RoundPersonImage src={filteredPerson.avatar} alt="addPeople" />
            ) : (
              <FirstRoundLetterComp
                text={filteredPerson.name}
                backColor={i}
                color={filteredPerson.color}
              />
            )}
          </AssigneeRoundImage>
        ))}
      {moreAssignee && (
        <ShowMoreAssigneeList ref={moreRef1} style={{ left: "300px" }}>
          {assigneeManage.map((member, index) => (
            <Fragment key={index}>
              <AssigneeRoundImage
                index={0}
                style={{ cursor: "default" }}
                size="30px"
              >
                {member.avatar ? (
                  <RoundPersonImage
                    src={member.avatar}
                    alt=""
                    size="30px"
                    borderColor="#c2cce1"
                  />
                ) : (
                  <FirstRoundLetterComp
                    // style={{ border: "1px solid #c2cce1" }}
                    text={member.name}
                    backColor={index}
                    color={member.color || null}
                    size="34px"
                    fontSize="12px"
                  />
                )}
              </AssigneeRoundImage>
              <AssignListText>{member.name}</AssignListText>
            </Fragment>
          ))}
        </ShowMoreAssigneeList>
      )}
    </DetailsAssigneeRoundImageSection>
  );
};

const DetailsAssigneeSection = (props) => {
  return (
    <AssigneeSection>
      <CommonText $label>Assignees</CommonText>
      <AssigneeImagesSection assigneeManage={props.assigneeList} />
    </AssigneeSection>
  );
};

export default DetailsAssigneeSection;
