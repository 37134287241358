import IntegrationsHome from "../custom_modules/IntegrationsNew";
import IntegrationSetup from "../custom_modules/IntegrationsNew/Setup";
import IntegrationDetails from "../custom_modules/IntegrationsNew/Details";

// auth
import AuthLoader from "../custom_modules/IntegrationsNew/Auth";
import JiraIntegration from "custom_modules/IntegrationsNew/Jira";
import JiraSync from "custom_modules/IntegrationsNew/Jira/JiraSync";
import JiraDetails from "custom_modules/IntegrationsNew/Jira/JiraDetails";

const integrationsRoutes = [
  {
    name: "Integrations",
    path: "/",
    component: IntegrationsHome,
    exact: true,
  },
  {
    name: "Integration Authorization",
    path: "/:name/auth-setup",
    component: AuthLoader,
    exact: true,
  },
  {
    name: "Integration Setup",
    path: "/:id/setup",
    component: IntegrationSetup,
  },
  {
    name: "Integration Details",
    path: "/:enabled_integration_id/details",
    component: IntegrationDetails,
  },
  {
    name: "Jira Integration Details",
    path: "/jira-details",
    component: JiraDetails,
  },
  {
    name: "Jira Integration Request Access",
    path: "/jira-request-access",
    component: JiraIntegration,
  },
  {
    name: "Jira Update Credentials",
    path: "/jira-update-credentials",
    component: JiraIntegration,
  },
  {
    name: "Jira Integration Sync",
    path: "/jira-sync",
    component: JiraSync,
  },
];

export default integrationsRoutes;
