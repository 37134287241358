import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";

//Deafult CSS files
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-virtualized/styles.css";
import "lightgallery.js/dist/css/lightgallery.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-dates/lib/css/_datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
//Store
import { store, persistor } from "./configureStore";

//Persist
import { PersistGate } from "redux-persist/integration/react";

//Provider
import { Provider, ReactReduxContext } from "react-redux";

import { ToastContainer, Slide } from "react-toastify";

//react-dates
import "react-dates/initialize";

// import TagManager from "react-gtm-module";

import { ConnectedRouter } from "connected-react-router";
import App from "./App";
import moment from "moment";
import momentLocalizer from "react-widgets-moment";
import "react-widgets/dist/css/react-widgets.css";
//import "react-table/react-table.css";
import { checkToken } from "./custom_modules/SignUp/signUpActions";

import { initClarity } from "./utils/clarity";

import { ThemeProvider } from "styled-components";
import { GlobalStyles, lightTheme } from "./styles";

momentLocalizer(moment);

// TagManager.initialize({
//   gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
// });

// Initialize Clarity
initClarity();

const hist = createBrowserHistory();
ReactDOM.render(
  <Provider store={store} context={ReactReduxContext}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles />
        <ConnectedRouter history={hist} context={ReactReduxContext}>
          <App />
        </ConnectedRouter>
        <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          transition={Slide}
          pauseOnHover
        />
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

if (localStorage.getItem("access_token")) {
  store.dispatch(checkToken());
}
