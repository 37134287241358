//Effects
import { all, put, takeLatest, delay, throttle } from "redux-saga/effects";

//Service
import { defaultApi } from "../../../utils/axiosApi";

//Constants
import {
  GET_IS_INSTALLED_JIRA,
  GET_IS_INSTALLED_JIRA_SUCCESS,
  GET_IS_INSTALLED_JIRA_FAILURE,
  CONNECT_JIRA,
  CONNECT_JIRA_SUCCESS,
  CONNECT_JIRA_FAILURE,
  SYNC_JIRA,
  SYNC_JIRA_SUCCESS,
  SYNC_JIRA_FAILURE,
  DELETE_JIRA,
  DELETE_JIRA_SUCCESS,
  DELETE_JIRA_FAILURE,
  UPDATE_JIRA,
  UPDATE_JIRA_SUCCESS,
  UPDATE_JIRA_FAILURE,
  GET_IS_ACTIVE_JIRA,
  GET_IS_ACTIVE_JIRA_SUCCESS,
  GET_IS_ACTIVE_JIRA_FAILURE,
  INVITE_MEMBER_BULK,
  INVITE_MEMBER_BULK_SUCCESS,
  INVITE_MULTIPLE_MEMBERS_FAILURE,
  UPDATE_CREDENTIALS,
  UPDATE_CREDENTIALS_SUCCES,
  UPDATE_CREDENTIALS_FAILURE,
  GET_CREDENTIALS_SUCCES,
  GET_CREDENTIALS_FAILURE,
  GET_JIRA_INFO_SUCCESS,
  GET_JIRA_INFO_FAILURE,
  GET_CREDENTIALS,
  GET_JIRA_INFO,
} from "../../../modules/constants";


export function* getIsInstalledJiraRequest({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/manual_integrations/jira/get_is_installed/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_IS_INSTALLED_JIRA_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_IS_INSTALLED_JIRA_FAILURE,
      payload: err,
    });
  }
}

export function* connectJiraRequest({ payload }) {
  if (!payload) return;
  const { organization_id, ...data } = payload;
  try {
    const url = `${organization_id}/manual_integrations/jira/`;
    const response = yield defaultApi(url, "POST", data);
    yield delay(1500);
    yield put({
      type: CONNECT_JIRA_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: CONNECT_JIRA_FAILURE,
      payload: err,
    });
  }
}

export function* syncJiraRequest({ payload }) {
  if (!payload) return;
  const { organization_id} = payload;
  try {
    const url = `${organization_id}/manual_integrations/jira/sync/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: SYNC_JIRA_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: SYNC_JIRA_FAILURE,
      payload: err,
    });
  }
}

export function* deleteJiraRequest({ payload }) {
  if (!payload) return;
  const { organization_id} = payload;
  try {
    const url = `${organization_id}/manual_integrations/jira/remove/`;
    const response = yield defaultApi(url, "DELETE");
    yield delay(1500);
    yield put({
      type: DELETE_JIRA_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: DELETE_JIRA_FAILURE,
      payload: err,
    });
  }
}

export function* getIsActiveJiraRequest({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/manual_integrations/jira/get_is_active/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_IS_ACTIVE_JIRA_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_IS_ACTIVE_JIRA_FAILURE,
      payload: err,
    });
  }
}

export function* updateJiraRequest({ payload }) {
  if (!payload) return;
  const { organization_id, ...data } = payload;
  try {
    const url = `${organization_id}/manual_integrations/jira/update_is_active/`;
    const response = yield defaultApi(url, "PATCH", data);
    yield delay(1500);
    yield put({
      type: UPDATE_JIRA_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: UPDATE_JIRA_FAILURE,
      payload: err,
    });
  }
}

export function* getJiraCredentialsRequest({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/manual_integrations/jira/get_credentials/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_CREDENTIALS_SUCCES,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_CREDENTIALS_FAILURE,
      payload: err,
    });
  }
}

export function* getJiraInfoRequest({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/manual_integrations/jira/get_info/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_JIRA_INFO_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_JIRA_INFO_FAILURE,
      payload: err,
    });
  }
}

export function* updateCredentialsRequest({ payload }) {
  if (!payload) return;
  const { organization_id, ...data } = payload;
  try {
    const url = `${organization_id}/manual_integrations/jira/update_credentials/`;
    const response = yield defaultApi(url, "PATCH", data);
    yield delay(1500);
    yield put({
      type: UPDATE_CREDENTIALS_SUCCES,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: UPDATE_CREDENTIALS_FAILURE,
      payload: err,
    });
  }
}

export function* inviteMemberBulk({ payload }) {
  if (!payload) return;
  const { organization_id, ...data } = payload;
  try {
    const url = `${organization_id}/members/invitations/multiple/`;
    const response = yield defaultApi(url, "POST", data);
    yield delay(1500);
    yield put({
      type: INVITE_MEMBER_BULK_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: INVITE_MULTIPLE_MEMBERS_FAILURE,
      payload: err,
    });
  }
}


export default function* root() {
  yield all([
    takeLatest(GET_IS_INSTALLED_JIRA, getIsInstalledJiraRequest),
    takeLatest(GET_IS_ACTIVE_JIRA, getIsActiveJiraRequest),
    takeLatest(CONNECT_JIRA, connectJiraRequest),
    takeLatest(SYNC_JIRA, syncJiraRequest),
    takeLatest(DELETE_JIRA, deleteJiraRequest),
    takeLatest(UPDATE_JIRA, updateJiraRequest),
    takeLatest(INVITE_MEMBER_BULK, inviteMemberBulk),
    takeLatest(UPDATE_CREDENTIALS, updateCredentialsRequest),
    takeLatest(GET_CREDENTIALS, getJiraCredentialsRequest),
    takeLatest(GET_JIRA_INFO, getJiraInfoRequest),
  ]);
}
