import { useState, useEffect, useRef } from "react";
import {
  OnboardingStepsContainer,
  OnboardingArtworkDiv,
  OnboardingArtwork,
  PricingPlanContainer,
} from "./onboardingStepsStyles";
import TagManager from "react-gtm-module";
import AddOrganization from "./StepComponents/AddOrganization";
import AddProject from "./StepComponents/AddProject";
// import PricingPlan from "./StepComponents/PricingPlan/PricingPlan";
import SelectPlan from "./StepComponents/SelectPlan";
import InviteMembers from "./StepComponents/InviteMembers";
// import PreviousButton from "./StepComponents/PreviousButton";

import { createNotification } from "../../../modules/notificationManager";
import { useDebouncedCallback } from "use-debounce";

import artwork from "../../../assets/img/onboardingImg/background-artwork.png";

const OnboardingSteps = (props) => {
  const [organizationData, setOrganizationData] = useState(null);
  const [projectsData, setProjectsData] = useState([]);
  const [invitationsData, setInvitationsData] = useState([]);
  const [activeStep, setActiveStep] = useState("organization");
  const [hideArtwork, setHideArtwork] = useState(false);

  const tagManagerArgs = {
    gtmId: "GTM-NH9X5RJV",
  };

  // refs
  const organizationRef = useRef(null);
  const pricingRef = useRef(null);
  const projectRef = useRef(null);
  const inviteMembersRef = useRef(null);

  const { location, advertise } = props;

  const [eliteMonthly, setEliteMonthly] = useState({
    id: "",
    paddleId: null,
  });

  const [standardMonthly, setStandardMonthly] = useState({
    id: "",
    paddleId: null,
  });

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    // window.addEventListener("scroll", onScroll);
    props.getOrganizationPlans();
    // return () => {
    //   window.removeEventListener("scroll", onScroll);
    // };
  }, []);

  useEffect(() => {
    if (props.redirect) {
      props.history.push({
        pathname: "/setup/onboarding-loader",
        state: {
          message: "onboard",
          advertise: location.state && location.state.advertise,
        },
      });
    }
  }, [props.redirect]);

  useEffect(() => {
    if (props.subscriptionPlans?.length > 0) {
      props.subscriptionPlans.map((item) => {
        if (item.name === "Elite" && item.interval === "monthly") {
          setEliteMonthly({
            id: item.id,
            paddleId: item.paddle_id,
          });
        } else if (item.name === "Standard" && item.interval === "monthly") {
          setStandardMonthly({
            id: item.id,
            paddleId: item.paddle_id,
          });
        }
      });
    }
  }, [props.subscriptionPlans]);

  useEffect(() => {
    if (
      location.state &&
      location.state.message &&
      location.state.message === "onboard" &&
      location.state.advertise &&
      location.state.advertise === "google"
    ) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "onboardingSteps",
        page: location.pathname + location.search, // Send the current URL as part of the event
      });
    }
  }, [location]);

  // const onScroll = () => {
  //   if (pricingRef && pricingRef.current) {
  //     if (
  //       window.scrollY > pricingRef.current.offsetTop - 600 &&
  //       window.scrollY <
  //         pricingRef.current.offsetTop + pricingRef.current.clientHeight &&
  //       hideArtwork === false
  //     ) {
  //       setHideArtwork(true);
  //     } else {
  //       setHideArtwork(false);
  //     }
  //   }
  // };

  const handleBack = (step) => {
    let offsetPosition;
    if (step === "project") {
      if (organizationRef && organizationRef.current) {
        offsetPosition = organizationRef.current.offsetTop - 70;
      }
      setActiveStep("organization");
    } else if (step === "invite-member") {
      if (projectRef && projectRef.current) {
        offsetPosition = projectRef.current.offsetTop - 100;
      }
      setActiveStep("project");
    } else if (step === "pricing") {
      // To bypass pricing step
      if (inviteMembersRef && inviteMembersRef.current) {
        offsetPosition = inviteMembersRef.current.offsetTop - 100;
      }
      setActiveStep("invite-member");
    }
    if (!offsetPosition) return;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const handleNext = (step) => {
    let offsetPosition;
    if (step === "organization") {
      if (projectRef && projectRef.current) {
        offsetPosition = projectRef.current.offsetTop - 100;
        setActiveStep("project");
      }
    } else if (step === "project") {
      if (inviteMembersRef && inviteMembersRef.current) {
        offsetPosition = inviteMembersRef.current.offsetTop - 70;
        setActiveStep("invite-member");
      }
    } else if (step === "invite-member") {
      if (pricingRef && pricingRef.current) {
        offsetPosition = pricingRef.current.offsetTop - 100;
        setActiveStep("pricing");
      }
    }
    if (!offsetPosition) return;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  // const handleSubmitDataFromPlan = (planId) => {
  //   if (organizationData && organizationData.name && planId) {
  //     const organization = { ...organizationData };
  //     organization.plan_id = planId;
  //     const payload = {
  //       organization,
  //       projects: projectsData,
  //       invitations: null,
  //     };
  //     props.saveOnboardingData(payload);
  //   }
  // };

  const handleSubmitData = useDebouncedCallback((planId) => {
    if (!planId) {
      createNotification("error", "Something went wrong. Please try again!");
      return;
    }
  
    if (!organizationData?.name) {
      let offsetPosition;
      if (organizationRef && organizationRef.current) {
        offsetPosition = organizationRef.current.offsetTop - 70;
      }
      setActiveStep("organization");
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
  
      createNotification("error", "Please enter organization name");
      return;
    }
  
    const payload = {
      organization: { ...organizationData, plan_id: planId },
      projects: projectsData,
      invitations: invitationsData.length > 0 ? invitationsData : null,
    };
  
    props.saveOnboardingData(payload);
  }, 3000, { leading: true, trailing: false });

  return (
    <div>
      <OnboardingStepsContainer>
        <AddOrganization
          ref={organizationRef}
          history={props.history}
          activeStep={activeStep}
          handleBack={handleBack}
          handleNext={handleNext}
          organizationData={organizationData}
          projectsData={projectsData}
          setOrganizationData={setOrganizationData}
        />
        <AddProject
          ref={projectRef}
          activeStep={activeStep}
          handleBack={handleBack}
          handleNext={handleNext}
          setProjectsData={setProjectsData}
          organization={organizationData}
        />
        <InviteMembers
          ref={inviteMembersRef}
          activeStep={activeStep}
          handleBack={handleBack}
          handleNext={handleNext}
          organization={organizationData}
          projects={projectsData}
          setInvitationsData={setInvitationsData}
        />
        <PricingPlanContainer ref={pricingRef}>
          {/* <PreviousButton handleClick={handleBack} type="pricing" /> */}
          {/* <PricingPlan
              activeStep={activeStep}
              plans={props.subscriptionPlans}
              organization={organizationData}
              handleNext={handleNext}
              handleBack={handleBack}
            /> */}
          <SelectPlan
            eliteMonthly={eliteMonthly}
            standardMonthly={standardMonthly}
            handleSubmitData={handleSubmitData}
            onboardingIsLoading={props.isLoading}
          />
        </PricingPlanContainer>
      </OnboardingStepsContainer>
      <OnboardingArtworkDiv hide={hideArtwork}>
        <OnboardingArtwork hide={hideArtwork} src={artwork} alt="" />
      </OnboardingArtworkDiv>
    </div>
  );
};

export default OnboardingSteps;
