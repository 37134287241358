import { useState } from "react";
import {
  SelectPlanContainer,
  SelectPlanHeader,
  PlanCardsContainer,
  HeaderSpan,
  NoCardRequiredDiv,
  NoCardRequiredText,
} from "./selectPlanStyles";
import {
  CommonText,
  CommonImage,
} from "../../../../../styledComponents/common";

import SinglePlanCard from "./SinglePlanCard";

import violetTick from "../../../../../assets/img/icons/violet-tick.svg";

export default function SelectPlan({
  eliteMonthly,
  standardMonthly,
  handleSubmitData,
  onboardingIsLoading,
}) {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handlePlanSelect = (plan) => {
    if(onboardingIsLoading){
      return;
    }
    
    setSelectedPlan(plan);

    handleSubmitData(plan?.id);
  };

  return (
    <SelectPlanContainer>
      <SelectPlanHeader>
        <CommonText
          fontSize="24px"
          smallLaptopFontSize="20px"
          tabFontSize="18px"
          mobileFontSize="16px"
          fontWeight="600"
          name
        >
          Do you want to monitor employee activity{" "}
          <HeaderSpan>(e.g. screenshots, activity, apps & URLs)?</HeaderSpan>
        </CommonText>
        <NoCardRequiredDiv>
          <CommonImage src={violetTick} alt="" size="20px" />
          <NoCardRequiredText>No credit card required</NoCardRequiredText>
        </NoCardRequiredDiv>
      </SelectPlanHeader>
      <PlanCardsContainer>
        <SinglePlanCard
          title="Time Tracking"
          type="green"
          name="standard"
          plan={standardMonthly}
          handlePlanSelect={handlePlanSelect}
          isLoading={
            selectedPlan?.id === standardMonthly?.id && onboardingIsLoading
          }
        />
        <SinglePlanCard
          title="Employee Monitoring"
          type="blue"
          name="elite"
          plan={eliteMonthly}
          handlePlanSelect={handlePlanSelect}
          isLoading={
            selectedPlan?.id === eliteMonthly?.id && onboardingIsLoading
          }
        />
      </PlanCardsContainer>
    </SelectPlanContainer>
  );
}
