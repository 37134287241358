import { useState, useEffect } from "react";
import AppsUrlSection from "./AppsUrlUsage/AppsUrlSection";
import { formatDurationToHoursMinutes } from "../../utils/helper";
import AppLists from "../ActivityNew/Apps/AppLists";
import UrlLists from "../ActivityNew/URLs/UrlLists";
import moment from "moment";
// import {
//   HeaderPDF,
//   FooterPDF,
//   CommonText,
// } from "../../styledComponents/common";
import jsPDF from "jspdf";
import "jspdf-autotable";

const ExportPDF = (
  title,
  appsUrlState,
  averageActivity,
  manualTimeData,
  selectedProject,
  selectedTab,
  weeklyDates,
  plan
) => {
  const doc = new jsPDF("l", "mm", "a4");
  doc.page = 1;

  const getAppData = (appList) => {
    AppLists.map((item) => {
      appList.map((app) => {
        if (
          app.app === item.winId ||
          app.app === item.macId ||
          app.app === item.linuxId
        ) {
          app.name = item.name;
          app.icon = item.icon;
        }
      });
    });
    return appList;
  };

  const getUrlData = (urlList) => {
    UrlLists.map((item) => {
      urlList.map((url) => {
        if (trimUrl(url.url) === item.name) {
          url.name = item.name;
          url.icon = item.icon;
        }
      });
    });
    return urlList;
  };

  const trimUrl = (url) => {
    return url.replace("http://", "").replace("https://", "").split(/[/?#]/)[0];
  };

  if (title === "appUrl") {
    if (appsUrlState && Object.keys(appsUrlState).length > 0) {
      doc.setFontSize(10);
      doc.text(
        `${moment(
          Object.keys(appsUrlState)[Object.keys(appsUrlState).length - 1]
        ).format("D MMM, YYYY")} - ${moment(
          Object.keys(appsUrlState)[0]
        ).format("D MMM, YYYY")}`,
        3,
        5
      );

      doc.setFontSize(22);
      let title = "Apps & URLs Report";

      let fontSize = doc.internal.getFontSize();
      let pageWidth = doc.internal.pageSize.width;
      let txtWidth =
        (doc.getStringUnitWidth(title) * fontSize) / doc.internal.scaleFactor;
      let x = (pageWidth - txtWidth) / 2;
      doc.text(title, x, 15);

      Object.keys(appsUrlState).forEach((date, index) => {
        const report = appsUrlState[date];
        if (report.hasData) {
          const tableColumn = [
            `Member`,
            `App or Website`,
            `Time Spent (hrs)`,
            `Percent Used`,
          ];
          let tableRows = [];

          let options = {
            theme: "grid",
            headStyles: { fillColor: "#e6e6e6", textColor: "black" },
            style: { cellWidth: "auto" },
            margin: { top: 25, bottom: 20, horizontal: 10 },
            didDrawPage: () => {
              doc.setFontSize(14);
              doc.text(moment(date).format("D MMM, YYYY"), 10, 20);
              // doc.text(date,  10, doc.autoTable.previous.finalY + 5);
              doc.setFontSize(10);
              doc.text(280, 195, `${doc.page}`); //print number bottom right
              doc.page++;

              doc.setFontSize(10);
              doc.text("Generated with ", 10, 195);
              doc.setTextColor(32, 190, 173);
              doc.textWithLink("Apploye", 36, 195, {
                url: "https://apploye.com/",
              });
            },
          };

          let optionsFirst = {
            ...options,
            margin: { top: 30, bottom: 20, horizontal: 10 },
          };

          for (let memberId in report) {
            if (
              report[memberId].app_data &&
              report[memberId].app_data.length > 0
            ) {
              getAppData(report[memberId].app_data).forEach((app, index) => {
                if (index === 0) {
                  const reportRow = [
                    `${report[memberId].member_name} (Apps)`,
                    app.name ? app.name : app.app,
                    moment.utc(app.time * 1000).format("HH:mm:ss"),
                    `${parseInt(
                      (app.time / report[memberId].duration) * 100
                    )}%`,
                  ];
                  tableRows.push(reportRow);
                } else {
                  const reportRow = [
                    "",
                    app.name ? app.name : app.app,
                    moment.utc(app.time * 1000).format("HH:mm:ss"),
                    `${parseInt(
                      (app.time / report[memberId].duration) * 100
                    )}%`,
                  ];
                  tableRows.push(reportRow);
                }
              });
            }
            if (
              report[memberId].url_data &&
              report[memberId].url_data.length > 0
            ) {
              getUrlData(report[memberId].url_data).forEach((url, index) => {
                if (index === 0) {
                  const reportRow = [
                    `${report[memberId].member_name} (Websites)`,
                    url.name ? url.name : trimUrl(url.url),
                    moment.utc(url.time * 1000).format("HH:mm:ss"),
                    `${parseInt(
                      (url.time / report[memberId].duration) * 100
                    )}%`,
                  ];
                  tableRows.push(reportRow);
                } else {
                  const reportRow = [
                    "",
                    url.name ? url.name : trimUrl(url.url),
                    moment.utc(url.time * 1000).format("HH:mm:ss"),
                    `${parseInt(
                      (url.time / report[memberId].duration) * 100
                    )}%`,
                  ];
                  tableRows.push(reportRow);
                }
              });
            }
          }
          if (index === 0) {
            doc.autoTable(tableColumn, tableRows, optionsFirst);
          } else {
            doc.autoTable(tableColumn, tableRows, options);
          }
          if (Object.keys(appsUrlState).length - 1 !== index) {
            doc.addPage();
          }
        }
      });
    }

    doc.save(`apps_urls_usage_report_${moment().format("DD-MM-YYYY")}.pdf`);
  } else if (title === "timeActivity") {
    if (averageActivity && averageActivity.length > 0) {
      if (selectedTab === "weekly") {
        doc.setFontSize(22);
        let title = "Time & Activity Report";

        let fontSize = doc.internal.getFontSize();
        let pageWidth = doc.internal.pageSize.width;
        let txtWidth =
          (doc.getStringUnitWidth(title) * fontSize) / doc.internal.scaleFactor;
        let x = (pageWidth - txtWidth) / 2;
        doc.text(title, x, 15);
        let topMargin = 25;
        if (selectedProject && selectedProject !== "All Projects") {
          doc.setFontSize(15);
          txtWidth =
            (doc.getStringUnitWidth(`Project Name : ${selectedProject}`) * 15) /
            doc.internal.scaleFactor;
          x = (pageWidth - txtWidth) / 2;
          doc.text(`Project Name : ${selectedProject}`, x, topMargin);
          topMargin += 10;
        }
        doc.setFontSize(10);
        txtWidth =
          (doc.getStringUnitWidth(
            `${moment(weeklyDates?.firstDay).format("D MMM, YYYY")} - ${moment(
              weeklyDates?.lastDay
            ).format("D MMM, YYYY")}`
          ) *
            10) /
          doc.internal.scaleFactor;
        x = (pageWidth - txtWidth) / 2;
        doc.text(
          `${moment(weeklyDates?.firstDay).format("D MMM, YYYY")} - ${moment(
            weeklyDates?.lastDay
          ).format("D MMM, YYYY")}`,
          x,
          topMargin
        );
        const tableColumn =
          plan === "monitored"
            ? [
                `Member`,
                `Time Worked`,
                `Average Activity`,
                `Active Time`,
                `Neutral Time`,
                `Idle Time`,
              ]
            : [`Member`, `Time Worked`];
        let tableRows = [];

        let options = {
          theme: "grid",
          headStyles: { fillColor: "#e6e6e6", textColor: "black" },
          style: { cellWidth: "auto" },
          margin: { top: 25, bottom: 20, horizontal: 10 },
          didDrawPage: () => {
            doc.setFontSize(10);
            doc.text(280, 195, `${doc.page}`); //print number bottom right
            doc.page++;

            doc.setFontSize(10);
            doc.text("Generated with ", 10, 195);
            doc.setTextColor(32, 190, 173);
            doc.textWithLink("Apploye", 36, 195, {
              url: "https://apploye.com/",
            });
          },
        };

        let optionsFirst = {
          ...options,
          margin: { top: 40, bottom: 20, horizontal: 10 },
        };

        averageActivity.forEach((info, i) => {
          const reportRow =
            plan === "monitored"
              ? [
                  info.member_name,
                  formatDurationToHoursMinutes(info.time_worked_in_seconds),
                  `${parseInt(info.average_weekly_activity)}%`,
                  formatDurationToHoursMinutes(info.active_time, true),
                  formatDurationToHoursMinutes(info.neutral_time),
                  info.idle_time
                    ? formatDurationToHoursMinutes(info.idle_time, true)
                    : "-",
                ]
              : [
                  info.member_name,
                  formatDurationToHoursMinutes(info.time_worked_in_seconds),
                ];
          tableRows.push(reportRow);
        });

        doc.autoTable(tableColumn, tableRows, optionsFirst);

        doc.save(
          `time_activity_report_weekly_${moment().format("DD-MM-YYYY")}.pdf`
        );
      } else {
        let firstDate = averageActivity[averageActivity.length - 1].date;
        let lastDate = averageActivity[0].date;

        doc.setFontSize(22);
        let title = "Time & Activity Report";

        let fontSize = doc.internal.getFontSize();
        let pageWidth = doc.internal.pageSize.width;
        let txtWidth =
          (doc.getStringUnitWidth(title) * fontSize) / doc.internal.scaleFactor;
        let x = (pageWidth - txtWidth) / 2;
        doc.text(title, x, 15);
        let topMargin = 25;
        if (selectedProject && selectedProject !== "All Projects") {
          doc.setFontSize(15);
          txtWidth =
            (doc.getStringUnitWidth(`Project Name : ${selectedProject}`) * 15) /
            doc.internal.scaleFactor;
          x = (pageWidth - txtWidth) / 2;
          doc.text(`Project Name : ${selectedProject}`, x, topMargin);
          topMargin += 10;
        }
        doc.setFontSize(10);
        txtWidth =
          (doc.getStringUnitWidth(
            `${moment(firstDate).format("D MMM, YYYY")} - ${moment(
              lastDate
            ).format("D MMM, YYYY")}`
          ) *
            10) /
          doc.internal.scaleFactor;
        x = (pageWidth - txtWidth) / 2;
        doc.text(
          `${moment(firstDate).format("D MMM, YYYY")} - ${moment(
            lastDate
          ).format("D MMM, YYYY")}`,
          x,
          topMargin
        );

        averageActivity.forEach((report, index) => {
          const tableColumn =
            plan === "monitored"
              ? [
                  `Member`,
                  `Time Worked`,
                  `Average Activity`,
                  `Active Time`,
                  `Neutral Time`,
                  `Idle Time`,
                ]
              : [`Member`, `Time Worked`];
          let tableRows = [];

          let options = {
            theme: "grid",
            headStyles: { fillColor: "#e6e6e6", textColor: "black" },
            style: { cellWidth: "auto" },
            margin: { top: 25, bottom: 20, horizontal: 10 },
            didDrawPage: () => {
              doc.setFontSize(14);
              doc.text(
                moment(report.date).format("D MMM, YYYY"),
                10,
                doc.page === 1 ? topMargin + 10 : 20
              );
              // doc.text(date,  10, doc.autoTable.previous.finalY + 5);
              doc.setFontSize(10);
              doc.text(280, 195, `${doc.page}`); //print number bottom right
              doc.page++;

              doc.setFontSize(10);
              doc.text("Generated with ", 10, 195);
              doc.setTextColor(32, 190, 173);
              doc.textWithLink("Apploye", 36, 195, {
                url: "https://apploye.com/",
              });
            },
          };
          let optionsFirst = {
            ...options,
            margin: {
              top: topMargin + 15,
              bottom: 20,
              horizontal: 10,
            },
          };
          report.data.forEach((info, i) => {
            const reportRow =
              plan === "monitored"
                ? [
                    info.member_name,
                    formatDurationToHoursMinutes(info.time_worked_in_seconds),
                    `${parseInt(info.member_average_activity)}%`,
                    formatDurationToHoursMinutes(
                      info.time_worked_in_seconds *
                        (info.member_average_activity / 100),
                      true
                    ),
                    formatDurationToHoursMinutes(
                      parseInt(
                        info.time_worked_in_seconds -
                          info.time_worked_in_seconds *
                            (info.member_average_activity / 100) -
                          (info.idle_time ?? 0)
                      )
                    ),
                    info.idle_time
                      ? formatDurationToHoursMinutes(info.idle_time, true)
                      : "-",
                  ]
                : [
                    info.member_name,
                    formatDurationToHoursMinutes(info.time_worked_in_seconds),
                  ];
            tableRows.push(reportRow);
          });

          if (index === 0) {
            doc.autoTable(tableColumn, tableRows, optionsFirst);
          } else {
            doc.autoTable(tableColumn, tableRows, options);
          }
          if (averageActivity.length - 1 !== index) {
            doc.addPage();
          }
        });
        doc.save(`time_activity_report_${moment().format("DD-MM-YYYY")}.pdf`);
      }
    }
  } else if (title === "manualTime") {
    if (manualTimeData && manualTimeData.length > 0) {
      doc.setFontSize(10);
      let firstDate = Object.keys(manualTimeData[manualTimeData.length - 1])[0];
      let lastDate = Object.keys(manualTimeData[0])[0];
      doc.text(
        `${moment(firstDate).format("D MMM, YYYY")} - ${moment(lastDate).format(
          "D MMM, YYYY"
        )}`,
        3,
        5
      );

      doc.setFontSize(22);
      let title = "Manual Time Report";

      let fontSize = doc.internal.getFontSize();
      let pageWidth = doc.internal.pageSize.width;
      let txtWidth =
        (doc.getStringUnitWidth(title) * fontSize) / doc.internal.scaleFactor;
      let x = (pageWidth - txtWidth) / 2;
      doc.text(title, x, 15);

      manualTimeData.forEach((report, index) => {
        const tableColumn = [
          `Member`,
          `Edited By`,
          `Project`,
          `Task`,
          `Change in TimeSheet`,
          `Reason`,
        ];
        let tableRows = [];

        let options = {
          theme: "grid",
          headStyles: { fillColor: "#e6e6e6", textColor: "black" },
          style: { cellWidth: "auto" },
          margin: { top: 25, bottom: 20, horizontal: 10 },
          columnStyles: {
            5: {
              columnWidth: 100,
            },
          },
          didDrawPage: () => {
            doc.setFontSize(14);
            doc.text(
              moment(Object.keys(report)[0]).format("D MMM, YYYY"),
              10,
              20
            );
            // doc.text(date,  10, doc.autoTable.previous.finalY + 5);
            doc.setFontSize(10);
            doc.text(280, 195, `${doc.page}`); //print number bottom right
            doc.page++;

            doc.setFontSize(10);
            doc.text("Generated with ", 10, 195);
            doc.setTextColor(32, 190, 173);
            doc.textWithLink("Apploye", 36, 195, {
              url: "https://apploye.com/",
            });
          },
        };

        let optionsFirst = {
          ...options,
          margin: { top: 30, bottom: 20, horizontal: 10 },
        };

        report[Object.keys(report)[0]].forEach((info, i) => {
          const reportRow = [
            info.member_name,
            info.member_name === info.edited_by_name
              ? `member on ${moment(info.edited_time).format("D-M-YYYY")}`
              : `${info.edited_by_name} on ${moment(info.edited_time).format(
                  "D-M-YYYY"
                )}`,
            info.project_name,
            info.task_name === null ? `-` : `${info.task_name}`,
            info.type_of_change === "add"
              ? `added ${formatDurationToHoursMinutes(info.old_time, true)}`
              : info.type_of_change === ("delete" || "update")
              ? `${info.type_of_change}d ${formatDurationToHoursMinutes(
                  info.old_time,
                  true
                )}`
              : `${info.type_of_change}ted ${formatDurationToHoursMinutes(
                  info.deleted_time,
                  true
                )}`,
            info.reason,
          ];
          tableRows.push(reportRow);
        });

        if (index === 0) {
          doc.autoTable(tableColumn, tableRows, optionsFirst);
        } else {
          doc.autoTable(tableColumn, tableRows, options);
        }
        if (manualTimeData.length - 1 !== index) {
          doc.addPage();
        }
      });
      doc.save(`manual_time_report_${moment().format("DD-MM-YYYY")}.pdf`);
    }
  }
};

export default ExportPDF;
