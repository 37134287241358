import { useState, useEffect, Fragment } from "react";
import { CommImageNameComp, CommDropdownComp } from "./CommonComponents";
import {
  AdditionalContainer,
  CollapseContainer,
  TitleContainer,
  AdditionalOptionSection,
  AdditionalOptionText,
  IconTitleSection,
  ClientSection,
  ReportShow,
  ReportShowBox,
  AssigneeSection,
  AssigneesManageTitleContainer,
  AssigneesManageInfoContainer,
  AssigneesManageImgTextGrid,
  RadioButton,
  RadioButtonActive,
  Cross,
  CrossIcon,
  CollapseRotate,
} from "../../../styledComponents/createProject";
import {
  CommonText,
  TableText,
  CardTitle,
  CommonFlex,
} from "../../../styledComponents/common";
import FirstRoundLetterComp from "../../../components/FirstRoundLetter/FirstRoundLetter";

import DropdownLoader from "../../../components/DropdownLoader/DropdownLoader";

import AddPeople from "../../../assets/img/icons/people_permission.svg";
import Collapse from "../../../assets/img/icons/collapse.svg";
import crossIcon from "../../../assets/img/icons/cross_black.svg";
import { getFullName } from "../../../utils/helper";

const AdditionalOption = (props) => (
  <AdditionalOptionSection active={props.active}>
    <AdditionalOptionText> {props.text} </AdditionalOptionText>
  </AdditionalOptionSection>
);

const AddPeoplePermissions = (props) => {
  const [active, setActive] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [visibility, setVisibility] = useState(0);
  const [assigneeManage, setAssigneeManage] = useState([]);

  useState(() => {
    props.clearProjectAssigneeList();
  }, []);

  useEffect(() => {
    if (props.projectAssigneeList && props.projectAssigneeList.length > 0) {
      let assigneeList = [];
      props.projectAssigneeList.map((assignee) => {
        assigneeList.push({
          id: assignee.user.id,
          name: getFullName(assignee.user),
          manageProject: assignee.can_manage,
          viewReport: assignee.can_view_report,
          avatar: assignee.user.avatar || null,
          color: assignee.user.color || null,
        });
      });
      setAssigneeManage(assigneeList);
    } else if (
      props.projectAssigneeList &&
      props.projectAssigneeList.length === 0
    ) {
      setAssigneeManage([]);
    }
  }, [props.projectAssigneeList]);

  //FUNCTION
  const toggleCollapse = () => {
    setActive(!active);
  };
  const toggleShowReport = () => {
    setShowReport(!showReport);
  };
  const showAssignee = (state) => {
    setVisibility(state);
  };
  const assigneesManageFunc = (assigneeId, index) => {
    let newArr = [...assigneeManage];
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.projectId &&
      assigneeId
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        project_id: props.projectId,
        user_id: assigneeId,
        can_manage: !newArr[index].manageProject,
      };
      props.updateProjectAssignee(payload);
    }
  };
  const assigneesViewReportFunc = (assigneeId, index) => {
    let newArr = [...assigneeManage];
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.projectId &&
      assigneeId
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        project_id: props.projectId,
        user_id: assigneeId,
        can_view_report: !newArr[index].viewReport,
      };
      props.updateProjectAssignee(payload);
    }
  };
  const handleDeleteAssignee = (assigneeId) => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.projectId &&
      assigneeId
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        project_id: props.projectId,
        user_id: assigneeId,
      };
      props.deleteProjectAssignee(payload);
    }
  };

  const updateAssingeeList = (option, type) => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.projectId
    ) {
      if (type === "team") {
        let payload = {
          organization_id: props.selectedOrganization.id,
          project_id: props.projectId,
          team_id: option.id,
        };
        props.addTeamToProject(payload);
      } else if (type === "person") {
        let payload = {
          organization_id: props.selectedOrganization.id,
          project_id: props.projectId,
          user_id: option.id,
        };
        props.addProjectAssignee(payload);
      }
    }
  };

  return (
    <AdditionalContainer
      active={props.addPeopleActive}
      style={{ padding: `0 0 10px 0` }}
    >
      <AdditionalOption
        active={props.addPeopleActive}
        text="Additional (Optional)"
      />
      <TitleContainer
        padding="5px 30px 10px"
        onClick={() => props.toggleAddPeople()}
      >
        <IconTitleSection>
          <div>
            <img src={AddPeople} alt="addPeople" width="27px" />
          </div>
          <CardTitle fontSize="16px">Add People & Permissions</CardTitle>
        </IconTitleSection>
        <CollapseRotate active={props.addPeopleActive}>
          <img src={Collapse} alt="collapse" width="100%" />
        </CollapseRotate>
      </TitleContainer>
      <CollapseContainer
        active={props.addPeopleActive}
        addAssignee={visibility}
      >
        {/* <ClientSection>
          <CommonText>Client</CommonText>
          <CommonText name>James Stunt</CommonText>
          <ReportShow onClick={() => toggleShowReport()}>
            <ReportShowBox>
              {showReport && (
                <img src={Tick} alt="tick" width="100%" height="100%" />
              )}
            </ReportShowBox>
            <CommonText>Show report to client</CommonText>
          </ReportShow>
        </ClientSection> */}
        <AssigneeSection>
          <CommonText $label>Assignee</CommonText>

          <CommDropdownComp
            selectedOrganization={props.selectedOrganization}
            assigneeManage={assigneeManage}
            updateState={updateAssingeeList}
            visibilityChange={showAssignee}
            getMembersList={props.getMembersList}
            membersList={props.membersList}
            getTeamList={props.getTeamList}
            teamList={props.teamList}
            assigneeRemove={handleDeleteAssignee}
            assigneeIsLoading={props.projectAssigneeIsLoading}
            loadingAssigneeId={props.loadingAssigneeId}
            teamIsLoading={props.projectTeamIsLoading}
            loadingTeamId={props.loadingTeamId}
          />
        </AssigneeSection>
        {assigneeManage && assigneeManage.length !== 0 && (
          <AssigneesManageTitleContainer>
            <CommonText $label>Assignees</CommonText>
            <CommonText $label>Can Manage Project?</CommonText>
            <CommonText $label>Can View Report?</CommonText>
          </AssigneesManageTitleContainer>
        )}
        <AssigneesManageInfoContainer>
          {assigneeManage &&
            assigneeManage.length > 0 &&
            assigneeManage.map((assignee, index) => {
              return props.projectAssigneeIsLoading &&
                props.loadingAssigneeId &&
                props.loadingAssigneeId === assignee.id ? (
                <Fragment key={index}>
                  <DropdownLoader loading />
                  <div />
                  <div />
                  <div />
                </Fragment>
              ) : (
                <Fragment key={index}>
                  {assignee.avatar ? (
                    <CommImageNameComp
                      imageSize="36px"
                      personImg={
                        <img
                          src={assignee.avatar}
                          alt="addPeople"
                          style={{
                            width: "36px",
                            height: "36px",
                            borderRadius: "50%",
                            border: `1px solid #fff`,
                          }}
                        />
                      }
                      personName={assignee.name}
                    />
                  ) : (
                    <CommonFlex gap="15px">
                      <FirstRoundLetterComp
                        size="36px"
                        text={assignee.name}
                        backColor={index}
                        color={assignee.color || null}
                      />
                      <TableText name>{assignee.name}</TableText>
                    </CommonFlex>
                  )}

                  <AssigneesManageImgTextGrid>
                    <RadioButton
                      onClick={() => assigneesManageFunc(assignee.id, index)}
                    >
                      <RadioButtonActive active={assignee.manageProject} />
                    </RadioButton>
                    <TableText name>
                      {assignee.manageProject ? `Yes` : `No`}
                    </TableText>
                  </AssigneesManageImgTextGrid>

                  <AssigneesManageImgTextGrid>
                    <RadioButton
                      onClick={() =>
                        assigneesViewReportFunc(assignee.id, index)
                      }
                    >
                      <RadioButtonActive active={assignee.viewReport} />
                    </RadioButton>
                    <TableText name>
                      {assignee.viewReport ? `Yes` : `No`}
                    </TableText>
                  </AssigneesManageImgTextGrid>
                  <Cross
                    onClick={() => handleDeleteAssignee(assignee.id)}
                    size="26px"
                  >
                    <CrossIcon src={crossIcon} alt="" size="18px" />
                  </Cross>
                </Fragment>
              );
            })}
        </AssigneesManageInfoContainer>
      </CollapseContainer>
    </AdditionalContainer>
  );
};

export default AddPeoplePermissions;
