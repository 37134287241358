export const features = {
  standard: [
    "Timesheets",
    "Projects & Budgeting",
    "Clients & Invoice",
    "Attendance & Payroll",
    "Advanced Reporting",
    "+ More Features",
  ],
  elite: [
    "Screenshots (Periodic & Instant)",
    "Activity Tracking",
    "Idle Time Tracking",
    "Apps & URLs Usage",
    "+ More Features",
  ],
};
