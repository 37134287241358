import styled from "styled-components";
import {
  RoundLetterSection,
  FirstWordRound,
  CommonText,
  TableText,
  ManagerBadge,
} from "../../styledComponents/common";
import {
  AssigneesManageImgTextGrid,
  AssigneesManageRoundImage,
} from "../../styledComponents/createProject";
import FirstRoundLetterComp from "../../components/FirstRoundLetter/FirstRoundLetter";

import Badge from "../../assets/img/icons/manager_badge.svg";

const TeamName = styled(TableText)`
  &:hover {
    text-decoration: underline;
  }
`;

export const CommImageNameComp = (props) => {
  return (
    <AssigneesManageImgTextGrid>
      <div style={{ position: `relative` }}>
        {props.personImg ? (
          <AssigneesManageRoundImage>
            {" "}
            {props.personImg}{" "}
          </AssigneesManageRoundImage>
        ) : (
          <FirstRoundLetterComp
            text={props.personName}
            backColor={props.index}
            color={props.personColor}
            size="40px"
            fontSize="14px"
          />
        )}

        {props.isManager ? (
          <ManagerBadge src={Badge} alt="manager-badge" width="25px" />
        ) : null}
      </div>
      <CommonText>{props.personName}</CommonText>
    </AssigneesManageImgTextGrid>
  );
};

export const CommFirstRoundLetterComp = ({
  text,
  backColor,
  size,
  fontSize,
  gap,
  color,
}) => {
  const colorList = [
    { color: "#9A96F9" },
    { color: "#F9D059" },
    { color: "#F9828B" },
    { color: "#73C0FF" },
  ];
  let selectedIndex = 0;
  if (backColor) {
    if (backColor < 4) {
      selectedIndex = backColor;
    } else {
      selectedIndex = backColor % 4;
    }
  }
  return (
    <RoundLetterSection gap={gap}>
      <FirstWordRound
        backColor={color ? color : colorList[selectedIndex].color}
        size={size}
        fontSize={fontSize}
      >
        {text.slice(0, 1).toUpperCase()}{" "}
      </FirstWordRound>
      <TeamName name> {text}</TeamName>
    </RoundLetterSection>
  );
};
