import { useState, useEffect, useRef, useMemo, createRef } from "react";
import { Container, Input, Item } from "./styles";
const KEY_CODE = {
  BACKSPACE: 8,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  DELETE: 46,
};
const CodeVerificationInput = ({
  length = 4,
  onChange,
  placeholder = "",
  value: pValue,
}) => {
  const emptyValue = new Array(length).fill(placeholder);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [value, setValue] = useState(pValue ? pValue.split("") : emptyValue);
  const codeInputRef = createRef();
  const itemsRef = useMemo(
    () => new Array(length).fill(null).map(() => createRef()),
    [length]
  );
  const isCodeRegex = new RegExp(`^[0-9]{${length}}$`);
  const getItem = (index) => {
    var _a;
    return (_a = itemsRef[index]) === null || _a === void 0
      ? void 0
      : _a.current;
  };
  const focusItem = (index) => {
    var _a;
    return (_a = getItem(index)) === null || _a === void 0
      ? void 0
      : _a.focus();
  };
  const blurItem = (index) => {
    var _a;
    return (_a = getItem(index)) === null || _a === void 0 ? void 0 : _a.blur();
  };

  const onItemFocus = (index) => () => {
    const isAllFieldsEmpty = value.every((v) => v === placeholder || v === "");
  
    if (isAllFieldsEmpty && activeIndex === -1) {
      setActiveIndex(0);
      focusItem(0);
      if (codeInputRef.current) {
        codeInputRef.current.focus();
      }
    } else {
      setActiveIndex(index);
      if (codeInputRef.current) {
        codeInputRef.current.focus();
      }
    }
  };   

  const onInputKeyUp = ({ key, keyCode }) => {
    const newValue = [...value];
    const nextIndex = activeIndex + 1;
    const prevIndex = activeIndex - 1;
    const codeInput = codeInputRef.current;
    const currentItem = getItem(activeIndex);
    const isLast = nextIndex === length;
    const isDeleting =
      keyCode === KEY_CODE.DELETE || keyCode === KEY_CODE.BACKSPACE;
    // keep items focus in sync
    onItemFocus(activeIndex);
    // on delete, replace the current value
    // and focus on the previous item
    if (isDeleting) {
      newValue[activeIndex] = placeholder;
      setValue(newValue);
      if (activeIndex > 0) {
        setActiveIndex(prevIndex);
        focusItem(prevIndex);
      }
      return;
    }
    // if the key pressed is not a number
    // don't do anything
    if (Number.isNaN(+key)) return;
    // reset the current value
    // and set the new one
    if (codeInput) {
      codeInput.value = "";
    }
    newValue[activeIndex] = key;
    setValue(newValue);
    if (!isLast) {
      setActiveIndex(nextIndex);
      focusItem(nextIndex);
      return;
    }
    if (codeInput) {
      codeInput.blur();
    }
    if (currentItem) {
      currentItem.blur();
    }
    setActiveIndex(-1);
  };
  // handle mobile autocompletion
  const onInputChange = (e) => {
    const { value: changeValue } = e.target;
    const isCode = isCodeRegex.test(changeValue);
    if (!isCode) {
      return;
    }
    setValue(changeValue.split(""));
    blurItem(activeIndex);
  };
  const onInputBlur = () => {
    // https://github.com/ugogo/react-input-verification-code/issues/1
    if (activeIndex === -1) {
      return;
    }
    blurItem(activeIndex);
    setActiveIndex(-1);
  };
  // handle pasting
  useEffect(() => {
    const codeInput = codeInputRef.current;
    if (!codeInput) {
      return;
    }
    setActiveIndex(0);
    focusItem(0);
    codeInput.focus();
    const onPaste = (e) => {
      var _a;
      e.preventDefault();
      const pastedString =
        (_a = e.clipboardData) === null || _a === void 0
          ? void 0
          : _a.getData("text");
      if (!pastedString) {
        return;
      }
      const isNumber = !Number.isNaN(+pastedString);
      if (isNumber) {
        setValue(pastedString.split(""));
        if (pastedString.length < 4) {
          setActiveIndex(pastedString.length);
          focusItem(pastedString.length);
          codeInput.focus();
        } else {
          setActiveIndex(-1);
          blurItem(activeIndex);
        }
      }
    };
    codeInput.addEventListener("paste", onPaste);
    return () => codeInput.removeEventListener("paste", onPaste);
  }, []);

  useEffect(() => {
    onChange(value.join(""));
  }, [value]);

  useEffect(() => {
    if (typeof pValue !== "string") {
      return;
    }
    // avoid infinite loop
    if (pValue === "" && value.join("") === emptyValue.join("")) {
      return;
    }
    // keep internal and external states in sync
    if (pValue !== value.join("")) {
      setValue(pValue.split(""));
    }
  }, [pValue]);

  return (
    <Container itemsCount={length}>
      <Input
        ref={codeInputRef}
        autoComplete="one-time-code"
        type="text"
        inputMode="decimal"
        id="one-time-code"
        onChange={onInputChange}
        onKeyUp={onInputKeyUp}
        onBlur={onInputBlur}
        activeIndex={activeIndex}
        value={value[activeIndex] ? value[activeIndex] : ""}
      />
      {itemsRef.map((ref, i) => (
        <Item
          key={i}
          ref={ref}
          role="button"
          tabIndex={0}
          isActive={i === activeIndex ? true : false}
          onFocus={onItemFocus(i)}
        >
          {value[i] || placeholder}
        </Item>
      ))}
    </Container>
  );
};
export default CodeVerificationInput;
