import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { connectRouter } from "connected-react-router";
import storage from "redux-persist/lib/storage";

import authReducer from "./custom_modules/Login/loginReducer";
import signUpReducer from "./custom_modules/SignUp/signUpReducer";
import forgotPasswordReducer from "./custom_modules/ForgotPassword/forgotPasswordReducer";
import resetPasswordReducer from "./custom_modules/ResetPassword/resetPasswordReducer";
import verificationCodeReducer from "./custom_modules/VerificationCode/verificationCodeReducer";
import verifyReducer from "./custom_modules/VerifyUser/verifyReducer";

import dashboardReducer from "./custom_modules/Dashboard/dashboardReducer";

import profileReducer from "./custom_modules/UserProfile/userProfileReducer";

import liveFeedReducer from "./custom_modules/LiveFeed/liveFeedReducer";

import projectReducer from "./custom_modules/Projects/projectsReducer";

import organizationReducer from "./custom_modules/Organization/OrganizationReducer";

import taskReducer from "./custom_modules/Tasks/tasksReducer";

import clockInOutReducer from "./custom_modules/ClockInOut/clockInOutReducer";

import leaveReducer from "./custom_modules/Leave/leaveReducer";

import clientReducer from "./custom_modules/Clients/clientsReducer";

// import reportReducer from "./custom_modules/Reports/ReportsReducer";
import reportReducer from "./custom_modules/ReportsNew/reportsReducer";

//import activityReducer from "./custom_modules/Activity/activityReducer";
import activityReducer from "./custom_modules/ActivityNew/activityReducer";

import routeMapReducer from "./custom_modules/FieldServices/RouteMap/routeMapReducer";
import jobSitesReducer from "./custom_modules/FieldServices/JobSites/jobSitesReducer";
import geofenceClockInOutReducer from "./custom_modules/FieldServices/GeofenceClockInOut/geofenceClockInOutReducer";

import timesheetReducer from "./custom_modules/Timesheet/timesheetReducer";
import timesheetApprovalReducer from "./custom_modules/TimesheetApproval/timesheetApprovalReducer";
import workNotesReducer from "./custom_modules/WorkNotes/workNotesReducer";

import scheduleReducer from "./custom_modules/Schedule/scheduleReducer";

import teamReducer from "./custom_modules/Teams/teamsReducer";
import invoiceReducer from "./custom_modules/Invoice/invoiceReducer";

import memberReducer from "./custom_modules/Members/membersReducer";

import requestJoinOrgReducer from "./custom_modules/Onboarding/RequestJoinOrg/requestJoinOrgReducer";
import onboardingReducer from "./custom_modules/Onboarding/OnboardingSteps/onboardingStepsReducer";
import redeemCodeReducer from "./custom_modules/RedeemCode/redeemCodeReducer";
import redeemDiscountCodeReducer from "./custom_modules/RedeemBlackFriday/redeemDiscountCodeReducer";
import redeemLimitedTimeCodeReducer from "./custom_modules/RedeemAppsumo/redeemCodeReducer";

import integrationsReducer from "./custom_modules/IntegrationsNew/integrationsReducer";
import JiraIntegrationsReducer from "./custom_modules/IntegrationsNew/Jira/jiraIntegrationsReducer";

import publicApiReducer from "./custom_modules/PublicApi/publicApiReducer";

import payrollReducer from "./custom_modules/Payroll/payrollReducer";

import adminReducer from "./layouts/Admin/adminReducer";

import subscriptionReducer from "./custom_modules/Subscription/subscriptionReducer";

import activitySettingsReducer from "./custom_modules/Settings/ActivitySettings/activitySettingsReducer";
import timesheetSettingsReducer from "./custom_modules/Settings/TimesheetSettings/timesheetSettingsReducer";

import settingsReducer from "./custom_modules/Settings/settingsReducer";

import { reducer as formReducer } from "redux-form";
import filterReducer from "./filterReducer";

const authPersistConfig = {
  key: "auth",
  storage: storage,
  whitelist: ["auth"],
};

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: persistReducer(authPersistConfig, authReducer),
    form: formReducer,
    requestJoinOrg: requestJoinOrgReducer,
    onboarding: onboardingReducer,
    signUp: signUpReducer,
    dashboard: dashboardReducer,
    forgot: forgotPasswordReducer,
    resetPassword: resetPasswordReducer,
    verificationCode: verificationCodeReducer,
    verify: verifyReducer,
    profile: profileReducer,
    liveFeed: liveFeedReducer,
    project: projectReducer,
    organization: organizationReducer,
    task: taskReducer,
    clockInOut: clockInOutReducer,
    leave: leaveReducer,
    report: reportReducer,
    client: clientReducer,
    activity: activityReducer,
    routeMap: routeMapReducer,
    jobSites: jobSitesReducer,
    geofenceClockInOut: geofenceClockInOutReducer,
    timesheet: timesheetReducer,
    timesheetApproval: timesheetApprovalReducer,
    workNotes: workNotesReducer,
    schedule: scheduleReducer,
    team: teamReducer,
    invoice: invoiceReducer,
    member: memberReducer,
    redeemCode: redeemCodeReducer,
    redeemDiscountCode: redeemDiscountCodeReducer,
    redeemLimitedTimeCode: redeemLimitedTimeCodeReducer,
    payroll: payrollReducer,
    integrations: integrationsReducer,
    jiraIntegrations: JiraIntegrationsReducer,
    publicApi: publicApiReducer,
    subscription: subscriptionReducer,
    activitySettings: activitySettingsReducer,
    timesheetSettings: timesheetSettingsReducer,
    admin: adminReducer,
    settings: settingsReducer,
    filterReducer: filterReducer,
  });
