import { useState, useEffect } from "react";
import TasksTable from "../../Tasks/TasksListNew";

import TopPagination from "../../../components/Pagination/TopPagination/TopPagination";
import BottomPagination from "../../../components/Pagination/BottomPagination/BottomPagination";

const TaskSection = (props) => {
  const [newTask, setNewTask] = useState(null);

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const {
    selectedOrganization,
    projectIsLoading,
    projectAssigneeIsLoading,
    projectId,
    projectName,
    projectStatus,
    getProjectTaskList,
    projectTaskList,
    getProjectAssigneeList,
    projectAssigneeList,
    addTask,
    editTask,
    deleteTask,
    addTaskAssignee,
    deleteTaskAssignee,
    taskListLoading,
    taskIsLoading,
    loadingTaskId,
    taskAssigneeIsLoading,
    loadingTaskAssigneeId,

    // pagination
    taskCount,
    taskPageSize,
  } = props;

  useEffect(() => {
    setCurrentPageNumber(1);
  }, [selectedOrganization]);

  const handlePageChange = (page) => {
    if (selectedOrganization && selectedOrganization.id && projectId) {
      const payload = {
        organization_id: selectedOrganization.id,
        project_id: projectId,
        status: "to do",
        page,
      };
      getProjectTaskList(payload);
    }
    setCurrentPageNumber(page);
  };

  const handleNewTask = (value) => {
    setNewTask(value);
  };

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id && projectId) {
      const payload = {
        organization_id: selectedOrganization.id,
        project_id: projectId,
        status: "to do",
        page: 1,
      };
      getProjectTaskList(payload);
    }
  }, [selectedOrganization, projectId]);

  return (
    <>
      {taskCount && taskCount > 0 && taskPageSize ? (
        <TopPagination
          itemName="tasks"
          totalCount={taskCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={taskPageSize}
          padding="0 30px"
        />
      ) : null}
      <TasksTable
        fromProject
        projectData={{ id: projectId, name: projectName }}
        tasksList={projectTaskList}
        selectedOrganization={selectedOrganization}
        projectAddTask={addTask}
        editTask={editTask}
        projectDeleteTask={deleteTask}
        getTaskList={getProjectTaskList}
        getProjectAssigneeList={getProjectAssigneeList}
        projectAssigneeList={projectAssigneeList}
        addTaskAssignee={addTaskAssignee}
        deleteTaskAssignee={deleteTaskAssignee}
        projectAssigneeIsLoading={projectAssigneeIsLoading}
        taskIsLoading={taskIsLoading}
        loadingTaskId={loadingTaskId}
        // loadingTaskProjectId={loadingTaskProjectId}
        taskAssigneeIsLoading={taskAssigneeIsLoading}
        loadingTaskAssigneeId={loadingTaskAssigneeId}
        taskListLoading={taskListLoading}
        newTask={newTask}
        handleNewTask={handleNewTask}
      />
      {taskCount && taskCount > 0 && taskPageSize ? (
        <BottomPagination
          totalCount={taskCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={taskPageSize}
        />
      ) : null}
    </>
  );
};

export default TaskSection;
