import React, { useState, useEffect } from "react";

import {
  AllIntegrationsContainer,
  AllIntegrationsDiv,
  IntegrationCard,
  CardIcon,
  HeaderText,
  BoldText,
  PointerText,
  NormalText,
  ConnectButton,
  SemiText,
  ConnectText,
  IntegrationsTableView,
  IntegrationsTableHeader,
  IntegrationsTableRow,
  Integration,
  ActionDropDown,
} from "../../styledComponents/integrations";
import {
  PageTitle,
  HeaderContainer,
  InputWithLabelSection,
  FilterLabel,
  CommonText,
} from "../../styledComponents/common";
import { TabButtonContainer, TabButton } from "../../styledComponents/buttons";
import { TabLeftGrid } from "../../styledComponents/members";

import SearchWithButton from "../../components/Search/SearchWithButton";

import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";

import { AuthHandler } from "./integrationHandler/authHandler/authHandlerService";

import JiraCard from "./integrationsJira";

const handleAuthFlow = (stepsData, otherData) => {
  const authHandler = new AuthHandler(stepsData, otherData);

  authHandler.init();
  // authHandler.run();
};

const SingleIntegrationCard = (props) => {
  const {
    integration,
    history,
    selectedOrganization,
    connectIntegration,
    handleViewDetails,
  } = props;

  return (
    <IntegrationCard>
      <CardIcon src={integration.icon} alt="asanaIcon" />
      <CommonText fontWeight="600" margin="10px 0" fontSize="15px">
        {integration.display_name}
      </CommonText>
      <ConnectButton
        onClick={() => {
          if (integration.is_installed) {
            handleViewDetails(integration.id);
          } else {
            connectIntegration(integration);
          }
        }}
      >
        {integration.is_installed ? "View Details" : "Connect"}
      </ConnectButton>
    </IntegrationCard>
  );
};

const AllIntegrations = (props) => {
  const { history, integrations, enabledIntegrations, selectedOrganization, isInstalledJira } =
    props;

  const connectIntegration = (integration) => {
    // const { route } = integration;
    // if (route) {
    //   if (route.isLink) {
    //     window.open(route.pathname, "_blank");
    //   }
    // } else {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      integration.id &&
      integration.frontend_steps &&
      integration.frontend_steps.length > 0
    ) {
      handleAuthFlow(integration.frontend_steps, {
        organization_id: selectedOrganization.id,
        integration_id: integration.id,
      });
    }
    // }
  };

  const handleViewDetails = (integrationId) => {
    if (enabledIntegrations && enabledIntegrations.length > 0) {
      enabledIntegrations.some((item) => {
        if (item.integration_id === integrationId) {
          history.push({
            pathname: `/user/integrations/${item.id}/details`,
            state: {
              enabledIntegration: {
                ...item,
              },
            },
          });
          return true;
        }
        return false;
      });
    }
  };

  return (
    <AllIntegrationsContainer>
      <AllIntegrationsDiv>
        {integrations &&
          integrations.map((integration, index) => (
            <React.Fragment key={index}>
              <SingleIntegrationCard
                integration={integration}
                history={history}
                selectedOrganization={selectedOrganization}
                handleViewDetails={handleViewDetails}
                connectIntegration={connectIntegration}
              />
            </React.Fragment>
          ))}

        <JiraCard
          history={history}
          selectedOrganization={selectedOrganization}
          isInstalledJira={isInstalledJira}
        />
      </AllIntegrationsDiv>
      {/* <CommonColorButton padding="13px 25px">
          All Integrations
        </CommonColorButton> */}
    </AllIntegrationsContainer>
  );
};
const ActivatedIntegrations = (props) => {
  return null;
};

const IntegrationsNew = (props) => {
  const [selectedTab, setSelectedTab] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");

  const {
    history,
    getAllIntegrationsList,
    allIntegrationsList,
    getEnabledIntegrationsList,
    enabledIntegrationsList,
    isLoading,
    selectedOrganization,
    isInstalledJira,
    getIsInstalledJira,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      getAllIntegrationsList(payload);
      getEnabledIntegrationsList(payload);
      getIsInstalledJira(payload);
    }
  }, [selectedOrganization]);

  const onTabSelect = (value) => {
    setSearchTerm("");
    setSelectedTab(value);
  };

  return (
    <div className="content">
      <PageTitle>Integrations</PageTitle>
      {/* <HeaderContainer>
        <TabLeftGrid columns="auto 280px">
          <InputWithLabelSection>
            <FilterLabel>Type</FilterLabel>
            <TabButtonContainer>
              <TabButton
                index="all"
                selected={selectedTab}
                onClick={() => onTabSelect("all")}
              >
                All Integrations
              </TabButton>
              <TabButton
                index="activated"
                selected={selectedTab}
                onClick={() => onTabSelect("activated")}
              >
                Activated Integrations
              </TabButton>
            </TabButtonContainer>
          </InputWithLabelSection>
          <SearchWithButton
            itemName="Integration"
            searchTermName="integration name"
          />
        </TabLeftGrid>
      </HeaderContainer> */}
      {/* {selectedTab && selectedTab === "all" ? (
        <AllIntegrations history={history} />
      ) : (
        <ActivatedIntegrations />
      )} */}
      {isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <AllIntegrations
          history={history}
          integrations={allIntegrationsList}
          enabledIntegrations={enabledIntegrationsList}
          selectedOrganization={selectedOrganization}
          isInstalledJira={isInstalledJira}
        />
      )}
    </div>
  );
};

export default IntegrationsNew;
