import React from "react";

import {
  IntegrationCard,
  CardIcon,
  ConnectButton,
} from "../../styledComponents/integrations";
import {
  CommonText,
} from "../../styledComponents/common";

import jiraIcon from "../../assets/img/integrations/jira.svg";

const SingleIntegrationCard = (props) => {
  const {
    integration,
    history,
    selectedOrganization,
    connectIntegration,
    handleViewDetails,
  } = props;

  return (
    <IntegrationCard>
      <CardIcon src={integration.icon} alt="jiraIcon" />
      <CommonText fontWeight="600" margin="10px 0" fontSize="15px">
        {integration.display_name}
      </CommonText>
      <ConnectButton
        onClick={() => {
          if (integration.is_installed) {
            handleViewDetails(integration.id);
          } else {
            connectIntegration(integration);
          }
        }}
      >
        {integration.is_installed ? "View Details" : "Connect"}
      </ConnectButton>
    </IntegrationCard>
  );
};

const JiraCard = (props) => {
  const { history, selectedOrganization, isInstalledJira } = props;

  const jiraIntegration = {
    id: "jira",
    name: "jira",
    tag: "jira",
    display_name: "Jira",
    description: "Apploye to Jira Integration",
    is_installed: isInstalledJira,
    icon: jiraIcon,
    frontend_steps: []
  };

  const connectJiraIntegration = () => {
    if (selectedOrganization && selectedOrganization.id) {
      history.push({
        pathname: `/user/integrations/jira-request-access`,
      })
    };
  };

  const handleJiraViewDetails = (integrationId) => {
    if (selectedOrganization && selectedOrganization.id) {
      history.push({
        pathname: `/user/integrations/jira-details`,
      })
    };
  };

  return (
    <SingleIntegrationCard
      integration={jiraIntegration}
      history={history}
      selectedOrganization={selectedOrganization}
      handleViewDetails={handleJiraViewDetails}
      connectIntegration={connectJiraIntegration}
    />
  );
};

export default JiraCard;
