import {
  GET_ALL_INTEGRATIONS_LIST,
  GET_ALL_INTEGRATIONS_LIST_SUCCESS,
  GET_ALL_INTEGRATIONS_LIST_FAILURE,
  GET_INTEGRATION_WORKFLOWS_LIST,
  GET_INTEGRATION_WORKFLOWS_LIST_SUCCESS,
  GET_INTEGRATION_WORKFLOWS_LIST_FAILURE,
  GET_ENABLED_INTEGRATIONS_LIST,
  GET_ENABLED_INTEGRATIONS_LIST_SUCCESS,
  GET_ENABLED_INTEGRATIONS_LIST_FAILURE,
  GET_INTEGRATION_ENABLED_WORKFLOWS_LIST,
  GET_INTEGRATION_ENABLED_WORKFLOWS_LIST_SUCCESS,
  GET_INTEGRATION_ENABLED_WORKFLOWS_LIST_FAILURE,
  ENABLE_INTEGRATION_WORKFLOW,
  ENABLE_INTEGRATION_WORKFLOW_SUCCESS,
  ENABLE_INTEGRATION_WORKFLOW_FAILURE,
  RUN_INTEGRATION_WORKFLOW,
  RUN_INTEGRATION_WORKFLOW_SUCCESS,
  RUN_INTEGRATION_WORKFLOW_FAILURE,
} from "../../modules/constants";

import { createNotification } from "../../modules/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------

export const handleAllIntegrationsListRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const handleAllIntegrationsListRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;

  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      allIntegrationsList: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    allIntegrationsList: null,
  };
};
export const handleAllIntegrationsListRequestFailure = (state, action) => {
  createNotification(
    "error",
    "Could not fetch integration list. Please try again!",
    2500
  );
  return {
    ...state,
    isLoading: false,
    allIntegrationsList: null,
  };
};

export const handleIntegrationWorkflowsListRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const handleIntegrationWorkflowsListRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;

  if (data.status === 200) {
    const result = JSON.parse(JSON.stringify(data.data));
    if (result && result.length > 0) {
      result.forEach((item) => {
        item.is_enabled = false;
      });
    }
    return {
      ...state,
      isLoading: false,
      integrationWorkflowsList: [...result],
    };
  }
  return {
    ...state,
    isLoading: false,
    integrationWorkflowsList: null,
  };
};
export const handleIntegrationWorkflowsListRequestFailure = (state, action) => {
  createNotification(
    "error",
    "Could not fetch integration workflows list. Please try again!",
    2500
  );
  return {
    ...state,
    isLoading: false,
    integrationWorkflowsList: null,
  };
};

export const handleEnabledIntegrationsListRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const handleEnabledIntegrationsListRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;

  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      enabledIntegrationsList: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    enabledIntegrationsList: null,
  };
};
export const handleEnabledIntegrationsListRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    enabledIntegrationsList: null,
  };
};

export const handleEnabledWorkflowsListRequest = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};
export const handleEnabledWorkflowsListRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;

  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      enabledWorkflowsList: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    enabledWorkflowsList: null,
  };
};
export const handleEnabledWorkflowsListRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    enabledWorkflowsList: null,
  };
};

export const handleEnableWorkflowRequest = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};
export const handleEnableWorkflowRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    createNotification("success", "Workflow enabled successfully", 2000);
    if (data.data && data.data.id) {
      const workflowsList = JSON.parse(
        JSON.stringify(state.integrationWorkflowsList)
      );
      workflowsList.some((workflow) => {
        if (workflow.name === data.data.name) {
          workflow.is_enabled = true;
          return true;
        }
        return false;
      });

      return {
        ...state,
        isLoading: false,
        integrationWorkflowsList: [...workflowsList],
        enabledWorkflowData: data.data,
      };
    }
    return {
      ...state,
      isLoading: false,
      enabledWorkflowData: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    enabledWorkflowData: null,
  };
};
export const handleEnableWorkflowRequestFailure = (state, action) => {
  createNotification(
    "error",
    "Could not enable the workflow. Please try again!",
    2000
  );
  return {
    ...state,
    isLoading: false,
    enabledWorkflowData: null,
  };
};

export const handleRunWorkflowRequest = (state, action) => {
  return {
    ...state,
    syncIsLoading: true,
  };
};
export const handleRunWorkflowRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;

  if (data.status === 200) {
    createNotification(
      "success",
      "All data will be synced shortly. Please come back after a while",
      4000
    );
    return {
      ...state,
      syncIsLoading: false,
    };
  }
  return {
    ...state,
    syncIsLoading: false,
  };
};
export const handleRunWorkflowRequestFailure = (state, action) => {
  createNotification(
    "error",
    "Could not sync data. Please try again later!",
    2500
  );
  return {
    ...state,
    syncIsLoading: false,
  };
};


// Action Handlers
const ACTION_HANDLERS = {
  [GET_ALL_INTEGRATIONS_LIST]: handleAllIntegrationsListRequest,
  [GET_ALL_INTEGRATIONS_LIST_SUCCESS]: handleAllIntegrationsListRequestSuccess,
  [GET_ALL_INTEGRATIONS_LIST_FAILURE]: handleAllIntegrationsListRequestFailure,

  [GET_INTEGRATION_WORKFLOWS_LIST]: handleIntegrationWorkflowsListRequest,
  [GET_INTEGRATION_WORKFLOWS_LIST_SUCCESS]:
    handleIntegrationWorkflowsListRequestSuccess,
  [GET_INTEGRATION_WORKFLOWS_LIST_FAILURE]:
    handleIntegrationWorkflowsListRequestFailure,

  [GET_ENABLED_INTEGRATIONS_LIST]: handleEnabledIntegrationsListRequest,
  [GET_ENABLED_INTEGRATIONS_LIST_SUCCESS]:
    handleEnabledIntegrationsListRequestSuccess,
  [GET_ENABLED_INTEGRATIONS_LIST_FAILURE]:
    handleEnabledIntegrationsListRequestFailure,

  [GET_INTEGRATION_ENABLED_WORKFLOWS_LIST]: handleEnabledWorkflowsListRequest,
  [GET_INTEGRATION_ENABLED_WORKFLOWS_LIST_SUCCESS]:
    handleEnabledWorkflowsListRequestSuccess,
  [GET_INTEGRATION_ENABLED_WORKFLOWS_LIST_FAILURE]:
    handleEnabledWorkflowsListRequestFailure,

  [ENABLE_INTEGRATION_WORKFLOW]: handleEnableWorkflowRequest,
  [ENABLE_INTEGRATION_WORKFLOW_SUCCESS]: handleEnableWorkflowRequestSuccess,
  [ENABLE_INTEGRATION_WORKFLOW_FAILURE]: handleEnableWorkflowRequestFailure,

  [RUN_INTEGRATION_WORKFLOW]: handleRunWorkflowRequest,
  [RUN_INTEGRATION_WORKFLOW_SUCCESS]: handleRunWorkflowRequestSuccess,
  [RUN_INTEGRATION_WORKFLOW_FAILURE]: handleRunWorkflowRequestFailure,
};

// default initial state
const initialState = {
  isLoading: false,
  syncIsLoading: false,

  allIntegrationsList: null,
  integrationWorkflowsList: null,

  enabledIntegrationsList: null,
  enabledWorkflowsList: null,

  enabledWorkflowData: null,
};

export default function IntegrationsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
