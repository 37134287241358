import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { setPathName } from "../../filterReducer";

import {
  PageTitle,
  HeaderContainer,
  InputWithLabelSection,
  FilterLabel,
  InputField,
} from "../../styledComponents/common";
import {
  TabButtonContainer,
  TabButton,
  ColoredButtonWithIcon,
  ButtonIcon,
} from "../../styledComponents/buttons";
import { TabLeftGrid } from "../../styledComponents/clients";

// import ClientsCards from "./ClientsCards";
import ClientsTable from "./ClientsTable";

import Plus from "../../assets/img/icons/plus_white.svg";

const Clients = (props) => {
  const dispatch = useDispatch();
  const [clientList, setClientList] = useState([]);
  const [clientType, setClientType] = useState(
    props.filters?.pathname === "/user/clients" && props.filters?.clientType
      ? props.filters?.clientType
      : "active"
  );
  const [searchTerm, setSearchTerm] = useState(
    props.filters?.pathname === "/user/clients" && props.filters?.searchTerm
      ? props.filters?.searchTerm
      : ""
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      let payload = {
        organization_id: props.selectedOrganization.id,
      };
      if (!(props.location.state && props.location.state.from)) {
        props.getClientList(payload);
      }
    }
  }, [props.selectedOrganization]);

  useEffect(() => {
    if (props.clientList && props.clientList.length > 0) {
      setClientList(props.clientList);
    } else if (props.clientList && props.clientList.length === 0) {
      setClientList([]);
    }
  }, [props.clientList]);

  useEffect(() => {
    if (
      searchTerm &&
      searchTerm !== "" &&
      props.clientList &&
      props.clientList.length > 0
    ) {
      let originalList = props.clientList;
      let filteredList = [];
      originalList.forEach((e) => {
        if (
          searchTerm &&
          e.name.toLowerCase().indexOf(searchTerm.toLowerCase().trim()) === -1
        ) {
          return;
        }
        if (props.selectedOrganization) {
          filteredList.push(e);
        }
      });
      setClientList(filteredList);
    } else if (searchTerm === "") {
      setClientList(props.clientList);
    }
  }, [searchTerm, props.clientList]);

  const onTabSwitch = (value) => {
    setClientType(value);
  };
  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const toggle = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleClientArchive = (clientId, action) => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      clientId
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        client_id: clientId,
        is_active: action === "archive" ? false : true,
        getList: true,
        action: "archive",
      };
      props.updateClient(payload);
    }
  };

  const handleClientDelete = (clientId) => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        client_id: clientId,
      };
      props.deleteClient(payload);
      setIsDeleteModalOpen(!isDeleteModalOpen);
    }
  };
  useEffect(() => {
    dispatch(
      setPathName({
        pathname: location.pathname,
        searchTerm,
        clientType,
      })
    );
  }, [searchTerm, clientType]);
  return (
    <div className="content">
      <PageTitle>Clients</PageTitle>
      <HeaderContainer>
        <TabLeftGrid>
          <InputWithLabelSection>
            <FilterLabel>Type</FilterLabel>
            <TabButtonContainer>
              <TabButton
                onClick={() => onTabSwitch("active")}
                selected={clientType}
                index="active"
              >
                Active
              </TabButton>
              <TabButton
                onClick={() => onTabSwitch("archived")}
                selected={clientType}
                index="archived"
              >
                Archived
              </TabButton>
            </TabButtonContainer>
          </InputWithLabelSection>

          <InputWithLabelSection>
            <FilterLabel>Search Client</FilterLabel>
            <InputField
              type="text"
              placeholder="Search by client name..."
              value={searchTerm}
              onChange={(e) => onSearchTermChange(e)}
            />
          </InputWithLabelSection>
        </TabLeftGrid>
        <ColoredButtonWithIcon
          onClick={() => props.history.push("/user/add-client")}
        >
          <ButtonIcon src={Plus} alt="" />
          Add Client
        </ColoredButtonWithIcon>
      </HeaderContainer>
      <ClientsTable
        searchTerm={searchTerm}
        clientList={clientList}
        clientType={clientType}
        history={props.history}
        modalToggle={toggle}
        isModalOpen={isDeleteModalOpen}
        handleArchive={handleClientArchive}
        handleDelete={handleClientDelete}
        isLoading={props.isLoading}
        clientUpdateLoading={props.clientUpdateLoading}
        updatingClientId={props.updatingClientId}
        clientDeleteLoading={props.clientDeleteLoading}
        deletingClientId={props.deletingClientId}
      />
    </div>
  );
};

export default Clients;
