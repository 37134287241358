import { connect } from "react-redux";
import JiraDetails from "./JiraDetails";

// Actions
import {
  syncJiraIntegration,
  deleteJiraIntegration,
  updateJiraIntegration,
  getIsActiveJira
} from "../jiraIntegrationsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,

  isLoading: state.jiraIntegrations.isLoading,
  syncIsLoading: state.jiraIntegrations.syncIsLoading,
  isActiveJira: state.jiraIntegrations.isActiveJira,
  isInstalledJira: state.jiraIntegrations.isInstalledJira,
  jiraInfo: state.jiraIntegrations.jiraInfo,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  syncJiraIntegration: (payload) =>
    dispatch(syncJiraIntegration(payload)),
  deleteJiraIntegration: (payload) =>
    dispatch(deleteJiraIntegration(payload)),
  updateJiraIntegration: (payload) =>
    dispatch(updateJiraIntegration(payload)),
  getIsActiveJira: (payload) => 
    dispatch(getIsActiveJira(payload))
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(JiraDetails);
