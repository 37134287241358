import React from "react";
// reactstrap components
import { Row } from "reactstrap";
import moment from "moment";
import ScheduleHeader from "./ScheduleHeader";
import ScheduleTable from "./ScheduleTable";
import ScheduleModal from "./ScheduleModal";
import Loader from "../../modules/loader";
import { getFullName } from "../../utils/helper";

const currentDate = moment();
const initialState = {
  user_id: "",
  time_interval: { label: "Daily", value: "daily" },
  project_id: "",
  task_id: "",
  selectedMember: "",
  currentDate: currentDate,
  defaultDate: currentDate.format("ddd, DD MMMM, YYYY"),
  selectedDate: currentDate.format("YYYY-MM-DD"),
  selectedTimeInterval: "daily",
  mode: "add",
  modalIsOpen: false,
};

const scheduleTypes = [
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
];

class Schedules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
  }

  componentDidMount() {
    // this.getScheduleList();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.organization && !prevProps.organization) ||
      (prevProps.organization &&
        this.props.organization.id !== prevProps.organization.id)
    ) {
      this.setState({
        ...initialState,
      });
      const payload = {
        defaultData: {
          organization_id: this.props.organization.id,
          time_interval: this.state.time_interval.value,
        },
      };
      if (
        this.props.organization &&
        prevProps.organization &&
        this.props.organization.id !== prevProps.organization.id
      ) {
        payload.defaultData.time_interval = initialState.time_interval.value;
      }
      this.props.getScheduleRequest(payload);
      let data = {
        id: this.props.organization.id,
      };
      this.props.getOrganizationMembers(data);
    }
  }

  getScheduleList = (
    user_id = this.state.user_id,
    time_interval = this.state.time_interval,
    project_id = this.state.project_id,
    selectedDate = this.state.selectedDate
  ) => {
    const { getScheduleRequest, organization } = this.props;
    if (organization) {
      const organizationId = organization.id;
      const payload = {
        defaultData: {
          organization_id: organizationId,
          time_interval: time_interval.value,
        },
      };
      if (project_id && project_id.value !== "") {
        payload.defaultData["project_id"] = project_id.value;
      }
      if (user_id) {
        payload.defaultData["user_id"] = user_id.value;
      }
      if (selectedDate) {
        payload.defaultData["date"] = selectedDate;
      }
      this.setState({
        selectedTimeInterval: time_interval.value,
      });
      getScheduleRequest(payload);
    }
  };

  handleChange = (e, name) => {
    if (name === "user_id") {
      this.setState({
        selectedMember: e,
      });
    } else if (name === "project_id") {
      this.setState({
        selectedMember: "",
        user_id: "",
      });
    }
    this.setState({
      [name]: e,
    });
  };

  selectDate = (date) => {
    const { user_ids, time_interval, project_id } = this.state;
    const selectedDate = date.format("YYYY-MM-DD");
    this.setState({
      defaultDate: date.format("ddd, DD MMMM, YYYY"),
      selectedDate,
    });
    this.getScheduleList(user_ids, time_interval, project_id, selectedDate);
  };

  toggleScheduleModal = () => {
    const { modalIsOpen } = this.state;
    this.setState({
      modalIsOpen: !modalIsOpen,
      mode: "add",
    });
    this.props.resetSelectedScheduleRequest();
  };

  edit = (id) => {
    const { getSelectedScheduleRequest, selectedSchedule } = this.props;
    if (!selectedSchedule || selectedSchedule.id !== id) {
      getSelectedScheduleRequest({ id });
    }

    this.setState((prevState) => ({
      modalIsOpen: !prevState.modalIsOpen,
      mode: "update",
    }));
  };

  deactivateSchedule = (schedule_id) => {
    const { deactivateScheduleRequest, organization } = this.props;
    const { time_interval, project_id, user_id, selectedDate } = this.state;

    const payload = {
      schedule_id,
      defaultData: {
        organization_id: organization.id,
        time_interval: time_interval.value,
      },
    };
    if (project_id && project_id.value !== "") {
      payload.defaultData["project_id"] = project_id.value;
    }
    if (user_id) {
      payload.defaultData["user_id"] = user_id.value;
    }
    if (selectedDate) {
      payload.defaultData["date"] = selectedDate;
    }
    deactivateScheduleRequest(payload);
    this.setState((prevState) => ({
      modalIsOpen: !prevState.modalIsOpen,
      mode: "add",
    }));
  };

  handleSave = (data) => {
    const { organization } = this.props;
    const { time_interval, project_id, user_id, selectedDate } = this.state;

    const payload = {
      ...data,
      organization: organization.id,
      defaultData: {
        organization_id: organization.id,
        time_interval: time_interval.value,
      },
    };
    if (project_id && project_id.value !== "") {
      payload.defaultData["project_id"] = project_id.value;
    }
    if (user_id) {
      payload.defaultData["user_id"] = user_id.value;
    }
    if (selectedDate) {
      payload.defaultData["date"] = selectedDate.value;
    }

    this.props.addScheduleRequest(payload);
    this.setState((prevState) => ({
      modalIsOpen: !prevState.modalIsOpen,
    }));
  };

  handleUpdate = (data) => {
    const { organization, selectedSchedule } = this.props;
    const { time_interval, project_id, user_id, selectedDate } = this.state;
    const payload = {
      ...data,
      organization: organization.id,
      id: selectedSchedule.id,
      defaultData: {
        organization_id: organization.id,
        time_interval: time_interval.value,
      },
    };
    if (project_id && project_id.value !== "") {
      payload.defaultData["project_id"] = project_id.value;
    }
    if (user_id) {
      payload.defaultData["user_id"] = user_id.value;
    }
    if (selectedDate) {
      payload.defaultData["date"] = selectedDate;
    }

    this.props.updateScheduleRequest(payload);
    this.setState((prevState) => ({
      modalIsOpen: !prevState.modalIsOpen,
    }));
  };

  render() {
    const {
      scheduleList,
      projectList,
      organizationList,
      taskList,
      organization,
      getTaskList,
      selectedSchedule,
      isLoading,
    } = this.props;
    const {
      modalIsOpen,
      currentDate,
      defaultDate,
      selectedDate,
      selectedTimeInterval,
      project_id,
      selectedMember,
      mode,
      time_interval,
    } = this.state;

    const projects = [{ label: "All Projects", value: "" }];
    projectList &&
      organization &&
      projectList
        .filter((project) => project.is_active === true)
        .forEach((project) => {
          projects.push({ label: project.name, value: project.id });
        });

    let userList = [];

    if (project_id && project_id.value !== "") {
      const currentProject = projectList.find(
        (project) => project.id === project_id.value
      );
      if (
        currentProject &&
        ((currentProject.project_manager &&
          currentProject.project_manager.id ===
            localStorage.getItem("user_id")) ||
          (organization &&
            (organization.role === "owner" || organization.role === "admin")))
      ) {
        userList =
          currentProject.assignees &&
          currentProject.assignees.map((assignee) => ({
            label: assignee.name,
            value: assignee.id,
          }));
      }
    } else if (
      organization &&
      (organization.role === "owner" || organization.role === "admin")
    ) {
      userList =
        organizationList.members &&
        organizationList.members.map((member) => ({
          label: getFullName(member),
          value: member.id,
        }));
    }
    return (
      <>
        {/* <Loader open={isLoading} /> */}
        <div className="content">
          <ScheduleModal
            isOpen={modalIsOpen}
            toggle={this.toggleScheduleModal}
            userList={organization && userList}
            projectList={organization && projectList}
            taskList={organization && taskList}
            organizationId={organization && organization.id}
            getTaskList={getTaskList}
            handleSave={this.handleSave}
            handleUpdate={this.handleUpdate}
            mode={mode}
            selectedSchedule={selectedSchedule}
            deactivateSchedule={this.deactivateSchedule}
          />
          <Row>
            <h3 className="remove-default-mt default-header">Schedules </h3>
          </Row>
          {/* <ScheduleHeader
            time_interval={time_interval}
            currentProject={project_id}
            projects={projects}
            handleChange={this.handleChange}
            scheduleTypes={scheduleTypes}
            selectedMember={selectedMember}
            userList={userList}
            getScheduleList={this.getScheduleList}
            toggleScheduleModal={this.toggleScheduleModal}
            selectDate={this.selectDate}
            currentDate={currentDate}
            defaultDate={defaultDate}
            selectedDate={selectedDate}
            organization={organizationList.selectedOrganization}
          /> */}
          <ScheduleTable
            scheduleList={scheduleList}
            time_interval={selectedTimeInterval}
            defaultDate={defaultDate}
            selectedDate={selectedDate}
            deactivateSchedule={this.deactivateSchedule}
            toggleScheduleModal={this.toggleScheduleModal}
            edit={this.edit}
            isLoading={isLoading}
          />
        </div>
      </>
    );
  }
}

export default Schedules;
