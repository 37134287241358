export const ROLES = {
  MEMBER: "member",
  TEAM_LEADER: "team_leader",
  OWNER: "owner",
  ADMIN: "admin",
};

export const RESTRICTED_ROUTES = {
  MEMBER: [
    "Invoice",
    "Clients",
    "Organizations",
    "Payroll",
    "Integrations",
    "Reports",
    "Settings",
    "Live Feed",
  ],
  TEAM_LEADER: ["Payroll", "Organizations", "Settings"],
  SOLO_PLAN: ["Live Feed", "Teams", "Members"],
};

export const ROUTE_MAPPINGS = {
  "/user/settings": [
    "/user/current-plan",
    "/user/subscription",
    "/user/activity-settings",
    "/user/timesheet-settings",
  ],
  "/user/projects": [
    "/user/create-project",
    "/user/edit-project",
    "/user/project-details",
  ],
  "/user/live-feed": ["/user/instant-screenshots"],
  "/user/organizations": ["/user/add-organization"],
  "/user/clients": [
    "/user/add-client",
    "/user/edit-client",
    "/user/client-details",
  ],
  "/user/members": ["/user/member-details", "/user/member-invitation"],
  "/user/teams": ["/user/add-team", "/user/team-details"],
  "/user/invoices": [
    "/user/create-invoice",
    "/user/edit-invoice",
    "/user/invoice-preview",
    "/user/invoice-client-view",
  ],
  "/user/reports/custom-report": ["/user/reports/create-custom-report"],
};
