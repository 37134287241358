import styled from "styled-components";

export const SkeletonPulse = styled.div`
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: pulse 1.6s ease-in-out infinite;

  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

export const SkeletonLine = styled(SkeletonPulse)`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "20px"};
  margin-bottom: ${(props) => props.mb || "12px"};
  border-radius: 4px;
`;

export const SidebarSkeletonContainer = styled.div`
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const GroupContainer = styled.div`
  margin-bottom: 24px;
`;
