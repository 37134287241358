import { connect } from "react-redux";
import Integrations from "./IntegrationsNew";

import {
  getAllIntegrationsList,
  getEnabledIntegrationsList,
} from "./integrationsActions";

import { getIsInstalledJira } from "./Jira/jiraIntegrationsActions";

const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,

  // integrations
  isLoading: state.integrations.isLoading,
  allIntegrationsList: state.integrations.allIntegrationsList,
  enabledIntegrationsList: state.integrations.enabledIntegrationsList,

  isInstalledJira: state.jiraIntegrations.isInstalledJira,
});

const mapDispatchToProps = (dispatch) => ({
  getAllIntegrationsList: (payload) =>
    dispatch(getAllIntegrationsList(payload)),
  getEnabledIntegrationsList: (payload) =>
    dispatch(getEnabledIntegrationsList(payload)),
  getIsInstalledJira: (payload) => 
    dispatch(getIsInstalledJira(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
