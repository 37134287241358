import { all, put, takeLatest } from "redux-saga/effects";

import { defaultApi } from "../../utils/axiosApi";

import {
  GET_CLOCK_IN_OUT_LIST,
  GET_CLOCK_IN_OUT_LIST_SUCCESS,
  GET_CLOCK_IN_OUT_LIST_FAILURE,
  EXPORT_ATTENDANCE_SUCCESS,
  EXPORT_ATTENDANCE_FAILURE,
  EXPORT_ATTENDANCE,
} from "../../modules/constants";

export function* getClockInOutList({ payload }) {
  const {
    organization_id,
    start_date,
    end_date,
    name,
    page,
    user_id,
    tz,
    attendanceFilter,
  } = payload;
  try {
    const url = `${organization_id}/attendance/clock-in-clock-out/?start_date=${start_date}&end_date=${end_date}${
      page ? `&page=${page}` : ``
    }${name ? `&name=${name}` : ``}${user_id ? `&user_id=${user_id}` : ``}${
      tz ? `&tz=${tz}` : ``
    }${attendanceFilter ? `&attendanceFilter=${attendanceFilter}` : ``}`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_CLOCK_IN_OUT_LIST_SUCCESS,
      payload: { data: response, timezone: tz },
    });
  } catch (err) {
    yield put({
      type: GET_CLOCK_IN_OUT_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* exportClockInOutList({ payload }) {
  const {
    organization_id,
    start_date,
    end_date,
    name,
    time_format,
    user_id,
    tz,
    attendanceFilter,
  } = payload;
  try {
    const url = `${organization_id}/attendance/export-clock-in-clock-out/?start_date=${start_date}&end_date=${end_date}${
      name ? `&name=${name}` : ``
    }${user_id ? `&user_id=${user_id}` : ``}${tz ? `&tz=${tz}` : ``}${
      time_format ? `&time_format=${time_format}` : ``
    }${attendanceFilter ? `&attendanceFilter=${attendanceFilter}` : ``}`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: EXPORT_ATTENDANCE_SUCCESS,
      payload: { data: response, start_date, end_date },
    });
  } catch (err) {
    yield put({
      type: EXPORT_ATTENDANCE_FAILURE,
      payload: err,
    });
  }
}

export default function* clockInOutSagas() {
  yield all([takeLatest(GET_CLOCK_IN_OUT_LIST, getClockInOutList)]);
  yield all([takeLatest(EXPORT_ATTENDANCE, exportClockInOutList)]);
}
