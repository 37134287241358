import { all, fork } from "redux-saga/effects";

// auth
import loginSagas from "./custom_modules/Login/loginSagas";
import signUpSagas from "./custom_modules/SignUp/signUpSagas";
import dashboardSagas from "./custom_modules/Dashboard/dashboardSagas";
import forgotPasswordSagas from "./custom_modules/ForgotPassword/forgotPasswordSagas";
import verificationCodeSagas from "./custom_modules/VerificationCode/verificationCodeSagas";
import resetPasswordSagas from "./custom_modules/ResetPassword/resetPasswordSagas";
import verifySagas from "./custom_modules/VerifyUser/verifySagas";

// profile
import profileSagas from "./custom_modules/UserProfile/userProfileSagas";

import liveFeedSagas from "./custom_modules/LiveFeed/liveFeedSagas";
import projectSagas from "./custom_modules/Projects/projectsSagas";
import organizationSagas from "./custom_modules/Organization/OrganizationSagas";
import taskSagas from "./custom_modules/Tasks/tasksSagas";
import clockInOutSagas from "./custom_modules/ClockInOut/clockInOutSagas";
import leaveSagas from "./custom_modules/Leave/leaveSagas";
import reportSagas from "./custom_modules/ReportsNew/reportsSagas";
import clientSagas from "./custom_modules/Clients/clientsSagas";
import activitySagas from "./custom_modules/ActivityNew/activitySagas";
// field services
import routeMapSagas from "./custom_modules/FieldServices/RouteMap/routeMapSagas";
import jobSitesSagas from "./custom_modules/FieldServices/JobSites/jobSitesSagas";
import geofenceClockInOutSagas from "./custom_modules/FieldServices/GeofenceClockInOut/geofenceClockInOutSagas";

// timesheet
import timesheetSagas from "./custom_modules/Timesheet/timesheetSagas";
import timesheetApprovalSagas from "./custom_modules/TimesheetApproval/timesheetApprovalSagas";
import workNotesSagas from "./custom_modules/WorkNotes/workNotesSagas";
import scheduleSagas from "./custom_modules/Schedule/scheduleSagas";
import memberSagas from "./custom_modules/Members/membersSagas";
import teamSagas from "./custom_modules/Teams/teamsSagas";
import integrationsSagas from "./custom_modules/IntegrationsNew/integrationsSagas";
import invoiceSagas from "./custom_modules/Invoice/invoiceSagas";
import requestJoinOrgSaga from "./custom_modules/Onboarding/RequestJoinOrg/requestJoinOrgSaga";
import onboardingSaga from "./custom_modules/Onboarding/OnboardingSteps/onboardingStepsSagas";
import redeemCodeSaga from "./custom_modules/RedeemCode/redeemCodeSaga";
import redeemDiscountCodeSaga from "./custom_modules/RedeemBlackFriday/redeemDiscountCodeSaga";
import redeemLimitedTimeCodeSaga from "./custom_modules/RedeemAppsumo/redeemCodeSaga";
import publicApiSagas from "./custom_modules/PublicApi/publicApiSagas";
import payrollSaga from "./custom_modules/Payroll/payrollSagas";
import adminSaga from "./layouts/Admin/adminSagas";
import subscriptionSaga from "./custom_modules/Subscription/subscriptionSagas";
import settingsSaga from "./custom_modules/Settings/settingsSagas";
import activitySettingsSaga from "./custom_modules/Settings/ActivitySettings/activitySettingsSagas";
import timesheetSettingsSaga from "./custom_modules/Settings/TimesheetSettings/timesheetSettingsSagas";
import jiraIntegrationsSaga from "./custom_modules/IntegrationsNew/Jira/jiraIntegrationsSagas";

/**
 * rootSaga
 */
export default function* root() {
  yield all([
    fork(loginSagas),
    fork(signUpSagas),
    fork(forgotPasswordSagas),
    fork(verificationCodeSagas),
    fork(dashboardSagas),
    fork(resetPasswordSagas),
    fork(verifySagas),
    fork(profileSagas),
    fork(projectSagas),
    fork(organizationSagas),
    fork(taskSagas),
    fork(clockInOutSagas),
    fork(leaveSagas),
    fork(reportSagas),
    fork(clientSagas),
    fork(activitySagas),
    fork(routeMapSagas),
    fork(jobSitesSagas),
    fork(geofenceClockInOutSagas),
    fork(timesheetSagas),
    fork(timesheetApprovalSagas),
    fork(workNotesSagas),
    fork(scheduleSagas),
    fork(liveFeedSagas),
    fork(memberSagas),
    fork(teamSagas),
    fork(integrationsSagas),
    fork(jiraIntegrationsSaga),
    fork(invoiceSagas),
    fork(requestJoinOrgSaga),
    fork(onboardingSaga),
    fork(redeemCodeSaga),
    fork(redeemDiscountCodeSaga),
    fork(redeemLimitedTimeCodeSaga),
    fork(publicApiSagas),
    fork(payrollSaga),
    fork(subscriptionSaga),
    fork(adminSaga),
    fork(settingsSaga),
    fork(activitySettingsSaga),
    fork(timesheetSettingsSaga),
  ]);
}
