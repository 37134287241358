import { connect } from "react-redux";

// Component
import Organizations from "./OrganizationsNew";

// Actions
import {
  getOrganizationList,
  createOrganization,
  getOrganizationTypes,
  getOrganizationPlans,
  updateOrganization,
  selectOrganization,
  getOrganizationMembers,
  deleteOrganization,
} from "./OrganizationActions";

import { getTrialInfo } from "../../layouts/Admin/adminActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  organizations: state.organization,
  isLoading: state.organization.isLoading,
  orgUpdateLoading: state.organization.orgUpdateLoading,
  organization: state.organization.selectedOrganization,
  orgCreated: state.organization.orgCreated,
  createdOrgId: state.organization.createdOrgId,
  createdOrgIsActive: state.organization.createdOrgIsActive,
  filters: state.filterReducer.filters,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getOrganizationList: () => dispatch(getOrganizationList()),
  getOrganizationTypes: () => dispatch(getOrganizationTypes()),
  getOrganizationPlans: () => dispatch(getOrganizationPlans()),
  createOrganization: (data) => dispatch(createOrganization(data)),
  updateOrganization: (data) => dispatch(updateOrganization(data)),
  selectOrganization: (data) => dispatch(selectOrganization(data)),
  getOrganizationMembers: (data) => dispatch(getOrganizationMembers(data)),
  deleteOrganization: (data) => dispatch(deleteOrganization(data)),
  getTrialInfo: () => dispatch(getTrialInfo()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Organizations);
