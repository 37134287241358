import { ADD_PATH, SAVE_FILTERS } from "./modules/constants";

// Initial State
const initialState = {
  filters: null,
  paths: [],
};

// Action Handlers
export const handleSetFilters = (state, action) => {
  return {
    ...state,
    filters: action?.payload,
  };
};

export const handleAddPath = (state, action) => {
  const pathName = action?.payload?.split("?")[0];
  const newPaths = [pathName, ...state.paths];
  if (newPaths.length > 3) {
    newPaths.pop();
  }
  let filters = state.filters;
  if (state.filters?.pathname) {
    if (state.filters?.pathname === "/user/clients") {
      if (
        ![
          "/user/clients",
          "/user/add-client",
          "/user/edit-client",
          "/user/client-details",
        ].includes(pathName)
      ) {
        filters = null;
      }
    }

    if (state.filters?.pathname === "/user/invoices") {
      if (
        ![
          "/user/invoices",
          "/user/edit-invoice",
          "/user/invoice-preview",
          "/user/invoice-client-view",
          "/user/create-invoice",
        ].includes(pathName)
      ) {
        filters = null;
      }
    }
    if (state.filters?.pathname === "/user/teams") {
      if (
        !["/user/add-team", "/user/teams", "/user/team-details"].includes(
          pathName
        )
      ) {
        filters = null;
      }
    }

    if (state.filters?.pathname === "/user/members") {
      if (
        ![
          "/user/members",
          "/user/member-details",
          "/user/member-invitation",
        ].includes(pathName)
      ) {
        filters = null;
      }
    }
    if (state.filters?.pathname === "/user/organizations") {
      if (
        !["/user/add-organization", "/user/organizations"].includes(pathName)
      ) {
        filters = null;
      }
    }

    if (state.filters?.pathname === "/user/organizations") {
      if (
        !["/user/add-organization", "/user/organizations"].includes(pathName)
      ) {
        filters = null;
      }
    }
    if (state.filters?.pathname === "/user/projects") {
      if (
        ![
          "/user/create-project",
          "/user/project-details",
          "/user/edit-project",
          "/user/projects",
        ].includes(pathName)
      ) {
        filters = null;
      }
    }
  }

  return {
    ...state,
    filters,
    paths: newPaths,
  };
};

// Reducer
const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_FILTERS:
      return handleSetFilters(state, action);
    case ADD_PATH:
      return handleAddPath(state, action);
    default:
      return state;
  }
};

export const setPathName = (pathName) => ({
  type: SAVE_FILTERS,
  payload: pathName,
});

export const addPath = (path) => ({
  type: ADD_PATH,
  payload: path,
});

export default filterReducer;
