import { useState, useEffect, forwardRef } from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countryTimeZone from "countries-and-timezones";

import {
  AddOrganizationContainer,
  OnboardingForm,
  OnboardingFormAlert,
  OnboardingInputContainer,
  OnboardingInputLabel,
  InputRequiredStar,
  OnboardingInput,
  OnboardingNextButton,
  NextButtonText,
  NextButtonIcon,
  InputSubLabel,
  SkipButton,
} from "../onboardingStepsStyles";
import {
  CommonFlex,
  CommonText,
  CommontTextWithLink,
} from "../../../../styledComponents/common";

import { timezones } from "../../../../utils/timezone";

// import PreviousButton from "./PreviousButton";

import Select from "../../../../components/ReactSelectDropdown";
import TimezoneDropdownStyle from "./timezoneDropdownStyle";
import { IndicatorSeparator } from "./DropdownIndicatorSeparator";

import nextIcon from "../../../../assets/img/onboardingImg/right_arrow_green.svg";
import { checkHtml, checkUrl } from "../../../../utils/helper";

const genericDomains = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "outlook.com",
  "aol.com",
  "protonmail.com",
  "icloud.com",
  "mail.com",
  "zoho.com",
  "gmx.com",
  "yandex.com",
  "inbox.com",
  "fastmail.com",
  "yahoo.co.uk",
  "yahoo.co.in",
  "hotmail.co.uk",
  "hotmail.fr",
  "live.com",
  "live.co.uk",
  "outlook.co.uk",
  "outlook.in",
  "protonmail.ch",
  "rediffmail.com",
  "tutanota.com",
  "hushmail.com",
  "runbox.com",
  "posteo.de",
  "startmail.com",
];

const AddOrganization = forwardRef((props, ref) => {
  const [organizationName, setOrganizationName] = useState("");
  const [defaultOrgName, setDefaultOrgName] = useState("");
  const [timezone, setTimezone] = useState({});
  const [defaultTimezone, setDefaultTimezone] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [saveErrors, setSaveErrors] = useState({});
  const [countryCode, setCountryCode] = useState("us");

  useEffect(() => {
    const userEmail = localStorage.getItem("email");
    const userFullName = localStorage.getItem("user_fullName");

    if (!userEmail || !userFullName) return;

    const generateOrgName = () => {
      const [localPart, domain] = userEmail.split("@");
      const baseName = genericDomains.includes(domain)
        ? `${userFullName.split(" ")[0]}'s Organization`
        : domain.split(".")[0];

      return baseName.length > 65
        ? baseName.substring(0, 65).replace(/^./, (c) => c.toUpperCase())
        : baseName.replace(/^./, (c) => c.toUpperCase());
    };

    setDefaultOrgName(generateOrgName());
  }, []);

  useEffect(() => {
    setOrganizationName(defaultOrgName);
  }, [defaultOrgName]);

  useEffect(() => {
    setDefaultTimezone({
      label: `(UTC${convertOffset(new Date().getTimezoneOffset())}) ${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }`,
      value: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    });
  }, []);

  useEffect(() => {
    if (defaultTimezone) {
      setTimezone(defaultTimezone);
    }
  }, [defaultTimezone]);

  useEffect(() => {
    const orgData = {};
    if (defaultOrgName) {
      orgData.name = defaultOrgName;
    }
    if (defaultTimezone) {
      orgData.timezone = defaultTimezone.value;
    }
    props.setOrganizationData(orgData);
  }, [defaultOrgName, defaultTimezone]);

  const fetchCountryCodeFromTimeZone = () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const country = countryTimeZone.getTimezone(timeZone)?.countries?.[0];
    setCountryCode(country?.toLowerCase() || "us");
  };

  useEffect(() => {
    fetchCountryCodeFromTimeZone();
  }, []);

  const filterOptions = (options, filterString, values) => {
    return options.filter(
      (x) => x.label.includes(filterString) || x.metadata.includes(filterString)
    );
  };
  const handleNameChange = (e) => {
    setSaveErrors({});
    setOrganizationName(e.target.value);
  };
  const handleTimezoneChange = (e) => {
    setSaveErrors({});
    setTimezone({
      label: e.label,
      value: e.value,
    });
  };

  const handleNext = (skip = false) => {
    if (checkError()) {
      const orgData = {
        name: skip ? defaultOrgName : organizationName,
        timezone: skip ? defaultTimezone.value : timezone.value,
        phone: skip ? null : phoneNumber ? `+${phoneNumber}` : null,
      };
      if (skip) {
        setTimeout(() => {
          setOrganizationName(defaultOrgName);
          setTimezone(defaultTimezone);
          setPhoneNumber("");
        }, 300);
      }
      props.setOrganizationData(orgData);
      if (props.activeStep === "organization") {
        props.handleNext("organization");
      } else if (props.activeStep === "project") {
        if (props.projectsData && props.projectsData.length > 0) {
          props.handleNext("project");
        } else {
          props.handleNext("organization");
        }
      } else if (props.activeStep === "invite-member") {
        props.handleNext("project");
      } else if (props.activeStep === "pricing") {
        props.handleNext("invite-member");
      }
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!organizationName.trim()) {
      formIsValid = false;
      saveErrors["name"] = "Organization name is required.";
    } else if (organizationName.trim().length > 65) {
      formIsValid = false;
      saveErrors["name"] = "Organization name is too long.";
    } else if (checkUrl(organizationName)) {
      formIsValid = false;
      saveErrors["name"] = "Organization name can not contain url.";
    } else if (checkHtml(organizationName)) {
      formIsValid = false;
      saveErrors["name"] = "Invalid input in organization name.";
    }

    if (timezone === "") {
      formIsValid = false;
      saveErrors["timezone"] = "Please select a timezone.";
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const convertOffset = (offset) => {
    const hour = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;

    return `${offset <= 0 ? "+" : "-"}${hour >= 10 ? hour : `0${hour}`}:${
      minutes >= 10 ? minutes : `0${minutes}`
    }`;
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleNext();
    }
  };

  const handleJoinOrg = () => {
    props.history.push("/setup/request-to-join");
  };

  return (
    <AddOrganizationContainer ref={ref}>
      {/* <PreviousButton handleClick={props.handleBack} type="organization" /> */}
      <OnboardingForm onKeyDown={handleKeyPress}>
        <OnboardingInputContainer gap="5px">
          <OnboardingInputLabel>
            What's your organization name?{" "}
            <InputRequiredStar>*</InputRequiredStar>
          </OnboardingInputLabel>
          <OnboardingInput
            type="text"
            id="organizationName"
            placeholder="Example: SpaceX"
            value={organizationName}
            onChange={(e) => handleNameChange(e)}
            autoFocus
            alert={
              saveErrors &&
              Object.keys(saveErrors).length > 0 &&
              saveErrors.name
            }
          />
          <CommonFlex justifyContent="space-between">
            <CommonText
              $label
              fontWeight="500"
              tabFontSize="10px"
              mobileFontSize="9px"
            >
              You can edit organization name later on.
            </CommonText>
            <CommontTextWithLink
              primary
              $title
              fontSize="16px"
              tabFontSize="12px"
              mobileFontSize="10px"
              onClick={() => handleJoinOrg()}
            >
              I've organization code to join.
            </CommontTextWithLink>
          </CommonFlex>
          {saveErrors &&
            Object.keys(saveErrors).length > 0 &&
            saveErrors.name && (
              <OnboardingFormAlert>{saveErrors["name"]}</OnboardingFormAlert>
            )}
        </OnboardingInputContainer>
        <OnboardingInputContainer gap="5px">
          <OnboardingInputLabel>
            Your timezone <InputRequiredStar>*</InputRequiredStar>
          </OnboardingInputLabel>
          <Select
            isSearchable
            value={
              timezone && timezone.value
                ? {
                    label: timezone.label,
                    value: timezone.value,
                  }
                : ""
            }
            name="timezone"
            id="timezone"
            placeholder="UTC: Asia - Dhaka"
            options={timezones}
            filterOptions={filterOptions}
            onChange={(e) => handleTimezoneChange(e)}
            components={{ IndicatorSeparator }}
            styles={TimezoneDropdownStyle()}
          />
          <CommonText
            $label
            fontWeight="500"
            tabFontSize="10px"
            mobileFontSize="9px"
          >
            You can edit timezone later on.
          </CommonText>
          {saveErrors &&
            Object.keys(saveErrors).length > 0 &&
            saveErrors.timezone && (
              <OnboardingFormAlert>
                {saveErrors["timezone"]}
              </OnboardingFormAlert>
            )}
        </OnboardingInputContainer>
        <OnboardingInputContainer gap="5px">
          <CommonFlex gap="7px">
            <OnboardingInputLabel>Your phone number</OnboardingInputLabel>
            <InputSubLabel marginLeft="0">(Optional)</InputSubLabel>
          </CommonFlex>
          <PhoneInput
            enableSearch
            country={countryCode}
            value={phoneNumber}
            onChange={(phone) => setPhoneNumber(phone)}
            inputClass="onboarding-phone-number-input"
            buttonClass="onboarding-phone-number-flag-dropdown"
            inputStyle={{
              outline: "none",
              boxShadow: "none",
            }}
          />
          <CommonText
            $label
            fontWeight="500"
            tabFontSize="10px"
            mobileFontSize="9px"
          >
            You can edit phone number later on.
          </CommonText>
        </OnboardingInputContainer>
        <CommonFlex gap="40px" alignItems="center">
          <OnboardingNextButton onClick={() => handleNext(false)}>
            <NextButtonText>Next</NextButtonText>
            <NextButtonIcon src={nextIcon} alt="" />
          </OnboardingNextButton>
          <SkipButton
            type="gray"
            hoverUnderline
            onClick={() => handleNext(true)}
          >
            Skip
          </SkipButton>
        </CommonFlex>
      </OnboardingForm>
    </AddOrganizationContainer>
  );
});

export default AddOrganization;
