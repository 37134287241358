export const timeList = [
  {
    time: `12:00 AM`,
  },
  {
    time: `1:00 AM`,
  },
  {
    time: `2:00 AM`,
  },
  {
    time: `3:00 AM`,
  },
  {
    time: `4:00 AM`,
  },
  {
    time: `5:00 AM`,
  },
  {
    time: `6:00 AM`,
  },
  {
    time: `7:00 AM`,
  },
  {
    time: `8:00 AM`,
  },
  {
    time: `9:00 AM`,
  },
  {
    time: `10:00 AM`,
  },
  {
    time: `11:00 AM`,
  },
  {
    time: `12:00 PM`,
  },
  {
    time: `1:00 PM`,
  },
  {
    time: `2:00 PM`,
  },
  {
    time: `3:00 PM`,
  },
  {
    time: `4:00 PM`,
  },
  {
    time: `5:00 PM`,
  },
  {
    time: `6:00 PM`,
  },
  {
    time: `7:00 PM`,
  },
  {
    time: `8:00 PM`,
  },
  {
    time: `9:00 PM`,
  },
  {
    time: `10:00 PM`,
  },
  {
    time: `11:00 PM`,
  },
  {
    time: `12:00 AM`,
  },
];
