import { useState, useEffect } from "react";

import { Row, Col, CardBody } from "reactstrap";

import {
  Container,
  PageTitle,
  ContentHeader,
  CardTitle,
} from "../../styledComponents/common";

import SelectPlan from "./Select Plan";

import { AddOrganizationForm } from "./AddOrganizationForm";

import BackButtonComponent from "../../components/BackButtonComponent/BackButtonComponent";

import goBackLogo from "../../assets/img/icons/previous.svg";
import addOrganizationArtwork from "../../assets/img/common/create-workspace.svg";

import classNames from "classnames";

const AddOrganization = (props) => {
  const [activeStep, setActiveStep] = useState(1);
  const [types, setTypes] = useState(null);

  useEffect(() => {
    props.getOrganizationTypes();

    return () => {
      props.clearOrgCreated();
    };
  }, []);
  useEffect(() => {
    if (props.organizations && props.organizations.types) {
      setTypes(props.organizations.types);
    }
  }, [props.organizations]);
  useEffect(() => {
    if (props.organizations.orgCreated === true) {
      setActiveStep(2);
    }
  }, [props.organizations.orgCreated]);

  const handleSubmitGeneralInfo = (data) => {
    props.createOrganization(data);
  };

  return (
    <div className="content">
      <PageTitle>Organizations</PageTitle>
      <ContentHeader>
        {props.organization?.role === "admin" ||
        props.organization?.role === "owner" ? (
          <BackButtonComponent
            onClick={() => props.history.push("/user/organizations")}
            subTitle="Back to all organizations"
          />
        ) : (
          <BackButtonComponent
            onClick={() => props.history.goBack()}
            subTitle="Back"
          />
        )}
      </ContentHeader>
      <Container marginTop="0">
        <CardBody style={{ padding: "30px 20px" }}>
          <Row>
            <Col md={4}>
              <div
                className="progress"
                style={{ height: "2px", borderRadius: 0 }}
              >
                <div
                  className={classNames(
                    "progress-bar",
                    "progress-bar-workspace"
                  )}
                  style={{ width: activeStep === 1 ? "50%" : "100%" }}
                ></div>
              </div>
            </Col>
          </Row>
          <Row>
            {activeStep && activeStep === 1 && (
              <>
                <Col md={5}>
                  <CardTitle margin="15px 0" fontWeight="500">
                    General details of your organization
                  </CardTitle>
                  <AddOrganizationForm
                    orgTypes={types}
                    handleSubmitGeneralInfo={handleSubmitGeneralInfo}
                    isLoading={props.isLoading}
                  />
                </Col>
                <Col
                  md={7}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt=""
                    src={addOrganizationArtwork}
                    style={{ height: "80%" }}
                  />
                </Col>
              </>
            )}
            {activeStep && activeStep === 2 && (
              // <>
              //   <Col md={12}>
              //     <p
              //       style={{
              //         fontSize: "20px",
              //         marginTop: "25px",
              //         color: "#2f394e",
              //       }}
              //     >
              //       Choose the plan that fits your team
              //     </p>
              //     <ChoosePlan
              //       subscriptionPlans={props.subscriptionPlans}
              //       checkPaymentApplicable={props.checkPaymentApplicable}
              //       clearPaymentApplicable={props.clearPaymentApplicable}
              //       paymentApplicable={props.paymentApplicable}
              //       organization={props.organization}
              //       updateOrganization={props.updateOrganization}
              //       selectedPlanId={props.planId}
              //       history={props.history}
              //     />
              //   </Col>
              // </>
              <SelectPlan history={props.history} />
            )}
          </Row>
        </CardBody>
      </Container>
    </div>
  );
};

export default AddOrganization;
