import { convertTimestampToTz } from "../../utils/timeUtils";

export const processTimeEntry = (entry, tz) => {
  return {
    ...entry,
    duration: entry.end_timestamp - entry.start_timestamp,
    start_timestamp: convertTimestampToTz(entry.start_timestamp, tz),
    end_timestamp: convertTimestampToTz(entry.end_timestamp, tz),
  };
};
