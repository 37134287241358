import { useState, useCallback } from "react";

import { getCollapsedStates } from "../sidebarHelpers";

export const useCollapseState = (routes) => {
  const [collapseState, setCollapseState] = useState(() =>
    getCollapsedStates(routes)
  );

  const toggleCollapse = useCallback((path) => {
    setCollapseState((prev) => ({
      ...prev,
      [path.state]: !prev[path.state],
    }));
  }, []);

  return [collapseState, toggleCollapse];
};
