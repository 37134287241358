import { useState, useEffect } from "react";
import {
  GroupContainer,
  SkeletonLine,
  SidebarSkeletonContainer,
} from "./sidebarSkeletonStyles";

const SidebarSkeleton = () => {
  const [linesPerGroup, setLinesPerGroup] = useState([]);

  useEffect(() => {
    const calculateLines = () => {
      const sidebarHeight = window.innerHeight - 137; // Same as CustomScrollbars height
      const containerPadding = 40;
      const groupPadding = 8;
      const lineHeight = 20 + 12;

      // Calculate available height
      const availableHeight = sidebarHeight - containerPadding;
      const heightPerGroup = Math.floor((availableHeight - groupPadding) / 3);

      // Calculate lines per group
      const linesPerGroup = Math.floor(heightPerGroup / lineHeight);

      // Set minimum and maximum lines
      const groups = [
        Math.max(4, Math.min(linesPerGroup, 8)), // Analyze group
        Math.max(3, Math.min(linesPerGroup - 1, 6)), // Manage group
        Math.max(2, Math.min(linesPerGroup - 1, 5)), // Admin group
      ];

      setLinesPerGroup(groups);
    };

    calculateLines();
    window.addEventListener("resize", calculateLines);

    return () => window.removeEventListener("resize", calculateLines);
  }, []);

  const renderGroup = (numLines, index) => (
    <GroupContainer key={index}>
      <SkeletonLine width="80px" height="14px" mb="16px" />
      {Array(numLines)
        .fill(0)
        .map((_, lineIndex) => (
          <SkeletonLine
            key={lineIndex}
            mb="8px"
            width={lineIndex === numLines - 1 ? `${80 - index * 10}%` : "100%"}
          />
        ))}
    </GroupContainer>
  );

  return (
    <SidebarSkeletonContainer>
      {linesPerGroup.map((numLines, index) => renderGroup(numLines, index))}
    </SidebarSkeletonContainer>
  );
};

export default SidebarSkeleton;
