import { useState, useEffect, Fragment } from "react";
import moment from "moment";
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonText,
} from "../../styledComponents/common";
import {
  TableDateContainer,
  ColoredLine,
} from "../../styledComponents/reports";
import { PrimaryButton, WhiteButton } from "../../styledComponents/buttons";
import { TableItem } from "../../styledComponents/teams";
import { LeaveTableRow, ApproveRejectBadge } from "./leaveStyles";

import { getFullName } from "../../utils/helper";

import RoundImageNameComp from "../../components/RoundImageName/RoundImageNameComp";

const LeaveTable = (props) => {
  const { leave, tabSelect, index, key, modalToggle, selectedOrganization } =
    props;

  const openModal = (id, button) => {
    modalToggle(id, button);
  };
  return (
    <div key={key}>
      <CommonText>{moment(leave[0]).format("ddd, D MMM, YYYY")}</CommonText>
      <TableDateContainer index={index}>
        {leave[1].map((item, index) => {
          return (
            <Fragment key={item.id}>
              <LeaveTableRow manual>
                <TableItem manual>
                  <CommonGrid justifyContent="start" alignItem="center">
                    <RoundImageNameComp
                      imgSource={item.user.avatar || null}
                      name={item.user ? getFullName(item.user) : ""}
                      index={index}
                      imageSize="32px"
                      color={item.user.color || null}
                    />
                  </CommonGrid>
                </TableItem>

                <TableItem manual>
                  <CommonGrid
                    justifyContent="start"
                    alignItem="center"
                    gap="25px"
                  >
                    <CommonFlex gap="10px">
                      <CommonText>Leave Date:</CommonText>
                      <CommonText fontWeight="600" name>
                        {moment(item.start).format("MMM D")}
                        {item.end
                          ? ` - ${moment(item.end).format("MMM D")}`
                          : ""}
                      </CommonText>
                    </CommonFlex>

                    <CommonFlex gap="10px">
                      <CommonText name>Duration:</CommonText>
                      <ColoredText type="blue" fontSize="13px" semibold>
                        {item.halfDay
                          ? "Half day"
                          : moment(item.end).diff(
                              moment(item.start),
                              "days"
                            ) === 0
                          ? "1 Day"
                          : ` ${
                              moment(item.end).diff(
                                moment(item.start),
                                "days"
                              ) + 1
                            } Days`}
                      </ColoredText>
                    </CommonFlex>
                  </CommonGrid>

                  <CommonText margin="30px 0px 10px" $label>
                    Message
                  </CommonText>
                  <CommonText name>{item.message}</CommonText>

                  {tabSelect === "pending" ? (
                    localStorage.getItem("user_id") &&
                    localStorage.getItem("user_id") === item.user.id ? (
                      <ColoredText
                        type="warning"
                        hover
                        margin="20px 0 0"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          width: "max-content",
                        }}
                        fontSize="13px"
                        semibold
                        onClick={() => openModal(item.id, "withdraw")}
                      >
                        Withdraw Application
                      </ColoredText>
                    ) : null
                  ) : (
                    <CommonFlex gap="15px" margin="25px 0 0">
                      {item.statusChangedBy ? (
                        <ApproveRejectBadge tabSelect={tabSelect}>
                          {tabSelect === "accepted" ? "Accepted" : "Rejected"}{" "}
                          By {item.statusChangedBy.first_name}
                        </ApproveRejectBadge>
                      ) : null}
                      {item.statusChangedAt ? (
                        <CommonText>
                          on{" "}
                          {moment(item.statusChangedAt).format(
                            "ddd, DD MMM, YYYY"
                          )}
                        </CommonText>
                      ) : null}
                    </CommonFlex>
                  )}
                </TableItem>

                {selectedOrganization.role !== "member" ? (
                  tabSelect === "pending" ? (
                    <TableItem manual style={{ alignSelf: "center" }}>
                      <CommonFlex gap="10px" margin="0 0 0 20px">
                        <WhiteButton
                          type="cancel"
                          onClick={() => openModal(item.id, "reject")}
                        >
                          Reject
                        </WhiteButton>
                        <PrimaryButton
                          onClick={() => openModal(item.id, "accept")}
                        >
                          Accept
                        </PrimaryButton>
                      </CommonFlex>
                    </TableItem>
                  ) : //   <TableItem manual style={{ alignSelf: "center" }}>
                  //     <CommonFlex gap="10px" margin="0 0 0 20px">
                  //       <WhiteButton
                  //         type="delete"
                  //         onClick={() => openModal(item.id, "delete")}
                  //       >
                  //         Remove
                  //       </WhiteButton>
                  //     </CommonFlex>
                  //   </TableItem>
                  null
                ) : null}
              </LeaveTableRow>
              {index !== leave[1].length - 1 && <ColoredLine />}
            </Fragment>
          );
        })}
      </TableDateContainer>
    </div>
  );
};

export default LeaveTable;
