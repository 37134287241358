import BackButtonComponent from "../../../../components/BackButtonComponent/BackButtonComponent";
import ProjectDropdown from "../../../../components/CommonFilter/ProjectDropdown";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  CardTitle,
  CommonFlex,
  FilterLabel,
  FormAlert,
  InputWithLabelSection,
  PageTitle,
  RedStar,
  ContentHeader,
} from "../../../../styledComponents/common";
import {
  Container,
  InputField,
} from "../../../../styledComponents/createProject";
import { CreateCustomReportDropdowns } from "../customReportStyles";
import AddMultiMemberDropdown from "../../../../components/CustomDropdown/AddMultiMemberDropdown";
import ReactDateRangePicker from "../../../../components/ReactDateRangePicker/ReactDateRangePicker";
import CustomReportFeatureButton from "./customReportFeatureButtons";
import {
  PrimaryButton,
  TabButton,
  TabButtonContainer,
} from "../../../../styledComponents/buttons";
import FilterDropDownStyle from "../../../../components/DropdownStyle/FilterDropDownStyle";
import TaskDropdown from "../../../../components/CommonFilter/TaskDropdown";
import { getDateLabel, getFullName } from "../../../../utils/helper";

const CreateCustomReport = ({
  history,
  organizationMembersList,
  getOrganizationMembersList,
  getProjectShortList,
  organization,
  isLoading,
  createReportFailed,
  createdReport,
  createCustomReport,
  getProjectAssigneeList,
  projectAssigneeList,
  getTaskAssigneeList,
  taskAssigneeList,
}) => {
  const [taskAssignees, setTaskAssignees] = useState([]);

  const [startDate, setStartDate] = useState(
    moment().startOf("isoWeek").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("isoWeek").format("YYYY-MM-DD")
  );
  const [saveErrors, setSaveErrors] = useState({});

  const [reportName, setReportName] = useState(
    `New Report ${Math.round(Math.random(555) * 1000)}`
  );
  const [selectedProject, setSelectedProject] = useState({
    value: null,
    label: "(All Projects)",
  });
  const [selectedTask, setSelectedTask] = useState({
    value: "",
    label: "(All Tasks)",
  });
  const [selectedMembers, setSelectedMembers] = useState([]);
  const initialFeatureList = {
    project: {
      title: "Project",
      selected: true,
    },
    task: {
      title: "Task",
      selected: false,
    },
    total_time: {
      title: "Total Time",
      selected: true,
    },
    manual_time: {
      title: "Manual Time",
      selected: false,
    },
    notes: {
      title: "Notes",
      selected: false,
    },
    // task: {
    //   title: "Task",
    //   selected: true,
    // },
    // app_usage: {
    //   title: "App Usage",
    //   selected: false,
    // },
  };

  const [featureList, setFeatureList] = useState({ ...initialFeatureList });
  const [selectedTimeBreakdown, setSelectedTimeBreakdown] =
    useState("all_task");
  useEffect(() => {
    if (organization?.configuration) {
      const { mouse_track, keystroke_track } = organization.configuration;
      if (mouse_track === true && keystroke_track === true) {
        setFeatureList({
          ...featureList,
          idle_time: {
            title: "Idle Time",
            selected: false,
          },
          average_activity: {
            title: "Average Activity",
            selected: false,
          },
          active_time: {
            title: "Active Time",
            selected: false,
          },
          neutral_time: {
            title: "Neutral Time",
            selected: false,
          },
        });
      } else {
        setFeatureList({ ...initialFeatureList });
      }
    }
  }, [organization]);
  const updateSelection = (d) => {
    setFeatureList((prevFeatureList) => ({
      ...prevFeatureList,
      [d.key]: d,
    }));
  };
  const updateTimeBreakdownSelection = (val) => {
    setSelectedTimeBreakdown(val);
  };
  const updateSelectedMembers = (members) => {
    setSelectedMembers(members);
  };
  const onProjectSelect = (project) => {
    setSelectedProject(project);
    setSelectedTask(null);
  };
  const clearSelectedMembers = () => {
    setSelectedMembers([]);
  };
  const onTaskSelect = (task) => {
    setSelectedTask(task);
  };
  const changeDateRange = (e) => {
    setStartDate(moment(e.startDate).format("YYYY-MM-DD"));
    setEndDate(moment(e.endDate).format("YYYY-MM-DD"));
  };
  const selectLastWeek = () => {
    setStartDate(moment().subtract(6, "days").format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
  };
  const selectLastMonth = () => {
    setStartDate(moment().subtract(29, "days").format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
  };
  useEffect(() => {
    let errors = { ...saveErrors };
    delete errors["dateRange"];
    setSaveErrors(errors);
  }, [startDate, endDate]);
  useEffect(() => {
    let errors = { ...saveErrors };
    delete errors["reportName"];
    setSaveErrors(errors);
  }, [reportName]);

  useEffect(() => {
    if (localStorage.getItem("user_id") && organization?.id) {
      getProjectShortList({
        organization_id: organization.id,
        // user_id: localStorage.getItem("user_id"),
      });
      getOrganizationMembersList({
        organization_id: organization.id,
      });
    }
  }, [organization]);

  const checkErrors = () => {
    let saveErrors = {};
    let isFormValid = true;
    if (moment(endDate).diff(startDate, "months") >= 3) {
      saveErrors["dateRange"] = "Date range should not be more than 3 months";
      isFormValid = false;
    }
    if (!(reportName?.length > 2)) {
      if (reportName?.length === 0)
        saveErrors["reportName"] = "Report name should not be empty";
      else
        saveErrors["reportName"] =
          "Report name should be more than 2 characters";
      isFormValid = false;
    }
    if (reportName?.length > 255) {
      saveErrors["reportName"] =
        "Ensure this field has no more than 255 characters.";
      isFormValid = false;
    }
    if (!isFormValid) setSaveErrors(saveErrors);
    return isFormValid;
  };

  const handleCreateReport = () => {
    if (checkErrors()) {
      const selectedFeatures = Object.keys(featureList).filter(
        (key) => featureList[key].selected
      );
      if (
        reportName?.length > 2 &&
        organization.id &&
        // selectedProject?.value &&
        startDate &&
        endDate &&
        selectedMembers &&
        selectedFeatures?.length > 1
      ) {
        const newFeatures = [...selectedFeatures];
        if (selectedTimeBreakdown === "task_wise") {
          newFeatures.push("task_wise");
        }
        const payload = {
          organization_id: organization.id,
          project_ids: selectedProject?.value ? [selectedProject?.value] : [],
          name: reportName,
          start_date: startDate,
          end_date: endDate,
          task_ids: [],
          user_ids: selectedMembers?.map(
            (selectedMembers) => selectedMembers.id
          ),
          features: newFeatures,
        };
        if (selectedTask?.value) {
          payload.task_ids = [selectedTask.value];
        }
        createCustomReport(payload);
      } else {
        // console.log("Please fill all required fields");
      }
    }
  };
  useEffect(() => {
    if (isLoading === false && createReportFailed === false && createdReport) {
      history.push(`/user/reports/custom-report/${createdReport.id}`);
      setReportName(null);
      setSelectedProject(null);
      setSelectedMembers([]);
      setStartDate(moment().startOf("isoWeek").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("isoWeek").format("YYYY-MM-DD"));
      setFeatureList({ ...initialFeatureList });
    }
  }, [isLoading, createReportFailed, createdReport]);
  useEffect(() => {
    if (reportName) {
      document.querySelector("#report-name").focus();
    }
  }, []);
  useEffect(() => {
    clearSelectedMembers();
    if (selectedProject?.value && organization) {
      getProjectAssigneeList({
        organization_id: organization.id,
        project_id: selectedProject?.value,
      });
    }
    setSelectedMembers([]);
  }, [selectedProject]);
  useEffect(() => {
    clearSelectedMembers();
    if (selectedTask?.value && organization) {
      getTaskAssigneeList({
        organization_id: organization.id,
        project_id: selectedProject.value,
        task_id: selectedTask?.value,
      });
    }
    setSelectedMembers([]);
  }, [selectedTask]);
  useEffect(() => {
    if (taskAssigneeList && taskAssigneeList.length > 0) {
      let assigneeList = [];
      taskAssigneeList.map((assignee) => {
        assigneeList.push({
          id: assignee.id,
          name: getFullName(assignee),
          avatar: assignee.avatar || null,
          timezone: assignee.timezone || null,
          color: assignee.color || null,
        });
      });
      setTaskAssignees(assigneeList);
    } else if (taskAssigneeList && taskAssigneeList.length === 0) {
      setTaskAssignees([]);
    }
  }, [taskAssigneeList]);

  return (
    <div className="content">
      <PageTitle>Create Custom Report</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => history.push("/user/reports/custom-report")}
          subTitle="Back to Custom Reports"
        />
      </ContentHeader>
      <Container
        style={{
          padding: `30px`,
          marginTop: `20px`,
          background: `#FCFDFE`,
          height: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        {" "}
        <CardTitle>
          <CommonFlex direction="column" alignItems="start">
            <InputField
              height="32px"
              type="text"
              placeholder="Write Report name"
              value={reportName}
              id="report-name"
              onChange={(e) => setReportName(e.target.value)}
              style={{
                minWidth: "175px",
                width: "fit-content",
              }}
            />
            {saveErrors &&
              saveErrors["reportName"] &&
              saveErrors["reportName"] !== undefined && (
                <FormAlert margin="">{saveErrors["reportName"]}</FormAlert>
              )}
          </CommonFlex>
        </CardTitle>
        <CreateCustomReportDropdowns>
          <InputWithLabelSection>
            <FilterLabel>Projects</FilterLabel>
            <ProjectDropdown
              selectedProject={selectedProject}
              selectProject={onProjectSelect}
              selectedOrganization={organization}
              addAllProjects
            />
          </InputWithLabelSection>
          <InputWithLabelSection>
            <FilterLabel>Tasks</FilterLabel>
            <TaskDropdown
              selectedOrganization={organization}
              selectedProject={selectedProject}
              selectedTask={
                selectedTask?.value
                  ? selectedTask
                  : { value: "", label: "(All Tasks)" }
              }
              selectTask={onTaskSelect}
              // selectedMember={selectedMember}
              style={FilterDropDownStyle({ height: "40px", width: "180px" })}
              addAllTasksOption={true}
            />
          </InputWithLabelSection>
          <InputWithLabelSection>
            <FilterLabel>
              Assignee <RedStar>*</RedStar>
            </FilterLabel>
            <AddMultiMemberDropdown
              selectedMembers={selectedMembers}
              updateSelectedMembers={updateSelectedMembers}
              membersList={
                selectedTask?.value
                  ? taskAssignees
                  : selectedProject?.value
                  ? projectAssigneeList?.map((assignee) => ({
                      ...assignee,
                      id: assignee.user_id,
                      name: getFullName(assignee.user),
                      avatar: assignee.user.avatar ?? null,
                      color: assignee.user.color ?? null,
                    }))
                  : organizationMembersList
              }
              clearSelectedMembers={clearSelectedMembers}
            />
          </InputWithLabelSection>
          <InputWithLabelSection>
            <FilterLabel>
              Date Range{getDateLabel({ startDate, endDate })}
            </FilterLabel>
            <ReactDateRangePicker
              startDate={startDate ? moment(startDate) : null}
              startDateId="report-date-range-start-date"
              endDate={endDate ? moment(endDate) : null}
              endDateId="report-date-range-end-date"
              onDatesChange={changeDateRange}
              dateDisplayFormat="DD/MM/YYYY"
              onDateSelect={() => {}}
              selectLastWeek={selectLastWeek}
              selectLastMonth={selectLastMonth}
            />
            {saveErrors &&
              saveErrors["dateRange"] &&
              saveErrors["dateRange"] !== undefined && (
                <FormAlert margin="">{saveErrors["dateRange"]}</FormAlert>
              )}
          </InputWithLabelSection>
        </CreateCustomReportDropdowns>
        <InputWithLabelSection>
          {" "}
          <FilterLabel>Time Breakdown</FilterLabel>
          <CommonFlex
            wrap="wrap"
            style={{
              gap: "20px",
            }}
          >
            <InputWithLabelSection>
              <TabButtonContainer
                columns="auto auto"
                bgColor="rgba(0, 0, 0, 0.05)"
              >
                <TabButton
                  onClick={() => updateTimeBreakdownSelection("all_task")}
                  selected={selectedTimeBreakdown}
                  index={"all_task"}
                  activeBgColor="#FFFFFF"
                >
                  All Task
                </TabButton>
                <TabButton
                  onClick={() => updateTimeBreakdownSelection("task_wise")}
                  selected={selectedTimeBreakdown}
                  activeBgColor="#FFFFFF"
                  index={"task_wise"}
                >
                  Task Wise
                </TabButton>
              </TabButtonContainer>
            </InputWithLabelSection>
          </CommonFlex>
        </InputWithLabelSection>{" "}
        <InputWithLabelSection>
          {" "}
          <FilterLabel>Choose Feature</FilterLabel>
          <CommonFlex
            wrap="wrap"
            style={{
              gap: "20px",
            }}
          >
            {Object.keys(featureList).map((key) => {
              return (
                <CustomReportFeatureButton
                  updateSelection={updateSelection}
                  data={{
                    key,
                    ...featureList[key],
                  }}
                />
              );
            })}
          </CommonFlex>
        </InputWithLabelSection>
        <PrimaryButton
          fontSize="14px"
          disabled={
            !(
              reportName?.length > 2 &&
              selectedMembers?.length > 0 &&
              Object.keys(featureList).filter(
                (key) => featureList[key].selected
              ).length > 0
            )
          }
          style={{ marginTop: `auto`, width: "max-content" }}
          onClick={handleCreateReport}
        >
          Create Report
        </PrimaryButton>
      </Container>
    </div>
  );
};

export default CreateCustomReport;
