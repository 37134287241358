import { useMemo } from "react";
import { Redirect, Route } from "react-router-dom";
import ComponentCircleLoader from "../components/Loaders/ComponentCircleLoader";
import { createNotification } from "./notificationManager";

export const PrivateRoute = ({
  component: Component,
  selectedOrganization,
  restricted = [],
  ...rest
}) => {
  const role = useMemo(
    () => selectedOrganization?.role?.toLowerCase(),
    [selectedOrganization]
  );

  const isAuthenticated = localStorage.getItem("access_token");

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{ pathname: "/auth/login", state: { from: rest.location } }}
      />
    );
  }

  if (!role && restricted?.length) {
    return <ComponentCircleLoader height="100vh" />;
  }

  if (restricted?.includes(role)) {
    createNotification("error", "Access Denied!");
    return <Redirect to="/user/dashboard" />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
