import { useState, useEffect } from "react";
import moment from "moment-timezone";

import {
  Container,
  RoundMiniImage,
  TableText,
  CommonText,
  ColoredText,
} from "../../../styledComponents/common";
import { TableItem } from "../../../styledComponents/teams";
import { MemberNameDiv } from "../../../styledComponents/members";
import {
  ClockInOutTableHeader,
  ClockInOutTableRow,
} from "../../ClockInOut/clockInOutStyles";

import { getFullName, getHours, toHHMMSS } from "../../../utils/helper";

import { CommImageNameComp } from "../../Members/CommonComponents";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";

import avatar5 from "../../../assets/img/avatar_5.svg";

const ClockInOutTable = (props) => {
  const { isLoading, history, clockInOutList } = props;
  return (
    <Container style={{ paddingBottom: "20px" }}>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : clockInOutList && clockInOutList.length > 0 ? (
        <>
          <ClockInOutTableHeader>
            <TableItem>
              <TableText>Member Name</TableText>
            </TableItem>
            <TableItem>
              <TableText>Clock In</TableText>
            </TableItem>
            <TableItem>
              {/* <InfoTooltip
                mainText="Clock Out"
                toolTipText="Clock out occurs when the user stops the timer at the end of the day"
                title
                label
                top="-55px"
                tooltipPadding="5px 8px"
                infoSize="17px"
              /> */}
              <TableText>Clock Out</TableText>
            </TableItem>
            <TableItem>
              <TableText>Job Site</TableText>
            </TableItem>
          </ClockInOutTableHeader>

          {clockInOutList.map((data, index) => (
            <ClockInOutTableRow key={index}>
              <TableItem>
                <MemberNameDiv>
                  <CommImageNameComp
                    imgSource={data.user.avatar || avatar5}
                    name={getFullName(data.user)}
                    size="36px"
                  />
                </MemberNameDiv>
              </TableItem>
              <TableItem>
                <TableText name>
                  {data.clockin
                    ? moment.unix(data.clockin.timestamp).format("LT")
                    : "-"}
                </TableText>
              </TableItem>
              <TableItem>
                <TableText name>
                  {data.clockout
                    ? moment.unix(data.clockout.timestamp).format("LT")
                    : "-"}
                </TableText>
                {data.clockout && data.clockout.outside ? (
                  <ColoredText
                    type="warning"
                    fontSize="13px"
                    fontWeight="600"
                    margin="6px 0 0"
                  >
                    Out of Perimeter
                  </ColoredText>
                ) : null}
              </TableItem>
              {/* <TableItem>
                <TableText name>
                  {data.attendance && data.attendance.duration
                    ? getHours(toHHMMSS(data.attendance.duration))
                    : "-"}
                </TableText>
              </TableItem> */}
              <TableItem>
                <TableText name>
                  {data.jobsite && data.jobsite.id ? data.jobsite.name : "-"}
                </TableText>
              </TableItem>
            </ClockInOutTableRow>
          ))}
        </>
      ) : (
        <NoDataComponent />
      )}
    </Container>
  );
};

export default ClockInOutTable;
