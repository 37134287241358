import React, { useState } from "react";
import styled from "styled-components";
import copyIcon from "../../assets/img/icons/copy.svg";
import tickIcon from "../../assets/img/icons/tick.svg";

const CopyWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  color: ${({ color }) => color || "inherit"};
`;

const CopyIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-left: 10px;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`;

const CopyComponent = ({ text, color }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  return (
    <CopyWrapper color={color}>
      {text}
      <CopyIcon
        src={isCopied? tickIcon:copyIcon}
        alt="Copy"
        title={isCopied?"Copied":"Copy to clipboard"}
        onClick={handleCopy}
      />
    </CopyWrapper>
  );
};

export default CopyComponent;
