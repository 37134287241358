import {
  SinglePlanCardContainer,
  SinglePlanCardTitle,
  GetStartedButton,
  FeatureDivContainer,
  SingleFeatureDiv,
} from "./selectPlanStyles";
import { NextButtonIcon } from "../../onboardingStepsStyles";
import {
  CommonText,
  CommonImage,
  CommonFlex,
} from "../../../../../styledComponents/common";

import { features } from "./features";

import ButtonLoader from "../../../../../components/DropdownLoader/DropdownLoader";

import nextIcon from "../../../../../assets/img/onboardingImg/right_arrow_green.svg";
import grayTick from "../../../../../assets/img/icons/gray-tick.svg";

export default function SinglePlanCard(props) {
  const {
    title = "Free",
    plan,
    type,
    name,
    isLoading = false,
    handlePlanSelect,
  } = props;

  return (
    <SinglePlanCardContainer type={type}>
      <SinglePlanCardTitle>{title}</SinglePlanCardTitle>
      <FeatureDivContainer>
        <CommonText margin="20px 0 15px" fontWeight="600">
          What's included
        </CommonText>
        {name === "elite" && (
          <CommonText margin="0 0 12px" fontSize="12px" fontWeight="600">
            Everything in 'Time Tracking', plus:
          </CommonText>
        )}
        {features[name].map((feature, index) => (
          <SingleFeatureDiv key={index}>
            <CommonImage src={grayTick} alt="" size="16px" />
            <CommonText fontSize="12px">{feature}</CommonText>
          </SingleFeatureDiv>
        ))}
      </FeatureDivContainer>
      <CommonFlex margin="40px 0 0" gap="0" justifyContent="center">
        <GetStartedButton
          type={type}
          isLoading={isLoading}
          onClick={() => handlePlanSelect(plan)}
        >
          {isLoading ? (
            <ButtonLoader color="#fff" loading={true} size={7} />
          ) : (
            <>
              Get started free
              <NextButtonIcon src={nextIcon} alt="" width="18px" />
            </>
          )}
        </GetStartedButton>
      </CommonFlex>
    </SinglePlanCardContainer>
  );
}
