import { useState, useRef, useEffect, Fragment } from "react";
import FirstRoundLetter from "../../../components/ColorLetterRound/FirstRoundLetter";
import FirstRoundLetterComp from "../../../components/FirstRoundLetter/FirstRoundLetter";
import {
  AssigneesManageImgTextGrid,
  AssigneesManageRoundImage,
  BillingTextTooltip,
  BillingQues,
  BillingTooltip,
  AssigneeRoundImageSection,
  AssigneeRoundImage,
  RoundPersonImage,
  ShowAssigneeTitle,
  ShowAssigneeTitleText,
  RoundImageCross,
  ToolTipSection,
  Tooltip,
} from "../../../styledComponents/createProject";
import {
  TableText,
  AssignListContainer,
  AssignListItem,
  AssignListText,
  SearchBarWithAssignListContainer,
  AssigneeSearchBarWrapper,
  AssigneeSearchBarContainer,
  AssigneeSearchIcon,
  AssigneeSearchInput,
  ShowMoreAssigneeList,
} from "../../../styledComponents/common";

import DropdownLoader from "../../../components/DropdownLoader/DropdownLoader";

//dropdown
import Assign from "../../../assets/img/icons/assign2.svg";
import removeAssignee from "../../../assets/img/icons/remove_assignee.svg";
import searchIcon from "../../../assets/img/icons/search.svg";

export const CommImageNameComp = (props) => (
  <AssigneesManageImgTextGrid>
    <AssigneesManageRoundImage imageSize={props.imageSize}>
      {props.personImg}
    </AssigneesManageRoundImage>
    <TableText name>{props.personName}</TableText>
  </AssigneesManageImgTextGrid>
);

// ---- Dropdown
const ShowAssigneeTitleComp = (props) => (
  <ShowAssigneeTitle>
    <ShowAssigneeTitleText> {props.text} </ShowAssigneeTitleText>
  </ShowAssigneeTitle>
);

export const CommDropdownComp = ({
  selectedOrganization,
  assigneeManage,
  index,
  updateState,
  visibilityChange,
  getMembersList,
  membersList,
  getTeamList,
  teamList,
  assigneeRemove,
  assigneeIsLoading,
  loadingAssigneeId,
  teamIsLoading,
  loadingTeamId,
}) => {
  const [addAssignee, setAddAssignee] = useState(false);
  const [moreAssignee, setMoreAssignee] = useState(false);
  const wrapperRef1 = useRef(null);
  const wrapperRef2 = useRef(null);
  const moreRef1 = useRef(null);
  const moreRef2 = useRef(null);
  const [addList, setAddList] = useState([]);
  const [addTeamList, setAddTeamList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      let newArr = membersList;
      if (assigneeManage && assigneeManage.length > 0) {
        assigneeManage.map((assignee) => {
          newArr = newArr.filter((item) => item.id !== assignee.id);
        });
      }
      setAddList(newArr);
    } else if (membersList && membersList.length === 0) {
      setAddList([]);
    }
    if (assigneeManage && assigneeManage.length < 4) {
      setMoreAssignee(false);
    }
  }, [membersList, assigneeManage]);

  useEffect(() => {
    if (teamList?.length > 0) {
      const filteredTeams = teamList.filter((team) => team.member_count > 0);
      setAddTeamList(filteredTeams);
    } else if (teamList?.length === 0) {
      setAddTeamList([]);
    }
  }, [teamList]);

  useEffect(() => {
    if (
      (!membersList || membersList.length === 0) &&
      (!teamList || teamList.length === 0)
    ) {
      return;
    }

    let filteredMembers = membersList || [];
    let filteredTeams = teamList.filter((team) => team.member_count > 0) || [];

    if (searchTerm && searchTerm.trim() !== "") {
      const lowerCaseSearchTerm = searchTerm.toLowerCase().trim();

      filteredMembers = filteredMembers.filter((member) =>
        member.name.toLowerCase().trim().includes(lowerCaseSearchTerm)
      );

      filteredTeams = filteredTeams.filter((team) =>
        team.name.toLowerCase().trim().includes(lowerCaseSearchTerm)
      );
    }

    if (assigneeManage) {
      filteredMembers = filteredMembers.filter(
        (member) => member.id !== assigneeManage.id
      );
    }

    setAddList(filteredMembers);
    setAddTeamList(filteredTeams);
  }, [searchTerm]);

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  //function
  useOutsideClickHandler(wrapperRef1, wrapperRef2, moreRef1, moreRef2);

  const showAssignee = () => {
    if (selectedOrganization && selectedOrganization.id) {
      let payload = {
        organization_id: selectedOrganization.id,
      };
      getMembersList(payload);
      getTeamList(payload);
    }
    setAddAssignee(true);
  };

  const showMoreAssignee = () => {
    setMoreAssignee(true);
  };

  const updateAssingeeList = (person) => {
    setAddList(addList.filter((item) => item.id !== person.id));
  };

  function useOutsideClickHandler(ref1, ref2, moreRef1, moreRef2) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref1.current &&
          !ref1.current.contains(event.target) &&
          ref2.current &&
          !ref2.current.contains(event.target)
        ) {
          setAddAssignee(false);
          visibilityChange(false);
          setSearchTerm("");
        }
        if (
          moreRef1.current &&
          !moreRef1.current.contains(event.target) &&
          moreRef2.current &&
          !moreRef2.current.contains(event.target)
        ) {
          setMoreAssignee(false);
          visibilityChange(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref1, ref2, addAssignee, moreRef1, moreRef2, moreAssignee]);
  }
  return (
    <AssigneeRoundImageSection
      position={assigneeManage.length}
      style={{ marginRight: `auto` }}
    >
      <AssigneeRoundImage
        index={0}
        onClick={() => {
          showAssignee();
          visibilityChange(true);
        }}
        ref={wrapperRef2}
      >
        <img src={Assign} alt="assign" />
      </AssigneeRoundImage>
      {assigneeManage.length > 3 && (
        <AssigneeRoundImage
          index="more"
          onClick={() => {
            showMoreAssignee();
            visibilityChange(true);
          }}
          ref={moreRef2}
          style={{
            border: `1px solid #fff`,
            paddingLeft: "3px",
          }}
        >
          {`+${assigneeManage.length - 3}`}
        </AssigneeRoundImage>
      )}
      {assigneeManage
        .filter((person, fi) => fi < 3)
        .map((filteredPerson, i) => (
          <AssigneeRoundImage
            key={i}
            index={assigneeManage.length < 4 ? i + 1 : i + 2}
          >
            {/* <AssigneeTooltip toolTipText={filteredPerson.name} /> */}
            {filteredPerson.avatar ? (
              <RoundPersonImage src={filteredPerson.avatar} alt="addPeople" />
            ) : (
              <FirstRoundLetterComp
                text={filteredPerson.name}
                backColor={i}
                color={filteredPerson.color || null}
              />
            )}

            <RoundImageCross onClick={() => assigneeRemove(filteredPerson.id)}>
              <img
                src={removeAssignee}
                style={{ width: "100%", height: "100%" }}
              />
            </RoundImageCross>
          </AssigneeRoundImage>
        ))}
      {moreAssignee && (
        <ShowMoreAssigneeList ref={moreRef1}>
          {assigneeManage.map((member, index) => (
            <Fragment key={index}>
              <AssigneeRoundImage index={0} size="30px">
                {member.avatar ? (
                  <RoundPersonImage
                    src={member.avatar}
                    alt=""
                    size="30px"
                    borderColor="#c2cce1"
                  />
                ) : (
                  <FirstRoundLetterComp
                    size="30px"
                    text={member.name}
                    backColor={index}
                    fontSize="12px"
                    color={member.color || null}
                  />
                )}

                <RoundImageCross onClick={() => assigneeRemove(member.id)}>
                  <img
                    src={removeAssignee}
                    style={{ width: "100%", height: "100%" }}
                  />
                </RoundImageCross>
              </AssigneeRoundImage>
              <AssignListText>{member.name}</AssignListText>
            </Fragment>
          ))}
        </ShowMoreAssigneeList>
      )}
      {addAssignee && (
        <AssignListContainer ref={wrapperRef1}>
          <SearchBarWithAssignListContainer>
            <AssigneeSearchBarWrapper>
              <AssigneeSearchBarContainer>
                <AssigneeSearchIcon src={searchIcon} alt="" />
                <AssigneeSearchInput
                  value={searchTerm}
                  onChange={(e) => onSearchTermChange(e)}
                  placeholder="Search member..."
                />
              </AssigneeSearchBarContainer>
            </AssigneeSearchBarWrapper>

            {addTeamList && addTeamList.length > 0 && (
              <>
                <ShowAssigneeTitleComp text="Teams" />
                {addTeamList.map((team, index) => {
                  return teamIsLoading &&
                    loadingTeamId &&
                    loadingTeamId === team.id ? (
                    <AssignListItem columns="100%" key={index}>
                      <DropdownLoader loading />
                    </AssignListItem>
                  ) : (
                    <AssignListItem
                      key={index}
                      onClick={() => {
                        updateState(team, "team");
                      }}
                      columns="30px auto"
                      padding="7px 8px"
                    >
                      <FirstRoundLetterComp
                        text={team.name}
                        backColor={index}
                        size="30px"
                        fontSize="12px"
                        color={team.color || null}
                      />
                      <AssignListText>{team.name}</AssignListText>
                    </AssignListItem>
                  );
                })}
              </>
            )}
            {addList && addList.length > 0 && (
              <>
                <ShowAssigneeTitleComp text="People" />
                {addList.map((person, i) => {
                  return assigneeIsLoading &&
                    loadingAssigneeId &&
                    loadingAssigneeId === person.id ? (
                    <AssignListItem columns="100%" key={i}>
                      <DropdownLoader loading />
                    </AssignListItem>
                  ) : (
                    <AssignListItem
                      key={i}
                      onClick={() => {
                        updateState(person, "person");
                        setSearchTerm("");
                      }}
                      columns="30px auto"
                      padding="7px 8px"
                    >
                      <AssigneeRoundImage index={0} size="30px">
                        {person.avatar ? (
                          <RoundPersonImage
                            src={person.avatar}
                            alt="addPeople"
                            size="30px"
                            borderColor="#c2cce1"
                          />
                        ) : (
                          <FirstRoundLetterComp
                            size="30px"
                            fontSize="12px"
                            text={person.name}
                            backColor={i}
                            color={person.color || null}
                          />
                        )}
                      </AssigneeRoundImage>
                      <AssignListText>{person.name}</AssignListText>
                    </AssignListItem>
                  );
                })}
              </>
            )}
          </SearchBarWithAssignListContainer>
        </AssignListContainer>
      )}
    </AssigneeRoundImageSection>
  );
};
