import { useState, useEffect } from "react";

import ActivityFilter from "../activityFilterContainer";
import UserActivityInfo from "./UserActivityInfo";
import CardsList from "./CardsList";

import {
  PageTitle,
  FilterLabel,
  CommonGrid,
  CommonImage,
  CommonFlex,
  InputWithLabelSection,
} from "../../../styledComponents/common";

import { PrimaryButton } from "../../../styledComponents/buttons";

import { ShowNotesModal } from "./ScreenshotsModals";

import Select from "../../../components/ReactSelectDropdown";
import createProjectDropdown from "../../../components/DropdownStyle/CreateProjectDropdown";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import SettingsDisabled from "../../../components/SettingsDisabled/SettingsDisabled";
import alertIcon from "../../../assets/img/icons/alert-grey.svg";
import CommonTooltip from "../../../components/Tooltip/CommonTooltip";

const ActivityScreenshots = (props) => {
  const [fetchAllNotes, setFetchAllNotes] = useState(false);
  const [isAllNotesModalOpen, setIsAllNotesModalOpen] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [sortType, setSortType] = useState({
    label: "Time (Old to new)",
    value: "OldToNew",
  });
  const sortOptions = [
    { label: "Time (Old to new)", value: "OldToNew" },
    { label: "Time (New to old)", value: "NewToOld" },
  ];
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      if (props.selectedOrganization.timezone) {
        setSelectedTimezone({
          type: "Organization",
          timezone: props.selectedOrganization.timezone,
          offset: props.selectedOrganization.timezoneoffset,
        });
      }
    }
  }, [props.selectedOrganization]);

  useEffect(() => {}, [props.allNotes]);
  const changeSortType = (type) => {
    setSortType(type);
  };
  const handleAllNotesClick = () => {
    setFetchAllNotes(true);
    setIsAllNotesModalOpen(true);
  };
  const toggleNotesModal = () => {
    setIsAllNotesModalOpen(!isAllNotesModalOpen);
  };
  return (
    <div className="content">
      <ShowNotesModal
        isOpen={isAllNotesModalOpen}
        toggle={toggleNotesModal}
        notes={props.allNotes}
        notesType="all"
        user={props.userActivity && props.userActivity.user}
        isLoading={props.allNotesIsLoading}
      />
      <CommonGrid alignItem="center">
        <CommonFlex gap="5px">
          <PageTitle>Screenshots</PageTitle>
          {/* {!(
            props.selectedOrganization?.configuration?.mouse_track ||
            props.selectedOrganization?.configuration?.keystroke_track
          ) && (
            <div style={{ position: "relative" }}>
              <CommonImage
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                src={alertIcon}
                alt=""
                size="20px"
              />
              {isHovered && (
                <CommonTooltip
                  style={{ position: "absolute" }}
                  tooltipText="Activity tracking is turned off."
                  left="-100px"
                  top="-35px"
                  width="220px"
                  height="25px"
                  padding="7px 10px"
                />
              )}
            </div>
          )} */}
        </CommonFlex>

        <CommonGrid columns="200px auto" alignItem="center">
          <InputWithLabelSection style={{ minWidth: "200px" }}>
            <FilterLabel>Sort By</FilterLabel>
            <Select
              isSearchable={false}
              value={sortType}
              options={sortOptions}
              onChange={(e) => {
                changeSortType(e);
              }}
              styles={createProjectDropdown(null)}
            />
          </InputWithLabelSection>
          <InputWithLabelSection>
            <div style={{ height: "18px" }} />
            <PrimaryButton onClick={() => handleAllNotesClick()} height="40px">
              All Notes
            </PrimaryButton>
          </InputWithLabelSection>
        </CommonGrid>
      </CommonGrid>
      <ActivityFilter
        title="Screenshots"
        sortType={sortType}
        changeSortType={changeSortType}
        fetchAllNotes={fetchAllNotes}
        setFetchAllNotes={setFetchAllNotes}
        setScreenshotMemberId={setSelectedMemberId}
      />
      {props.isLoading ? (
        <ComponentCircleLoader />
      ) : (
        props.selectedOrganization?.configuration && (
          <>
            {props.screenshots?.length > 0 ? (
              <UserActivityInfo
                userActivity={props.userActivity}
                selectedOrganization={props.selectedOrganization}
                clockInOutList={props.clockInOutList}
              />
            ) : null}

            {props.selectedOrganization.configuration.screenshot_track ? (
              <CardsList
                selectedOrganization={props.selectedOrganization}
                selectedMemberId={selectedMemberId}
                user={props.userActivity && props.userActivity.user}
                screenshots={props.screenshots}
                screenshotBlur={
                  props.selectedOrganization &&
                  props.selectedOrganization.configuration &&
                  props.selectedOrganization.configuration.screenshot_blur
                }
                sortType={sortType}
                addNotes={props.addNotes}
                deleteScreenshot={props.deleteScreenshot}
                allNotesIsLoading={props.allNotesIsLoading}
                addNoteIsLoading={props.addNoteIsLoading}
                deleteIsLoading={props.deleteIsLoading}
              />
            ) : (
              <SettingsDisabled feature={`'Screenshot Tracking'`} />
            )}
          </>
        )
      )}
    </div>
  );
};

export default ActivityScreenshots;
