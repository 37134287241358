import { useState, useEffect } from "react";
import moment from "moment";

import Select from "../../../components/ReactSelectDropdown";
import MembersDropdownStyle from "../../../components/DropdownStyle/MembersDropdownStyle";
import AddSingleMemberDropdown from "../../../components/CustomDropdown/AddSingleMemberDropdown";
import ReactDateRangePicker from "../../../components/ReactDateRangePicker/ReactDateRangePicker";

import {
  InputWithLabelSection,
  FilterLabel,
} from "../../../styledComponents/common";
import { FilterContainer } from "../timesheetApprovalStyles";

import { getDateLabel } from "../../../utils/helper";

const ApprovalFilter = (props) => {
  const [memberOptions, setMemberOptions] = useState([]);
  const sortOptions = [
    { value: "oldToNew", label: "Date (Old to New)" },
    { value: "newToOld", label: "Date (New to Old)" },
  ];

  const {
    selectedTab,
    selectedMember,
    setSelectedMember,
    startDate,
    endDate,
    onDateSelect,
    changeDateRange,
    selectLastWeek,
    selectLastMonth,
    selectedOrganization,
    getOrganizationMembersList,
    organizationMembersList,
    sortType,
    changeSortType,
    getTimesheetApprovalAwaitingList,
    getTimesheetApprovalHistory,
    disableCurrentAndFutureDates,

    // pagination
    paginationSize,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      const memberListPayload = { organization_id: selectedOrganization.id };
      getOrganizationMembersList(memberListPayload);

      const payload = {
        organization_id: selectedOrganization.id,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        offset: 0,
        limit: paginationSize,
      };
      if (selectedTab === "awaiting") {
        getTimesheetApprovalAwaitingList(payload);
      } else {
        getTimesheetApprovalHistory(payload);
      }
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (organizationMembersList && organizationMembersList.length > 0) {
      setMemberOptions(organizationMembersList);
    } else if (
      organizationMembersList &&
      organizationMembersList.length === 0
    ) {
      setMemberOptions([]);
    }
  }, [organizationMembersList]);

  const onMemberSelect = (member) => {
    if (member && member.id) {
      if (selectedOrganization && selectedOrganization.id) {
        const payload = {
          organization_id: selectedOrganization.id,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
          user_id: member.id,
        };

        if (selectedTab === "awaiting") {
          getTimesheetApprovalAwaitingList(payload);
        } else {
          getTimesheetApprovalHistory(payload);
        }
        setSelectedMember(member);
      }
    }
  };

  const clearSelectedMember = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        offset: 0,
        limit: paginationSize,
      };
      if (selectedTab === "awaiting") {
        getTimesheetApprovalAwaitingList(payload);
      } else {
        getTimesheetApprovalHistory(payload);
      }
      setSelectedMember(null);
    }
  };

  return (
    <FilterContainer
      columns={
        selectedOrganization &&
        selectedOrganization.role === "member" &&
        "280px 230px"
      }
    >
      {selectedOrganization && selectedOrganization.role !== "member" && (
        <InputWithLabelSection>
          <FilterLabel>Member</FilterLabel>
          <AddSingleMemberDropdown
            assigneeManage={selectedMember}
            updateState={onMemberSelect}
            membersList={memberOptions}
            clearAssignee={() => clearSelectedMember()}
          />
        </InputWithLabelSection>
      )}

      <InputWithLabelSection>
        <FilterLabel>
          Date Range{getDateLabel({ startDate, endDate })}
        </FilterLabel>
        <ReactDateRangePicker
          startDate={startDate}
          startDateId="custom-date-range-start-date"
          endDate={endDate}
          endDateId="custom-date-range-end-date"
          dateDisplayFormat="DD/MM/YYYY"
          onDateSelect={onDateSelect}
          onDatesChange={changeDateRange}
          selectLastWeek={selectLastWeek}
          selectLastMonth={selectLastMonth}
          disableCurrentAndFutureDates={disableCurrentAndFutureDates}
        />
      </InputWithLabelSection>
      {/* <InputWithLabelSection>
        <FilterLabel>Sort By</FilterLabel>
        <Select
          isSearchable={false}
          value={sortType}
          options={sortOptions}
          onChange={(e) => {
            changeSortType(e);
          }}
          styles={MembersDropdownStyle({ minHeight: "40px" })}
        />
      </InputWithLabelSection> */}
    </FilterContainer>
  );
};

export default ApprovalFilter;
