import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components";

import {
  ButtonIcon,
  ColoredButtonWithIcon,
} from "../../styledComponents/buttons";
import {
  CardTitle,
  CloseButton,
  CloseImg,
  CommonText,
} from "../../styledComponents/common";
import { ModalButtonSection } from "../../styledComponents/members";

import Animation from "../../components/Animation/Animation";

import crown from "../../assets/img/common/crown.svg";
import CrossIcon from "../../assets/img/icons/cross_black.svg";

// lottie animations
import alertRed from "../../assets/animations/alert-red.lottie";

const SubscriptionModalHeader = styled(ModalHeader)`
  text-align: center;
  display: flex;
  flex-direction: row;
  border: none;
  padding-bottom: 0;
`;

const SubscriptionModalBody = styled(ModalBody)`
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SubscriptionCancellationModal = ({ isOpen, toggle, handleUpgrade }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: `25vh` }}>
      <SubscriptionModalHeader>
        <CardTitle fontSize="18px">Renew Subscription</CardTitle>
        <CloseButton onClick={toggle}>
          <CloseImg src={CrossIcon} alt="" />
        </CloseButton>
      </SubscriptionModalHeader>

      <SubscriptionModalBody>
        <Animation src={alertRed} />
        <CommonText margin="10px 0 0">
          Your current subscription has been <strong>"Cancelled"</strong>.<br />{" "}
          To invite new members, please renew your subscription.
        </CommonText>
        <ModalButtonSection>
          <ColoredButtonWithIcon
            onClick={handleUpgrade}
            width="160px"
            fontSize="16px"
            height="50px"
          >
            <ButtonIcon src={crown} alt="" size="17px" />
            UPGRADE
          </ColoredButtonWithIcon>
        </ModalButtonSection>
      </SubscriptionModalBody>
    </Modal>
  );
};

export default SubscriptionCancellationModal;
