import { useState, useEffect, useRef } from "react";
import queryString from "query-string";
import moment from "moment";
import { Line } from "react-chartjs-2";
import Select from "../../../components/ReactSelectDropdown";
import FirstRoundLetterComp from "../../../components/FirstRoundLetter/FirstRoundLetter";
import CreateProjectDropdown from "../../../components/DropdownStyle/CreateProjectDropdown";
import RoundImageNameComp from "../../../components/RoundImageName/RoundImageNameComp";
import TaskList from "../TaskList";
import { DetailsAssigneeSectionContainer } from "../../../styledComponents/createProject";
import {
  ProjectDetailsContainer,
  BudgetLeftSection,
  ProjectCardLabel,
  ProjectCardText,
  PercentageBarSection,
  PercentageText,
  EditProjectButton,
  ProjectStatusBadge,
  DeadlineText,
} from "../projectsStyles";
import {
  ProjectRightButtonContainer,
  GrayButton,
  ProjectListSection,
  ListUpperSection,
  ClientDeadlineGrid,
  ProjectItemCount,
  ProjectTitleDiv,
  ActionSection,
  DetailTitleSelectSection,
  ChartContainer,
  ViewLessCardContainer,
  ViewLessCard,
  CardTextData,
  LinearProgressBarTrack,
  LinearProgressBarTrain,
  InvoiceSectionContainer,
  InvoiceSectionTitle,
  InvoiceTableContainer,
  InvoiceTableItem,
  NoDataGraphContainer,
  NoDataGraph,
  NoDataText,
} from "../../../styledComponents/ProjectsNew";
import {
  PageTitle,
  HeaderContainer,
  Container,
  ContentHeader,
  CommonText,
  TableText,
  CommonFlex,
} from "../../../styledComponents/common";
import {
  ColoredButtonWithIcon,
  ButtonIcon,
  ColoredButton,
  TabButtonContainer,
  TabButton,
} from "../../../styledComponents/buttons";
import { StatusBox } from "../../../styledComponents/invoice";
import { ProjectDetailsInvoiceTaskContainer } from "../projectsStyles";
import {
  IntegrationIconDiv,
  IntegrationIcon,
} from "../../../styledComponents/tasks";

import InvoiceSection from "./InvoiceSection";
import TaskSection from "./TaskSection";

import noDataGraph from "../../../assets/img/common/no-data-graph.svg";

import LinearActivityBar from "../../../components/ProgressBar/LinearActivityBar";
import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import AccessDeniedComponent from "../../../components/AccessDenied/AccessDenied";
import { ProjectCardNoData } from "../ViewLess";
import ProjectArchiveModal from "../ProjectArchiveModal";
import ProjectDeleteModal from "../ProjectDeleteModal";
import DetailsAssigneeSection from "./DetailsAssigneeSection";
import AssignButtonLoader from "../../../components/AssignButtonLoader/AssignButtonLoader";
import CommonTooltip from "../../../components/Tooltip/CommonTooltip";

import { currencySymbolMap } from "../../../utils/currencies";

import { projectChartOptions } from "../../../utils/chartOptions";
import { createNotification } from "../../../modules/notificationManager";
import { getFullName } from "../../../utils/helper";

const getDates = (startDate, endDate) => {
  const dates = [];
  while (moment(startDate).isSameOrBefore(endDate)) {
    dates.push(startDate.format("YYYY-MM-DD"));
    startDate = moment(startDate).add(1, "days");
  }
  return dates;
};

const getMonths = (startDate, endDate) => {
  const months = [];
  while (
    moment(endDate) > moment(startDate) ||
    startDate.format("M") === endDate.format("M")
  ) {
    months.push(startDate.format("YYYY-MM"));
    startDate.add(1, "month");
  }
  return months;
};

const getHoursLabel = (time) => {
  const hours = time.split(":");
  return `${hours[0]}h ${hours[1]}m`;
};

const initialData = {
  labels: getDates(moment(new Date()).subtract(6, "days"), moment(new Date())),
  datasets: [
    {
      label: "Cost",
      fill: true,
      lineTension: 0.5,
      backgroundColor: "#c6e6e3",
      borderColor: "#20bead",
      borderWidth: 2,
      pointBorderColor: "#20bead",
      pointBackgroundColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#20bead",
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHoverRadius: 5,
      data: [24, 35, 40, 15, 20, 23, 14],
    },
  ],
};

const LinearProgressBar = ({ percent }) => (
  <LinearProgressBarTrack percent={percent}>
    <LinearProgressBarTrain />
  </LinearProgressBarTrack>
);

const NoDataGraphSection = ({ graphType, graphDuration }) => {
  const [text, setText] = useState("No data found for this project!");

  useEffect(() => {
    if (graphType && graphDuration) {
      if (graphType === "time-spent") {
        setText(
          `Seems no time is spent in this project in last ${graphDuration}!`
        );
      } else if (graphType === "cost") {
        setText(`No expenses found in this project in last ${graphDuration}!`);
      }
    }
  }, [graphType, graphDuration]);
  return (
    <NoDataGraphContainer>
      <NoDataGraph src={noDataGraph} alt="" />
      <NoDataText>{text}</NoDataText>
    </NoDataGraphContainer>
  );
};

const ViewDetails = ({
  isLoading,
  location,
  history,
  projectDetails,
  projectAssigneeIsLoading,
  selectedOrganization,
  clearProjectAccessDenied,
  getProjectDetails,
  getProjectExpenses,
  projectExpenses,
  getProjectAssigneeList,
  projectAssigneeList,
  getProjectTaskList,
  projectTaskList,
  projectAddTask,
  projectEditTask,
  projectDeleteTask,
  addTaskAssignee,
  deleteTaskAssignee,
  taskListLoading,
  taskIsLoading,
  loadingTaskId,
  taskAssigneeIsLoading,
  loadingTaskAssigneeId,
  getProjectInvoices,
  invoicesList,

  // pagination
  projectTaskCount,
  projectTaskPageSize,
  projectInvoiceCount,
  projectInvoicePageSize,

  projectInvoiceListLoading,
  yearlyExpenses,
  projectExpensesIsLoading,
  accessDenied,
  deleteProjectIsLoading,
  updateProjectIsLoading,
  getProjectYearlyExpenses,
  deleteProject,
  updateProject,
}) => {
  const [projectId, setProjectId] = useState("");
  const [hasAdminPrivilege, setHasAdminPrivilege] = useState(false);
  const [isProjectCreator, setIsProjectCreator] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [projectType, setProjectType] = useState("");
  const [projectArchiveId, setProjectArchiveId] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [projectDeleteId, setProjectDeleteId] = useState("");
  const [timeSpentData, setTimeSpentData] = useState([]);
  const [costData, setCostData] = useState([]);
  const [graphType, setGraphType] = useState("time-spent");
  const [graphDuration, setGraphDuration] = useState("week");
  const [graphData, setGraphData] = useState(initialData);
  const [hoursPercentage, setHoursPercentage] = useState(0);
  const [costPercentage, setCostPercentage] = useState(0);
  const [budgetLeftPercentage, setBudgetLeftPercentage] = useState(0);
  const [assigneeList, setAssigneeList] = useState([]);
  const [currency, setCurrency] = useState("");

  const [detailsLoading, setDetailsLoading] = useState(true);
  const [hasEditAccess, setHasEditAccess] = useState(false);

  const [selectedTab, setSelectedTab] = useState("tasks");

  const [integrationTooltip, setIntegrationTooltip] = useState(null);

  const taskInvoiceRef = useRef(null);

  const [firstLoad, setFirstLoad] = useState(true);

  const [projectArchiveLoading, setProjectArchiveLoading] = useState(false);

  const actionOptions = [
    { value: "edit", label: "Edit Project" },
    { value: "archive", label: "Archive Project" },
    //{ value: "3", label: "Delete Project" },
  ];
  const [timeDurationValue, setTimeDurationValue] = useState({
    value: 1,
    label: "Last 7 Days",
  });
  const [graphTypeValue, setGraphTypeValue] = useState({
    value: 2,
    label: "See by Time Spent",
  });
  const graphDurationOptions = [
    { value: 1, label: "Last 7 Days" },
    { value: 2, label: "Last Month" },
    { value: 3, label: "Last Year" },
  ];
  const graphTypeOptions = [
    { value: 1, label: "See by Cost" },
    { value: 2, label: "See by Time Spent" },
  ];

  useEffect(() => {
    return () => {
      clearProjectAccessDenied();
    };
  }, []);

  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.configuration &&
      selectedOrganization.configuration.currency
    ) {
      setCurrency(selectedOrganization.configuration.currency);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (!isLoading) {
      if (
        projectDetails === null &&
        !firstLoad &&
        accessDenied &&
        selectedOrganization.role === "member"
      ) {
        createNotification("error", "Access Denied!");
        history.push("/user/projects");
      }
      if (firstLoad) {
        setFirstLoad(false);
      }
      setDetailsLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (projectAssigneeList && projectAssigneeList.length > 0) {
      const userId = localStorage.getItem("user_id");
      let editAccess = false;
      const list = [];
      projectAssigneeList.map((assignee) => {
        if (userId && assignee.user.id === userId && assignee.can_manage) {
          editAccess = true;
        }
        list.push({
          id: assignee.user.id,
          name: getFullName(assignee.user),
          manageProject: assignee.can_manage,
          viewReport: assignee.can_view_report,
          avatar: assignee.user.avatar || null,
          color: assignee.user.color || null,
        });
      });
      setAssigneeList(list);
      setHasEditAccess(editAccess);
    } else if (projectAssigneeList && projectAssigneeList.length === 0) {
      setAssigneeList([]);
    }
  }, [projectAssigneeList]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      if (location.search) {
        const queryParsed = queryString.parse(location.search);
        setProjectId(queryParsed.id);
        if (queryParsed.id) {
          const payload = {
            organization_id: selectedOrganization.id,
            project_id: queryParsed.id,
          };
          // getProjectInvoices(payload);
          getProjectAssigneeList(payload);
          getProjectDetails(payload);
          getProjectTaskList({ ...payload, status: "to do" });
          payload.start_date = moment()
            .subtract(6, "days")
            .format("YYYY-MM-DD");
          payload.end_date = moment().format("YYYY-MM-DD");
          getProjectExpenses(payload);
        }

        if (queryParsed && queryParsed.type) {
          setProjectType(queryParsed.type);
        }
      }
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (projectDetails && projectDetails.budget) {
      if (projectDetails.budget.budget_type === "project_fees") {
        if (projectDetails.budget.amount) {
          if (projectDetails.budget.budget_left !== null) {
            let leftBudget = (
              (projectDetails.budget.budget_left /
                projectDetails.budget.amount) *
              100
            ).toFixed(1);
            setBudgetLeftPercentage(leftBudget);
          }
          if (projectDetails.budget.cost) {
            let cost = (
              (projectDetails.budget.cost / projectDetails.budget.amount) *
              100
            ).toFixed(1);
            setCostPercentage(
              projectDetails.budget.cost > projectDetails.budget.amount
                ? 100
                : cost
            );
          } else if (!projectDetails.budget.cost) {
            setCostPercentage(0);
          }
        }
      } else if (projectDetails.budget.budget_type === "project_hours") {
        if (projectDetails.budget.amount) {
          if (projectDetails.budget.budget_left !== null) {
            let leftBudget = (
              (projectDetails.budget.budget_left /
                projectDetails.budget.amount) *
              100
            ).toFixed(1);
            setBudgetLeftPercentage(leftBudget);
          }
          if (projectDetails.budget.spent_hours) {
            let hourPercentage = (
              (getTimeInHours(projectDetails.budget.spent_hours) /
                projectDetails.budget.amount) *
              100
            ).toFixed(1);
            setHoursPercentage(
              parseInt(projectDetails.budget.spent_hours.split(":")[0]) >
                projectDetails.budget.amount
                ? 100
                : hourPercentage
            );
          }
        }
      }
    }
    if (projectDetails) {
      if (
        selectedOrganization.role === "owner" ||
        selectedOrganization.role === "admin"
      ) {
        setHasAdminPrivilege(true);
      } else setHasAdminPrivilege(false);
      if (projectDetails.created_by === localStorage.getItem("user_id")) {
        setIsProjectCreator(true);
      } else {
        setIsProjectCreator(false);
      }
    }
  }, [projectDetails]);

  useEffect(() => {
    if (projectExpenses && projectExpenses.length > 0) {
      let costsList = [];
      let timeSpentList = [];
      let dates = getDates(
        moment().subtract(graphDuration === "week" ? 6 : 29, "days"),
        moment()
      );

      dates.map((date) => {
        let costItem = {
          date,
          cost: 0,
        };
        let timeSpentItem = {
          date,
          timeSpent: 0,
        };
        projectExpenses.map((item) => {
          if (date === item.date) {
            if (item.expense) {
              costItem.cost =
                costItem.cost > 0
                  ? (costItem.cost += item.expense)
                  : item.expense;
            }
            if (item.duration) {
              timeSpentItem.timeSpent = timeSpentItem.timeSpent
                ? (timeSpentItem.timeSpent += item.duration)
                : item.duration;
            }
          }
        });
        if (projectDetails && projectDetails.budget) {
          costsList.push(costItem);
        }
        timeSpentList.push(timeSpentItem);
      });
      setCostData([...costsList]);
      setTimeSpentData([...timeSpentList]);
    } else if (projectExpenses && projectExpenses.length === 0) {
      setCostData([]);
      setTimeSpentData([]);
    }
  }, [projectExpenses]);

  useEffect(() => {
    if (yearlyExpenses && yearlyExpenses.length > 0) {
      let costsList = [];
      let timeSpentList = [];
      let months = getMonths(moment().subtract(11, "months"), moment());

      months.map((month) => {
        let costItem = {
          date: month,
          cost: 0,
        };
        let timeSpentItem = {
          date: month,
          timeSpent: 0,
        };
        yearlyExpenses.map((item) => {
          if (month === moment(item.month).format("YYYY-MM")) {
            if (item.expense) {
              costItem.cost =
                costItem.cost > 0
                  ? (costItem.cost += item.expense)
                  : item.expense;
            }
            if (item.duration) {
              timeSpentItem.timeSpent = timeSpentItem.timeSpent
                ? (timeSpentItem.timeSpent += item.duration)
                : item.duration;
            }
          }
        });
        if (projectDetails && projectDetails.budget) {
          costsList.push(costItem);
        }
        timeSpentList.push(timeSpentItem);
      });
      setCostData([...costsList]);
      setTimeSpentData([...timeSpentList]);
    } else if (yearlyExpenses && yearlyExpenses.length === 0) {
      setCostData([]);
      setTimeSpentData([]);
    }
  }, [yearlyExpenses]);

  useEffect(() => {
    if (costData && costData.length > 0) {
      if (graphType && graphType === "cost") {
        let data = JSON.parse(JSON.stringify(initialData));
        const labels = [];
        const values = [];
        costData.map((cost) => {
          labels.push(
            moment(cost.date).format(
              graphDuration === "year" ? "MMMM, YYYY" : "DD/MM/YYYY"
            )
          );
          values.push((cost.cost / 100).toFixed(2));
        });
        data.datasets[0].label = "Cost";
        data.labels = [...labels];
        data.datasets[0].data = [...values];
        setGraphData(data);
      }
    }
  }, [costData, graphType]);

  useEffect(() => {
    if (timeSpentData && timeSpentData.length > 0) {
      if (graphType && graphType === "time-spent") {
        let data = JSON.parse(JSON.stringify(initialData));
        const labels = [];
        const values = [];
        timeSpentData.map((time) => {
          labels.push(
            moment(time.date).format(
              graphDuration === "year" ? "MMMM, YYYY" : "DD/MM/YYYY"
            )
          );
          values.push((time.timeSpent / 3600).toFixed(2));
        });
        data.datasets[0].label = "Time Spent";
        data.labels = [...labels];
        data.datasets[0].data = [...values];
        setGraphData(data);
      }
    }
  }, [timeSpentData, graphType]);

  // useEffect(() => {
  //   if (invoicesList) {
  //     if (invoicesList && invoicesList.length > 0) {
  //       setInvoices(invoicesList);
  //     } else if (invoicesList && invoicesList.length === 0) {
  //       setInvoices([]);
  //     }
  //   }
  // }, [invoicesList]);

  const handleArchiveProject = (action) => {
    if (selectedOrganization && selectedOrganization.id && projectArchiveId) {
      const payload = {
        organization_id: selectedOrganization.id,
        project_id: projectArchiveId,
        getAllProjectList: true,
        type: "active",
      };
      if (action === "archive") {
        payload.is_active = false;
      } else if (action === "unarchive") {
        payload.is_active = true;
      }
      updateProject(payload);
      setProjectArchiveLoading(true);
      // setTimeout(() => {
      //   setIsArchiveModalOpen(!isArchiveModalOpen);
      //   history.push("/user/projects");
      // }, 600);
    }
  };

  useEffect(() => {
    if (projectArchiveLoading && !updateProjectIsLoading) {
      setIsArchiveModalOpen(!isArchiveModalOpen);
      history.push("/user/projects");
    }
  }, [projectArchiveLoading, updateProjectIsLoading]);

  const handleDeleteProject = () => {
    if (selectedOrganization && selectedOrganization.id && projectDeleteId) {
      const payload = {
        organization_id: selectedOrganization.id,
        project_id: projectDeleteId,
      };
      deleteProject(payload);
      setTimeout(() => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
        history.push("/user/projects");
      }, 300);
    }
  };

  const toggleArchiveModal = (projectId) => {
    if (!isArchiveModalOpen) {
      setProjectArchiveId(projectId);
    }
    setIsArchiveModalOpen(!isArchiveModalOpen);
  };

  const toggleDeleteModal = (projectId) => {
    if (!isDeleteModalOpen) {
      setProjectDeleteId(projectId);
    }
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleSelectTab = (tab) => {
    setSelectedTab(tab);
    if (selectedOrganization && selectedOrganization.id && projectId) {
      if (tab === "invoices") {
        const payload = {
          organization_id: selectedOrganization.id,
          project_id: projectId,
        };
        getProjectInvoices(payload);
        setTimeout(() => {
          taskInvoiceRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }, 400);
      }
    }
  };

  const onActionChange = (e) => {
    if (projectId) {
      if (e.value === "edit") {
        history.push(`/user/edit-project?id=${projectId}`);
      }
      if (e.value === "archive" || e.value === "unarchive") {
        toggleArchiveModal(projectId);
      }
    }
  };
  const onGraphDurationChange = (e) => {
    if (selectedOrganization && selectedOrganization.id && projectId) {
      let payload = {
        organization_id: selectedOrganization.id,
        project_id: projectId,
        start_date: moment().subtract(6, "days").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      };
      setTimeDurationValue(e);
      if (e.value === 1) {
        setGraphDuration("week");
        getProjectExpenses(payload);
      } else if (e.value === 2) {
        payload.start_date = moment().subtract(29, "days").format("YYYY-MM-DD");
        setGraphDuration("month");
        getProjectExpenses(payload);
      } else if (e.value === 3) {
        payload.start_date = moment()
          .subtract(11, "months")
          .format("YYYY-MM-DD");
        payload.end_date = moment().format("YYYY-MM-DD");
        setGraphDuration("year");
        getProjectYearlyExpenses(payload);
      }
    }
  };
  const onGraphTypeChange = (e) => {
    setGraphTypeValue(e);
    if (e.value === 1) {
      setGraphType("cost");
    } else if (e.value === 2) {
      setGraphType("time-spent");
    }
  };

  const getHours = (time) => {
    const hours = time.split(":");
    return `${hours[0]}h ${hours[1]}m`;
  };

  const getTimeInHours = (time) => {
    let splitTime = time.split(":");

    return parseInt(splitTime[0]);
  };

  const getData = (canvas, data) => {
    const chartConfig = JSON.parse(JSON.stringify(data));
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "rgba(32, 190, 173, 1)");
    gradient.addColorStop(0.33, "rgba(32, 190, 173, 0.5)");
    gradient.addColorStop(0.66, "rgba(32, 190, 173, 0.15)");
    gradient.addColorStop(1, "rgba(32, 190, 173, 0)");

    chartConfig.datasets[0].backgroundColor = gradient;

    return {
      ...chartConfig,
    };
  };

  return (
    <div className="content">
      <ProjectArchiveModal
        projectType={projectType}
        isOpen={isArchiveModalOpen}
        toggle={() => setIsArchiveModalOpen(!isArchiveModalOpen)}
        handleArchive={handleArchiveProject}
        isLoading={updateProjectIsLoading}
      />
      <ProjectDeleteModal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        handleDelete={handleDeleteProject}
        isLoading={deleteProjectIsLoading}
      />
      <HeaderContainer alignItems="center">
        <PageTitle style={{ margin: `0` }}>Project Details</PageTitle>
        {/* {selectedOrganization &&
          (selectedOrganization.role === "owner" ||
            selectedOrganization.role === "admin") && (
            <ProjectRightButtonContainer>
            
              <ColoredButtonWithIcon
                onClick={() => history.push("/user/create-project")}
              >
                <ButtonIcon src={Plus} />
                Create Project
              </ColoredButtonWithIcon>
            </ProjectRightButtonContainer>
          )} */}
      </HeaderContainer>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => history.push("/user/projects")}
          subTitle="Back to all projects"
        />
      </ContentHeader>
      <Container padding="30px 0">
        {detailsLoading || (!projectDetails && !accessDenied) ? (
          <ComponentCircleLoader />
        ) : !projectDetails?.can_view_report && !projectDetails?.managed ? (
          <AccessDeniedComponent history={history} />
        ) : (
          <ProjectListSection>
            <ProjectDetailsContainer padding="0 30px">
              <ListUpperSection>
                <div>
                  <ProjectTitleDiv>
                    <CommonFlex gap="14px">
                      {projectDetails && projectDetails.name && (
                        <>
                          <FirstRoundLetterComp
                            color={projectDetails.color || null}
                            text={projectDetails.name}
                          />

                          <CommonText name title fontSize="16px">
                            {projectDetails.name}
                          </CommonText>
                        </>
                      )}
                    </CommonFlex>
                    {projectDetails && projectDetails.code && (
                      <div style={{ position: `relative` }}>
                        <ProjectItemCount>
                          {projectDetails.code}
                        </ProjectItemCount>
                      </div>
                    )}
                    {projectDetails &&
                      projectDetails.integrations &&
                      projectDetails.integrations.length > 0 &&
                      projectDetails.integrations.map((integration, index) => {
                        return (
                          <IntegrationIconDiv
                            key={index}
                            onMouseEnter={() =>
                              setIntegrationTooltip(projectDetails.id)
                            }
                            onMouseLeave={() => setIntegrationTooltip(null)}
                          >
                            <IntegrationIcon
                              src={`https://cdn.apploye.com/integrations/icons/${integration}.svg`}
                              alt={integration}
                              margin="0 0 0 10px"
                              size="22px"
                            />
                            {integrationTooltip &&
                              integrationTooltip === projectDetails.id && (
                                <CommonTooltip
                                  tooltipText={`${integration
                                    .charAt(0)
                                    .toUpperCase()}${integration.slice(1)}`}
                                  left="-20px"
                                  top="-35px"
                                  width="82px"
                                  height="26px"
                                />
                              )}
                          </IntegrationIconDiv>
                        );
                      })}
                  </ProjectTitleDiv>
                  <ClientDeadlineGrid>
                    <ProjectStatusBadge
                      status={projectDetails && projectDetails.status}
                    >
                      {projectDetails && projectDetails.status}
                    </ProjectStatusBadge>
                    <CommonText fontSize="13px" name>
                      Client:{" "}
                      {projectDetails && projectDetails.client
                        ? projectDetails.client.name
                        : "No client"}{" "}
                    </CommonText>
                    <DeadlineText>
                      Deadline:{" "}
                      {projectDetails && (
                        <span style={{ color: `#2F394E`, marginLeft: "3px" }}>
                          {" "}
                          {!projectDetails.deadline
                            ? `No Deadline`
                            : moment(projectDetails.deadline).format(
                                "MMM DD, YYYY"
                              )}
                        </span>
                      )}
                      {projectDetails?.deadline && (
                        <>
                          {moment(projectDetails.deadline)
                            .tz(selectedOrganization.timezone)
                            .endOf("day")
                            .isBefore(
                              moment()
                                .tz(selectedOrganization.timezone)
                                .endOf("day")
                            ) ? (
                            <ProjectStatusBadge
                              bgColor="#ffdce0"
                              textColor="#fe5969"
                              style={{ marginLeft: "5px" }}
                            >
                              Deadline Over
                            </ProjectStatusBadge>
                          ) : null}
                        </>
                      )}
                    </DeadlineText>
                  </ClientDeadlineGrid>
                </div>
                {hasEditAccess ||
                (selectedOrganization && projectDetails.managed) ? (
                  <ActionSection>
                    {(projectType !== "archived" ||
                      isProjectCreator ||
                      hasAdminPrivilege) && (
                      <EditProjectButton
                        type={
                          projectType && projectType === "archived"
                            ? "delete"
                            : "edit"
                        }
                        onClick={() => {
                          if (projectType && projectType === "archived") {
                            toggleDeleteModal(projectDetails.id);
                          } else {
                            history.push(
                              `/user/edit-project?id=${
                                projectDetails && projectDetails.id
                              }`
                            );
                          }
                        }}
                      >
                        {projectType && projectType === "archived"
                          ? "Delete"
                          : "Edit Project"}
                      </EditProjectButton>
                    )}
                    {(hasAdminPrivilege || isProjectCreator) && (
                      <EditProjectButton
                        onClick={() =>
                          toggleArchiveModal(
                            projectDetails && projectDetails.id
                          )
                        }
                        type="archive"
                      >
                        {projectType && projectType === "archived"
                          ? "Unarchive"
                          : "Archive"}
                      </EditProjectButton>
                    )}
                    {hasAdminPrivilege && (
                      <ColoredButton
                        onClick={() => history.push("/user/create-invoice")}
                        type="black"
                        height="36px"
                        style={{ marginBottom: "auto" }}
                        padding="0 8px"
                      >
                        Create Invoice
                      </ColoredButton>
                    )}
                  </ActionSection>
                ) : null}
              </ListUpperSection>
              {projectDetails && projectDetails.description && (
                <CommonText style={{ maxWidth: `550px`, marginTop: `20px` }}>
                  {projectDetails.description}
                </CommonText>
              )}
              <DetailsAssigneeSectionContainer>
                <DetailsAssigneeSection assigneeList={assigneeList} />
              </DetailsAssigneeSectionContainer>
              <DetailTitleSelectSection>
                <CommonText title name>
                  Progress Report
                </CommonText>
                {projectExpensesIsLoading ? (
                  <AssignButtonLoader
                    padding="9px 10px"
                    borderColor="rgb(228, 231, 235)"
                    width="150px"
                  />
                ) : (
                  <Select
                    isSearchable={false}
                    value={timeDurationValue}
                    options={graphDurationOptions}
                    placeholder=""
                    onChange={(e) => {
                      onGraphDurationChange(e);
                    }}
                    styles={CreateProjectDropdown(null)}
                  />
                )}
              </DetailTitleSelectSection>
              <ChartContainer>
                <div style={{ maxWidth: `180px` }}>
                  <Select
                    isSearchable={false}
                    value={graphTypeValue}
                    options={graphTypeOptions}
                    placeholder=""
                    onChange={(e) => {
                      onGraphTypeChange(e);
                    }}
                    styles={CreateProjectDropdown(null)}
                  />
                </div>
                <div>
                  {graphType &&
                    graphType === "time-spent" &&
                    (timeSpentData && timeSpentData.length > 0 ? (
                      <Line
                        data={(canvas) => getData(canvas, graphData)}
                        options={projectChartOptions(graphType, "")}
                      />
                    ) : (
                      <NoDataGraphSection
                        graphType={graphType}
                        graphDuration={graphDuration}
                      />
                    ))}
                  {graphType &&
                    graphType === "cost" &&
                    (costData && costData.length > 0 ? (
                      <Line
                        data={(canvas) => getData(canvas, graphData)}
                        options={projectChartOptions(
                          graphType,
                          currencySymbolMap[currency]
                        )}
                      />
                    ) : (
                      <NoDataGraphSection
                        graphType={graphType}
                        graphDuration={graphDuration}
                      />
                    ))}
                </div>
              </ChartContainer>
              <ViewLessCardContainer
                style={{ marginBottom: "15px" }}
                padding="15px 0"
              >
                <ViewLessCard>
                  <div>
                    <CommonText fontSize="12px">
                      {projectDetails &&
                      projectDetails.budget &&
                      projectDetails.budget.budget_type === "project_hours"
                        ? "Budgeted Hours"
                        : "Total Budget"}
                    </CommonText>
                    {projectDetails &&
                    projectDetails.budget &&
                    projectDetails.budget.amount ? (
                      <CardTextData margin="4px 0 0" fontSize="14px">
                        {projectDetails.budget.budget_type === "project_fees"
                          ? `${currencySymbolMap[currency]} `
                          : ``}{" "}
                        {projectDetails.budget.budget_type === "project_fees"
                          ? (projectDetails.budget.amount / 100).toFixed(2)
                          : projectDetails.budget.amount}
                        {projectDetails.budget.budget_type === "project_hours"
                          ? `hours`
                          : ``}
                      </CardTextData>
                    ) : (
                      <ProjectCardNoData type="budget" text="Budget not set" />
                    )}
                  </div>
                  {projectDetails &&
                    projectDetails.budget &&
                    projectDetails.budget.budget_left !== null && (
                      <BudgetLeftSection>
                        <ProjectCardLabel>
                          {projectDetails.budget.budget_type === "project_fees"
                            ? "Left"
                            : "Remaining"}
                        </ProjectCardLabel>
                        <ProjectCardText>
                          {projectDetails.budget.budget_type === "project_fees"
                            ? `${currencySymbolMap[currency]} ${(
                                projectDetails.budget.budget_left / 100
                              ).toFixed(2)}`
                            : `${projectDetails.budget.budget_left} hours`}
                        </ProjectCardText>
                      </BudgetLeftSection>
                    )}
                </ViewLessCard>
                <ViewLessCard>
                  <div>
                    <CommonText fontSize="12px">
                      Spent{" "}
                      {projectDetails &&
                      projectDetails.budget &&
                      projectDetails.budget.budget_type === "project_hours"
                        ? "Hours"
                        : "Amount"}
                    </CommonText>
                    {projectDetails &&
                    projectDetails.budget &&
                    projectDetails.budget.amount ? (
                      <CardTextData margin="4px 0 0" fontSize="14px">
                        {projectDetails.budget.budget_type === "project_hours"
                          ? projectDetails.spent_hours
                            ? getHours(projectDetails.spent_hours)
                            : "0h 00m"
                          : projectDetails.budget.cost
                          ? `${currencySymbolMap[currency]} ${(
                              projectDetails.budget.cost / 100
                            ).toFixed(2)}`
                          : `${currencySymbolMap[currency]} 0.00`}
                      </CardTextData>
                    ) : (
                      <ProjectCardNoData type="budget" text="Budget not set" />
                    )}
                  </div>
                  {projectDetails &&
                  projectDetails.budget &&
                  projectDetails.budget.amount ? (
                    <PercentageBarSection>
                      <LinearActivityBar
                        width="100%"
                        height="8px"
                        borderRadius="4px"
                        yellow="#20bead"
                        red="#20bead"
                        green="#20bead"
                        percent={
                          projectDetails.budget.budget_type ===
                            "project_hours" && hoursPercentage
                            ? hoursPercentage
                            : projectDetails.budget.budget_type ===
                                "project_fees" && costPercentage
                            ? costPercentage
                            : 0
                        }
                        marginTop="0"
                        backgroundColor="#B5B5B5"
                      />
                      <PercentageText>
                        {projectDetails.budget.budget_type === "project_fees" &&
                        costPercentage
                          ? costPercentage
                          : projectDetails.budget.budget_type ===
                              "project_hours" && hoursPercentage
                          ? hoursPercentage
                          : 0}
                        %
                      </PercentageText>
                    </PercentageBarSection>
                  ) : null}
                </ViewLessCard>
                <ViewLessCard>
                  <div>
                    <CommonText fontSize="12px">
                      Spent{" "}
                      {projectDetails &&
                      projectDetails.budget &&
                      projectDetails.budget.budget_type === "project_hours"
                        ? "Amount"
                        : "Hours"}
                    </CommonText>
                    {projectDetails &&
                    projectDetails.budget &&
                    projectDetails.budget.budget_type === "project_hours" ? (
                      <ProjectCardNoData type="budget" />
                    ) : projectDetails &&
                      projectDetails.spent_hours &&
                      getHours(projectDetails.spent_hours) !== "00h 00m" ? (
                      <CardTextData margin="4px 0 0" fontSize="14px">
                        {getHours(projectDetails.spent_hours)}
                      </CardTextData>
                    ) : (
                      <ProjectCardNoData
                        type="hour"
                        text="Time not tracked yet"
                      />
                    )}
                  </div>
                </ViewLessCard>
                <ViewLessCard>
                  <div>
                    <CommonText fontSize="12px">Billable Amount</CommonText>
                    {projectDetails && projectDetails.billable_id ? (
                      <CardTextData margin="4px 0 0" fontSize="14px">
                        {currencySymbolMap[currency]}{" "}
                        {projectDetails.billable_amount.toFixed(2)}
                      </CardTextData>
                    ) : (
                      <ProjectCardNoData type="budget" text="Billing not set" />
                    )}
                  </div>
                </ViewLessCard>
                <ViewLessCard>
                  <div>
                    <CommonText fontSize="12px">Uninvoiced Amount</CommonText>
                    {projectDetails && projectDetails.billable_id ? (
                      <CardTextData
                        margin="4px 0 0"
                        fontSize="14px"
                        textColor={
                          projectDetails.uninvoiced_amount &&
                          projectDetails.uninvoiced_amount.toFixed(2) < 0
                            ? "#FE5969"
                            : "#20bead"
                        }
                      >
                        {currencySymbolMap[currency]}{" "}
                        {projectDetails.uninvoiced_amount.toFixed(2)}
                      </CardTextData>
                    ) : (
                      <ProjectCardNoData type="budget" text="Billing not set" />
                    )}
                  </div>
                  {projectDetails && projectDetails.billable_id && (
                    <BudgetLeftSection>
                      <ProjectCardLabel>Invoiced</ProjectCardLabel>
                      <ProjectCardText>
                        {currencySymbolMap[currency]}{" "}
                        {(
                          projectDetails.billable_amount -
                          projectDetails.uninvoiced_amount
                        ).toFixed(2)}
                      </ProjectCardText>
                    </BudgetLeftSection>
                  )}
                </ViewLessCard>
              </ViewLessCardContainer>
            </ProjectDetailsContainer>
            <TabButtonContainer margin="0 auto 0 30px">
              <TabButton
                selected={selectedTab}
                index="tasks"
                onClick={() => handleSelectTab("tasks")}
              >
                Tasks
              </TabButton>
              <TabButton
                selected={selectedTab}
                index="invoices"
                onClick={() => handleSelectTab("invoices")}
              >
                Invoices
              </TabButton>
            </TabButtonContainer>
            <ProjectDetailsInvoiceTaskContainer ref={taskInvoiceRef}>
              {selectedTab === "tasks" ? (
                <TaskSection
                  projectIsLoading={isLoading}
                  projectAssigneeIsLoading={projectAssigneeIsLoading}
                  selectedOrganization={selectedOrganization}
                  projectId={projectId}
                  projectName={projectDetails && projectDetails.name}
                  projectStatus={projectDetails && projectDetails.status}
                  getProjectTaskList={getProjectTaskList}
                  projectTaskList={projectTaskList}
                  getProjectAssigneeList={getProjectAssigneeList}
                  projectAssigneeList={projectAssigneeList}
                  addTask={projectAddTask}
                  editTask={projectEditTask}
                  deleteTask={projectDeleteTask}
                  addTaskAssignee={addTaskAssignee}
                  deleteTaskAssignee={deleteTaskAssignee}
                  taskListLoading={taskListLoading}
                  taskIsLoading={taskIsLoading}
                  loadingTaskId={loadingTaskId}
                  taskAssigneeIsLoading={taskAssigneeIsLoading}
                  loadingTaskAssigneeId={loadingTaskAssigneeId}
                  // pagination
                  taskCount={projectTaskCount}
                  taskPageSize={projectTaskPageSize}
                />
              ) : (
                <InvoiceSection
                  selectedOrganization={selectedOrganization}
                  projectId={projectId}
                  getProjectInvoices={getProjectInvoices}
                  invoicesList={invoicesList}
                  isLoading={projectInvoiceListLoading}
                  history={history}
                  // pagination
                  invoiceCount={projectInvoiceCount}
                  invoicePageSize={projectInvoicePageSize}
                />
              )}
            </ProjectDetailsInvoiceTaskContainer>
          </ProjectListSection>
        )}
      </Container>
    </div>
  );
};

export default ViewDetails;
