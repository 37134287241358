import DashboardIcon from "../../assets/img/Sidebar/dashboard.svg";
import RemoteTrackIcon from "../../assets/img/Sidebar/remote-track.svg";
import TimesheetIcon from "../../assets/img/Sidebar/timesheet.svg";
import ReportsIcon from "../../assets/img/Sidebar/reports.svg";

import TasksIcon from "../../assets/img/Sidebar/tasks.svg";
import ClockInOutIcon from "../../assets/img/Sidebar/clock-in.svg";
import SchedulesIcon from "../../assets/img/Sidebar/schedule.svg";
import AttendanceIcon from "../../assets/img/Sidebar/attendance.svg";
import GPSTrackIcon from "../../assets/img/Sidebar/gps-track.svg";

import ProjectsIcon from "../../assets/img/Sidebar/project.svg";
import InvoiceIcon from "../../assets/img/Sidebar/invoice.svg";
import ClientsIcon from "../../assets/img/Sidebar/clients.svg";

import TeamsIcon from "../../assets/img/Sidebar/teams.svg";
import MembersIcon from "../../assets/img/Sidebar/members.svg";
import PayrollIcon from "../../assets/img/Sidebar/payroll.svg";
import OrganizationsIcon from "../../assets/img/Sidebar/organization.svg";
import IntegrationsIcon from "../../assets/img/Sidebar/integrations.svg";
import SettingsIcon from "../../assets/img/Sidebar/settings.svg";

export const ICON_MAP = {
  dashboardIcon: DashboardIcon,
  remoteTrackIcon: RemoteTrackIcon,
  timesheetIcon: TimesheetIcon,
  reportsIcon: ReportsIcon,
  clockInOutIcon: ClockInOutIcon,
  tasksIcon: TasksIcon,
  schedulesIcon: SchedulesIcon,
  gpsTrackIcon: GPSTrackIcon,
  projectsIcon: ProjectsIcon,
  invoiceIcon: InvoiceIcon,
  clientsIcon: ClientsIcon,
  teamsIcon: TeamsIcon,
  membersIcon: MembersIcon,
  payrollIcon: PayrollIcon,
  integrationsIcon: IntegrationsIcon,
  organizationsIcon: OrganizationsIcon,
  settingsIcon: SettingsIcon,
};
