import { useState, useEffect, Fragment } from "react";
import Select from "../../../components/ReactSelectDropdown";
import CreateProjectDropdown from "../../../components/DropdownStyle/CreateProjectDropdown";
import { CommImageNameComp } from "./CommonComponents";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import {
  AdditionalContainer,
  CollapseContainer,
  TitleContainer,
  IconTitleSection,
  AssigneesManageImgTextGrid,
  SetRateContainer,
  CollapseRotate,
  ProjectBillingSection,
  ProjectBillingTabContainer,
  ProjectBillingtab,
  InputField,
  HourlyRateDropdownSection,
  ProjectHourlyRateInputSection,
  PersonHourlyRateContainer,
  SetRateButton,
  ProjectBudgetUpperGrid,
  ProjectBudgetUpperColumn,
  StyledSlider,
  StyledThumb,
  StyledTrack,
  SliderContainer,
  DropDownGrid,
  ButtonSection,
} from "../../../styledComponents/createProject";
import {
  CommonText,
  CardTitle,
  CommonFlex,
  TableText,
} from "../../../styledComponents/common";
import {
  TabButtonContainer,
  TabButton,
  WhiteButton,
  PrimaryButton,
} from "../../../styledComponents/buttons";

import { currencySymbolMap } from "../../../utils/currencies";

import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";

import Dollar from "../../../assets/img/icons/dollar.svg";
import Collapse from "../../../assets/img/icons/collapse.svg";
import FirstRoundLetterComp from "../../../components/FirstRoundLetter/FirstRoundLetter";
import { getFullName } from "../../../utils/helper";

//REACT SLIDER COMPONENT
const Thumb = (props, state) => <StyledThumb {...props} />;
const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const AddBiilingHours = (props) => {
  const [active, setActive] = useState(false); //COLLAPSE STATE
  const [projectTypeSelect, setProjectTypeSelect] = useState("Billing"); //SELECT BETWEEN PROJECT BIILING & PROJECT BUDGET
  const [projectBillingType, setProjectBillingType] = useState("hourly_basis"); //SELECT BETWEEN HOURLY BASIS & FIXED FEE
  const [hourlyRatesType, setHourlyRatesType] = useState(""); //HOURLY BASIS > HOURLY RATES > DROPDOWN VALUE
  const hourlyRatesOption = [
    { value: "project_hourly", label: "Project Hourly Rate" },
    { value: "person_hourly", label: "Person Hourly Rate" },
  ]; //HOURLY BASIS > HOURLY RATES > DROPDOWN OPTION
  const [projectHourlyRate, setProjectHourlyRate] = useState(""); //HOURLY BASIS > HOURLY RATES > PROJECT HOURLY RATE INPUT
  const [fixedRateValue, setFixedRateValue] = useState(""); //HOURLY BASIS > FIXED RATE INPUT
  const [personHourlyRates, setPersonHourlyRates] = useState([]); //HOURLY BASIS > HOURLY RATES > PERSON HOURLY RATE ASSIGNEE MANAGE
  const [budgetProjectType, setBudgetProjectType] = useState({
    value: "project_fees",
    label: "Total Project Cost",
  }); //PROJECT BUDGET > PROJECT TYPE DROPDOWN VALUE
  const budgetProjectTypeOption = [
    { value: "project_fees", label: "Total Project Cost" },
    { value: "project_hours", label: "Total Project Hours" },
  ]; //PROJECT BUDGET > PROJECT TYPE DROPDOWN OPTION
  const [budgetBasedOnInput, setBudgetBasedOnInput] = useState({
    value: "pay_rate",
    label: "Employee Pay Rate",
  }); //PROJECT BUDGET > BASED ON DROPDOWN VALUE
  const budgetBasedOnOption = [
    { value: "pay_rate", label: "Employee Pay Rate" },
    { value: "bill_rate", label: "Employee Bill Rate" },
  ]; //PROJECT BUDGET > BASED ON DROPDOWN OPTION
  const [budgetCostInput, setBudgetCostInput] = useState(""); //PROJECT BUDGET > COST INPUT
  const [budgetHours, setBudgetHours] = useState(""); //PROJECT BUDGET > BUDGET HOURS
  const [budgetHourlyRates, setBudgetHourlyRates] = useState([]);
  const [sliderValue, setSliderValue] = useState(0); //PROJECT BUDGET > NOTIFY AT > SLIDER
  const [saveErrors, setSaveErrors] = useState({}); //STATE FOR SAVING THE ERRORS
  const [billingBillRateInput, setBillingBillRateInput] = useState([]);
  const [budgetPayRateInput, setBudgetPayRateInput] = useState([]);
  const [budgetBillRateInput, setBudgetBillRateInput] = useState([]);

  useEffect(() => {
    return () => {
      props.clearProjectBilling();
      props.clearProjectBudget();
    };
  }, []);

  useEffect(() => {
    if (props.billingsActive) {
      if (
        props.selectedOrganization &&
        props.selectedOrganization.id &&
        props.projectId
      ) {
        let payload = {
          organization_id: props.selectedOrganization.id,
          project_id: props.projectId,
        };
        props.getProjectAssigneeList(payload);
      }
    }
  }, [props.billingsActive, props.projectId]);

  useEffect(() => {
    if (props.projectAssigneeList && props.projectAssigneeList.length > 0) {
      let assigneeList = [];
      props.projectAssigneeList.map((assignee) => {
        assigneeList.push({
          id: assignee.user.id,
          name: getFullName(assignee.user),
          avatar: assignee.user.avatar,
          color: assignee.user.color || null,
          setRateShow: false,
          projectBudgetPayRate:
            assignee.payment_config && assignee.payment_config.pay_rate
              ? assignee.payment_config.pay_rate
              : null,
          projectBudgetBillRate:
            assignee.payment_config && assignee.payment_config.bill_rate
              ? assignee.payment_config.bill_rate
              : null,
        });
      });
      setBudgetHourlyRates(assigneeList);
    } else if (
      props.projectAssigneeList &&
      props.projectAssigneeList.length === 0
    ) {
      setBudgetHourlyRates([]);
    }
  }, [props.projectAssigneeList]);

  useEffect(() => {
    if (props.projectBudget && props.projectBudget !== null) {
      if (props.projectBudget.budget_type) {
        if (props.projectBudget.budget_type === "project_fees") {
          setBudgetProjectType({
            value: "project_fees",
            label: "Total Project Cost",
          });
          if (props.projectBudget.budget_amount) {
            setBudgetCostInput(
              (props.projectBudget.budget_amount / 100).toFixed(2)
            );
          }
        } else if (props.projectBudget.budget_type === "project_hours") {
          setBudgetProjectType({
            value: "project_hours",
            label: "Total Project Hours",
          });
          if (props.projectBudget.budget_hours) {
            setBudgetHours(props.projectBudget.budget_hours);
          }
        }
      }
      if (props.projectBudget.budget_hourly_based_on) {
        if (props.projectBudget.budget_hourly_based_on === "pay_rate") {
          setBudgetBasedOnInput({
            value: "pay_rate",
            label: "Employee Pay Rate",
          });
        } else if (props.projectBudget.budget_hourly_based_on === "bill_rate") {
          setBudgetBasedOnInput({
            value: "bill_rate",
            label: "Employee Bill Rate",
          });
        }
      }
    } else if (!props.projectBudget) {
      clearBudgetState();
    }
  }, [props.projectBudget]);

  useEffect(() => {
    if (props.projectBilling && props.projectBilling !== null) {
      if (props.projectBilling.billable_type) {
        setProjectBillingType(props.projectBilling.billable_type);
        if (props.projectBilling.billable_type === "hourly_basis") {
          setFixedRateValue("");
          if (props.projectBilling.hourly_rate_based_on) {
            if (
              props.projectBilling.hourly_rate_based_on === "project_hourly"
            ) {
              setHourlyRatesType({
                value: "project_hourly",
                label: "Project Hourly Rate",
              });
              if (props.projectBilling.project_hourly) {
                setProjectHourlyRate(
                  (props.projectBilling.project_hourly.rate / 100).toFixed(2)
                );
              }
            } else if (
              props.projectBilling.hourly_rate_based_on === "person_hourly"
            ) {
              setHourlyRatesType({
                value: "person_hourly",
                label: "Person Hourly Rate",
              });
              if (
                props.projectBilling.person_hourlies &&
                props.projectBilling.person_hourlies.length > 0 &&
                props.projectAssigneeList &&
                props.projectAssigneeList.length > 0
              ) {
                let assigneeList = [];
                props.projectAssigneeList.map((assignee) => {
                  let object = {
                    id: assignee.user.id,
                    name: getFullName(assignee.user),
                    avatar: assignee.user.avatar,
                    setRateShow: false,
                    color: assignee.user.color || null,
                  };
                  props.projectBilling.person_hourlies.map((item) => {
                    if (item.user.id === assignee.user.id) {
                      if (item.rate) {
                        object.projectBillingBillRate = item.rate;
                      } else {
                        object.projectBillingBillRate = null;
                      }
                    }
                  });
                  assigneeList.push(object);
                });
                setPersonHourlyRates([...assigneeList]);
              }
            }
          }
        } else if (props.projectBilling.billable_type === "fixed_price") {
          setHourlyRatesType("");
          setProjectHourlyRate("");
          if (props.projectBilling.billable_amount) {
            setFixedRateValue(
              (props.projectBilling.billable_amount / 100).toFixed(2)
            );
          }
        }
      }
    } else if (!props.projectBilling) {
      clearBillingState();
    }
  }, [props.projectBilling, props.projectAssigneeList]);

  // ----- FUNCTION -----

  const toggleCollapse = () => {
    setActive(!active);
  };

  //SELECT BETWEEN PROJECT BIILING & PROJECT BUDGET
  const selectProjectType = (value) => {
    setProjectTypeSelect(value);
    if (value === "Budget") {
      if (
        props.projectAssigneeList &&
        props.projectAssigneeList.length > 0 &&
        budgetHourlyRates &&
        !budgetHourlyRates.length
      ) {
        let assigneeList = [];
        props.projectAssigneeList.map((assignee) => {
          assigneeList.push({
            id: assignee.user.id,
            name: getFullName(assignee.user),
            avatar: assignee.user.avatar,
            color: assignee.user.color || null,
            setRateShow: false,
            projectBudgetPayRate:
              assignee.payment_config && assignee.payment_config.pay_rate
                ? assignee.payment_config.pay_rate
                : null,
            projectBudgetBillRate:
              assignee.payment_config && assignee.payment_config.bill_rate
                ? assignee.payment_config.bill_rate
                : null,
          });
        });
        setBudgetHourlyRates(assigneeList);
      } else if (
        props.projectAssigneeList &&
        props.projectAssigneeList.length === 0 &&
        budgetHourlyRates &&
        !budgetHourlyRates.length
      ) {
        setBudgetHourlyRates([]);
      }
    }
  };

  //SELECT BETWEEN HOURLY BASIS & FIXED FEE
  const changeBillingType = (value) => {
    setProjectBillingType(value);
  };

  //SELECT HOURLY RATES TYPE
  const handleHourlyRatesType = (e) => {
    setHourlyRatesType(e);
    if (e.value === "person_hourly") {
      if (
        props.projectAssigneeList &&
        props.projectAssigneeList.length > 0 &&
        personHourlyRates &&
        !personHourlyRates.length
      ) {
        let assigneeList = [];
        props.projectAssigneeList.map((assignee) => {
          assigneeList.push({
            id: assignee.user.id,
            name: getFullName(assignee.user),
            avatar: assignee.user.avatar,
            color: assignee.user.color || null,
            setRateShow: false,
            projectBillingBillRate:
              assignee.payment_config && assignee.payment_config.bill_rate
                ? assignee.payment_config.bill_rate
                : null,
          });
        });
        setPersonHourlyRates(assigneeList);
      } else if (
        props.projectAssigneeList &&
        props.projectAssigneeList.length === 0 &&
        personHourlyRates &&
        !personHourlyRates.length
      ) {
        setPersonHourlyRates([]);
      }
    }
  };

  //HOURLY BASIS > HOURLY RATES > PROJECT HOURLY RATE INPUT
  const handleProjectHourlyRate = (e) => {
    const { value } = e.target;
    setSaveErrors({});
    setProjectHourlyRate(value);
  };

  //HOURLY BASIS > FIXED RATE INPUT
  const fixedRateFunc = (e) => {
    const { value } = e.target;
    setSaveErrors({});
    setFixedRateValue(value);
  };

  //PROJECT BUDGET > COST INPUT
  const budgetCostInputFunc = (e) => {
    const { value } = e.target;
    setSaveErrors({});
    setBudgetCostInput(value);
  };

  //PROJECT BUDGET > BUDGET HOURS
  const budgetHoursInputFunc = (e) => {
    const { value } = e.target;
    setSaveErrors({});
    setBudgetHours(value);
  };

  //HOURLY BASIS > HOURLY RATES > PERSON HOURLY RATE > ASSIGNEE INPUT & SET RATE FUNC
  const personRateInputFunc = (e, index) => {
    const { value } = e.target;
    let newArr = [...billingBillRateInput];
    newArr[index] = value;
    setBillingBillRateInput(newArr);
  };
  const personRateSetFunc = (index, saveMode) => {
    let newArr = [...personHourlyRates];
    let inputArr = [...billingBillRateInput];
    if (newArr[index].projectBillingBillRate) {
      inputArr[index] = (newArr[index].projectBillingBillRate / 100).toFixed(2);
    } else {
      inputArr[index] = "";
    }
    setBillingBillRateInput(inputArr);
    newArr[index].setRateShow = !newArr[index].setRateShow;
    if (saveMode) {
      newArr[index].projectBillingBillRate = parseInt(
        billingBillRateInput[index] * 100
      );
      inputArr[index] = "";
      setBillingBillRateInput(inputArr);
    }
    setPersonHourlyRates(newArr);
  };

  //PROJECT BUDGET TAB > ASSIGNEES HOUR RATE FUNCTION
  const budgetAssigneeRateInputFunc = (e, index) => {
    const { value } = e.target;
    let newArr;
    if (budgetBasedOnInput.value === "pay_rate") {
      newArr = [...budgetPayRateInput];
      newArr[index] = value;
      setBudgetPayRateInput(newArr);
    } else if (budgetBasedOnInput.value === "bill_rate") {
      newArr = [...budgetBillRateInput];
      newArr[index] = value;
      setBudgetBillRateInput(newArr);
    }
  };
  const budgetAssigneeRateSetFunc = (index, saveMode, userId) => {
    let newArr = [...budgetHourlyRates];
    let inputArr;
    if (budgetBasedOnInput.value === "pay_rate") {
      inputArr = [...budgetPayRateInput];
      if (newArr[index].projectBudgetPayRate) {
        inputArr[index] = (newArr[index].projectBudgetPayRate / 100).toFixed(2);
      } else {
        inputArr[index] = "";
      }
      setBudgetPayRateInput(inputArr);
    } else if (budgetBasedOnInput.value === "bill_rate") {
      inputArr = [...budgetBillRateInput];
      if (newArr[index].projectBudgetBillRate) {
        inputArr[index] = (newArr[index].projectBudgetBillRate / 100).toFixed(
          2
        );
      } else {
        inputArr[index] = "";
      }
      setBudgetBillRateInput(inputArr);
    }
    newArr[index].setRateShow = !newArr[index].setRateShow;
    if (saveMode) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        user_id: userId,
        currency: props.currency || "USD",
      };
      if (budgetBasedOnInput.value === "pay_rate") {
        newArr[index].projectBudgetPayRate = parseInt(
          budgetPayRateInput[index] * 100
        );
        payload.pay_rate = parseInt(budgetPayRateInput[index] * 100);
        if (newArr[index].projectBudgetBillRate) {
          payload.bill_rate = newArr[index].projectBudgetBillRate;
        } else {
          payload.bill_rate = null;
        }
        props.setPaymentConfiguration(payload);
        inputArr[index] = "";
        setBudgetPayRateInput(inputArr);
      } else if (budgetBasedOnInput.value === "bill_rate") {
        newArr[index].projectBudgetBillRate = parseInt(
          budgetBillRateInput[index] * 100
        );
        payload.bill_rate = parseInt(budgetBillRateInput[index] * 100);
        if (newArr[index].projectBudgetPayRate) {
          payload.pay_rate = newArr[index].projectBudgetPayRate;
        } else {
          payload.pay_rate = null;
        }
        props.setPaymentConfiguration(payload);
        inputArr[index] = "";
        setBudgetBillRateInput(inputArr);
      }
    }
    setBudgetHourlyRates(newArr);
  };

  //PROJECT BUDGET > SLIDER VALUE
  const onSliderValueChange = (e) => {
    setSliderValue(e);
  };

  const handleBillingSave = () => {
    if (checkBillingError()) {
      if (
        props.selectedOrganization &&
        props.selectedOrganization.id &&
        props.projectId
      ) {
        let payload = {
          organization_id: props.selectedOrganization.id,
          project_id: props.projectId,
          billable_type: projectBillingType,
          hourly_rate_based_on:
            projectBillingType === "hourly_basis"
              ? hourlyRatesType.value
              : null,
        };
        if (projectBillingType === "fixed_price") {
          payload.billable_amount = parseInt(fixedRateValue * 100);
        }
        if (
          projectBillingType === "hourly_basis" &&
          hourlyRatesType &&
          hourlyRatesType.value === "project_hourly" &&
          projectHourlyRate
        ) {
          payload.project_hourly = {
            rate: parseInt(projectHourlyRate * 100),
            currency: props.currency || "USD",
          };
        }
        if (
          projectBillingType === "hourly_basis" &&
          hourlyRatesType &&
          hourlyRatesType.value === "person_hourly"
        ) {
          let personHourlies = [];
          if (personHourlyRates && personHourlyRates.length > 0) {
            personHourlyRates.map((person) => {
              if (person.projectBillingBillRate) {
                personHourlies.push({
                  user_id: person.id,
                  rate: person.projectBillingBillRate,
                  currency: props.currency || "USD",
                });
              }
            });
          }
          payload.person_hourlies = personHourlies;
        }
        if (props.projectBilling && props.projectBilling !== null) {
          props.updateProjectBilling(payload);
        } else if (!props.projectBilling) {
          props.addProjectBilling(payload);
        }
      }
    }
  };

  const checkBillingError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (projectBillingType === "hourly_basis" && hourlyRatesType === "") {
      saveErrors["hourlyRate"] = "Please select the hourly rate type first.";
      formIsValid = false;
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleBudgetSave = () => {
    if (checkBudgetError()) {
      if (
        props.selectedOrganization &&
        props.selectedOrganization.id &&
        props.projectId
      ) {
        let payload = {
          organization_id: props.selectedOrganization.id,
          project_id: props.projectId,
          budget_type: budgetProjectType.value,
          budget_amount:
            budgetProjectType.value === "project_fees"
              ? parseInt(budgetCostInput * 100)
              : 0,
          budget_hours:
            budgetProjectType.value === "project_hours"
              ? parseInt(budgetHours)
              : 0,
          budget_hourly_based_on:
            budgetProjectType.value === "project_fees"
              ? budgetBasedOnInput.value
              : null,
          notify_at: sliderValue && sliderValue > 0 ? sliderValue : null,
        };
        if (props.projectBudget && props.projectBudget !== null) {
          props.updateProjectBudget(payload);
        } else if (!props.projectBudget) {
          props.addProjectBudget(payload);
        }
      }
    }
  };

  const checkBudgetError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (budgetProjectType === "") {
      formIsValid = false;
      saveErrors["budgetProjectType"] = "Please select a budget type first.";
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const cancelBillingSave = () => {
    // setActive(false);
    props.setBillingsActive(false);
  };

  const cancelBudgetSave = () => {
    // setActive(false);
    props.setBillingsActive(false);
  };

  const clearBillingState = () => {
    setProjectBillingType("hourly_basis");
    setHourlyRatesType("");
    setProjectHourlyRate("");
    setFixedRateValue("");
  };

  const clearBudgetState = () => {
    setBudgetProjectType({
      value: "project_fees",
      label: "Total Project Cost",
    });
    setBudgetBasedOnInput({
      value: "pay_rate",
      label: "Employee Pay Rate",
    });
    setBudgetCostInput("");
  };

  return (
    <AdditionalContainer
      active={props.billingsActive}
      style={{
        borderTop: props.billingsActive
          ? `1px solid #20bead`
          : `1px solid #c2cce1`,
      }}
    >
      <TitleContainer onClick={() => props.toggleBillings()}>
        <IconTitleSection>
          <div>
            <img src={Dollar} alt="addPeople" width="27px" />
          </div>
          <CardTitle fontSize="16px">Add Billings & Hours</CardTitle>
        </IconTitleSection>
        <CollapseRotate active={props.billingsActive}>
          <img src={Collapse} alt="collapse" width="100%" />
        </CollapseRotate>
      </TitleContainer>

      <CollapseContainer active={props.billingsActive}>
        <TabButtonContainer margin="20px auto 15px 0">
          <TabButton
            onClick={() => selectProjectType("Billing")}
            selected={projectTypeSelect}
            index={"Billing"}
          >
            Project Billing
          </TabButton>
          <TabButton
            onClick={() => selectProjectType("Budget")}
            selected={projectTypeSelect}
            index={"Budget"}
          >
            Project Budget
          </TabButton>
        </TabButtonContainer>
        <CommonText>
          {projectTypeSelect === "Billing"
            ? `Set up your project billing on an hourly basis or fixed rate to invoice your client.`
            : `Set up your project budget to get notified on milestones, keep track of the project's cost and profitability.`}
        </CommonText>
        <ProjectBillingSection>
          {projectTypeSelect === "Billing" ? (
            //PROJECT BIILING ---
            <>
              <ProjectBillingTabContainer>
                <ProjectBillingtab
                  onClick={() => changeBillingType("hourly_basis")}
                  index={"hourly_basis"}
                  projectBillingType={projectBillingType}
                >
                  Hourly Basis
                </ProjectBillingtab>
                <ProjectBillingtab
                  onClick={() => changeBillingType("fixed_price")}
                  index={"fixed_price"}
                  projectBillingType={projectBillingType}
                >
                  Fixed Fee
                </ProjectBillingtab>
              </ProjectBillingTabContainer>
              <InfoTooltip
                label
                mainText={
                  projectBillingType === "hourly_basis"
                    ? `Hourly Rates`
                    : `Project Fee`
                }
                toolTipText={
                  projectBillingType === "hourly_basis"
                    ? `We need hourly rates to track your projectʼs billable amount.`
                    : `Enter the amount you plan to invoice.`
                }
                padding="10px 25px"
                marginTop="10px"
              />
              {projectBillingType === "hourly_basis" ? (
                // HOURLY BASIS ---
                <>
                  <HourlyRateDropdownSection>
                    <Select
                      isSearchable
                      value={hourlyRatesType}
                      options={hourlyRatesOption}
                      placeholder="Select hourly rate type"
                      onChange={(e) => {
                        handleHourlyRatesType(e);
                      }}
                      styles={CreateProjectDropdown(null)}
                    />
                    {hourlyRatesType &&
                      hourlyRatesType.value === "project_hourly" && (
                        //HOURLY BASIS > PROJECT HOURLY RATE ---
                        <ProjectHourlyRateInputSection>
                          <CommonText>
                            {props.currency} (
                            {currencySymbolMap[props.currency]})
                          </CommonText>
                          <InputField
                            type="text"
                            placeholder="00.00"
                            value={projectHourlyRate}
                            onChange={(e) => handleProjectHourlyRate(e)}
                          />
                        </ProjectHourlyRateInputSection>
                        // --- HOURLY BASIS > PROJECT HOURLY RATE
                      )}
                  </HourlyRateDropdownSection>
                  {hourlyRatesType &&
                    hourlyRatesType.value === "person_hourly" &&
                    personHourlyRates &&
                    personHourlyRates.length > 0 && (
                      // HOURLY BASIS > PERSON HOURLY RATE ---
                      <PersonHourlyRateContainer>
                        <CommonText $label style={{ marginBottom: `10px` }}>
                          Assignees
                        </CommonText>
                        <CommonText $label style={{ marginBottom: `10px` }}>
                          Set Bill Rate
                        </CommonText>

                        {personHourlyRates.map((personRate, index) => (
                          <Fragment key={index}>
                            {personRate.avatar ? (
                              <CommImageNameComp
                                imageSize="36px"
                                personImg={
                                  <img
                                    src={personRate.avatar}
                                    alt="addPeople"
                                    style={{
                                      width: "36px",
                                      height: "36px",
                                      borderRadius: "50%",
                                      border: `1px solid #fff`,
                                    }}
                                  />
                                }
                                personName={personRate.name}
                              />
                            ) : (
                              <CommonFlex gap="15px">
                                <FirstRoundLetterComp
                                  size="36px"
                                  text={personRate.name}
                                  backColor={index}
                                  fontSize="12px"
                                  color={personRate.color || null}
                                />
                                <TableText name>{personRate.name}</TableText>
                              </CommonFlex>
                            )}

                            <SetRateContainer>
                              {personRate.setRateShow ? (
                                <InputField
                                  height="32px"
                                  type="text"
                                  placeholder="00.00"
                                  value={
                                    billingBillRateInput[index]
                                      ? billingBillRateInput[index]
                                      : ""
                                  }
                                  onChange={(e) =>
                                    personRateInputFunc(e, index)
                                  }
                                />
                              ) : (
                                <CommonText name fontSize="13px">
                                  {personRate.projectBillingBillRate
                                    ? `${currencySymbolMap[props.currency]} ${(
                                        personRate.projectBillingBillRate / 100
                                      ).toFixed(2)}`
                                    : `Missing rate!`}
                                </CommonText>
                              )}
                              <SetRateButton
                                onClick={() =>
                                  personRateSetFunc(
                                    index,
                                    personRate.setRateShow
                                  )
                                }
                                active={personRate.setRateShow}
                              >
                                {personRate.setRateShow
                                  ? `Set Rate`
                                  : `Edit Rate`}
                              </SetRateButton>
                            </SetRateContainer>
                          </Fragment>
                        ))}
                      </PersonHourlyRateContainer>
                      // --- HOURLY BASIS > PERSON HOURLY RATE
                    )}
                </>
              ) : (
                // --- HOURLY BASIS
                // FIXED FEE ---
                <HourlyRateDropdownSection>
                  <CommonText>Set project fee</CommonText>
                  <ProjectHourlyRateInputSection>
                    <CommonText>
                      {props.currency} ({currencySymbolMap[props.currency]})
                    </CommonText>
                    <InputField
                      type="text"
                      placeholder="00.00"
                      value={fixedRateValue}
                      onChange={(e) => fixedRateFunc(e)}
                    />
                  </ProjectHourlyRateInputSection>
                </HourlyRateDropdownSection>
                // --- FIXED FEE
              )}
              <CommonFlex
                padding="15px 25px 0px"
                gap="15px"
                margin="15px 0 0 0"
              >
                <WhiteButton
                  padding="0 30px"
                  type="cancel"
                  onClick={() => cancelBillingSave()}
                >
                  Cancel
                </WhiteButton>
                <PrimaryButton
                  padding={props.saveBillingLoading ? "0 20px" : "0 40px"}
                  onClick={() => handleBillingSave()}
                >
                  {props.saveBillingLoading ? (
                    <ButtonTextLoader fontSize="13px" loadingText="Saving" />
                  ) : (
                    "Save"
                  )}
                </PrimaryButton>
              </CommonFlex>
            </>
          ) : (
            // --- PROJECT BIILING
            // PROJECT BUDGET ---
            <>
              <ProjectBudgetUpperGrid>
                <ProjectBudgetUpperColumn>
                  <InfoTooltip
                    mainText="Budget Type"
                    toolTipText="Budget your project based on total project cost or total hours"
                    marginTop="10px"
                    label
                  />
                  <DropDownGrid>
                    <Select
                      isSearchable
                      value={budgetProjectType}
                      options={budgetProjectTypeOption}
                      placeholder="Select..."
                      onChange={(e) => {
                        setBudgetProjectType(e);
                      }}
                      styles={CreateProjectDropdown(null)}
                    />
                  </DropDownGrid>
                  <CommonText $label>
                    {budgetProjectType.value === "project_fees"
                      ? "Cost"
                      : "Hours"}
                  </CommonText>
                  <ProjectHourlyRateInputSection>
                    {budgetProjectType.value === "project_fees" && (
                      <CommonText>
                        {props.currency} ({currencySymbolMap[props.currency]})
                      </CommonText>
                    )}
                    <InputField
                      type="text"
                      placeholder={
                        budgetProjectType.value === "project_fees"
                          ? `00.00`
                          : `00`
                      }
                      value={
                        budgetProjectType.value === "project_fees"
                          ? budgetCostInput
                          : budgetHours
                      }
                      onChange={(e) => {
                        budgetProjectType.value === "project_fees"
                          ? budgetCostInputFunc(e)
                          : budgetHoursInputFunc(e);
                      }}
                    />
                    {budgetProjectType.value === "project_hours" && (
                      <CommonText>Hours</CommonText>
                    )}
                  </ProjectHourlyRateInputSection>
                </ProjectBudgetUpperColumn>
                <ProjectBudgetUpperColumn
                  style={{
                    alignContent:
                      budgetProjectType.value === "project_hours"
                        ? "start"
                        : "none",
                  }}
                >
                  {budgetProjectType.value === "project_fees" && (
                    <>
                      <InfoTooltip
                        mainText="Based On"
                        toolTipText={`Pay Rate – Rate you pay your team member/s.`}
                        moreText={`Bill Rate – Rate you, bill clients, for the time tracked by your team member/s.`}
                        marginTop="10px"
                        label
                      />
                      <DropDownGrid>
                        <Select
                          isSearchable
                          value={budgetBasedOnInput}
                          options={budgetBasedOnOption}
                          placeholder="Select..."
                          onChange={(e) => {
                            setBudgetBasedOnInput(e);
                          }}
                          styles={CreateProjectDropdown(null)}
                        />
                      </DropDownGrid>
                    </>
                  )}
                  <CommonText
                    $label
                    style={{
                      margin:
                        budgetProjectType.value === "project_fees"
                          ? "auto 0"
                          : "10px 0 0",
                    }}
                  >
                    Notify At
                  </CommonText>
                  <SliderContainer>
                    <StyledSlider
                      renderTrack={Track}
                      renderThumb={Thumb}
                      onChange={(e) => onSliderValueChange(e)}
                    />
                    <CommonText
                      name
                      title
                      fontSize="13px"
                      style={{ textAlign: `end` }}
                    >
                      {`${sliderValue}%`}
                    </CommonText>
                    <CommonText>of total budget.</CommonText>
                  </SliderContainer>
                </ProjectBudgetUpperColumn>
              </ProjectBudgetUpperGrid>

              {budgetProjectType.value === "project_fees" &&
                budgetHourlyRates &&
                budgetHourlyRates.length > 0 && (
                  <PersonHourlyRateContainer>
                    <CommonText $label margin="0 0 10px 0">
                      Assignees
                    </CommonText>
                    <CommonText $label margin="0 0 10px 0">
                      {`Set ${
                        budgetBasedOnInput.value === "pay_rate" ? `Pay` : `Bill`
                      } Rate`}
                    </CommonText>

                    {budgetHourlyRates.map((personRate, index) => (
                      <Fragment key={index}>
                        {personRate.avatar ? (
                          <CommImageNameComp
                            imageSize="36px"
                            personImg={
                              <img
                                src={personRate.avatar}
                                alt="addPeople"
                                style={{
                                  width: "36px",
                                  height: "36px",
                                  borderRadius: "50%",
                                  border: `1px solid #fff`,
                                }}
                              />
                            }
                            personName={personRate.name}
                          />
                        ) : (
                          <CommonFlex gap="15px">
                            <FirstRoundLetterComp
                              size="36px"
                              text={personRate.name}
                              backColor={index}
                              fontSize="12px"
                              color={personRate.color || null}
                            />
                            <TableText name>{personRate.name}</TableText>
                          </CommonFlex>
                        )}

                        <SetRateContainer>
                          {personRate.setRateShow ? (
                            <InputField
                              type="text"
                              placeholder="00.00"
                              value={
                                budgetBasedOnInput.value === "pay_rate"
                                  ? budgetPayRateInput[index]
                                    ? budgetPayRateInput[index]
                                    : ""
                                  : budgetBillRateInput[index]
                                  ? budgetBillRateInput[index]
                                  : ""
                              }
                              onChange={(e) =>
                                budgetAssigneeRateInputFunc(e, index)
                              }
                            />
                          ) : (
                            <CommonText name fontSize="13px">
                              {budgetBasedOnInput.value === "pay_rate"
                                ? personRate.projectBudgetPayRate
                                  ? `${currencySymbolMap[props.currency]} ${(
                                      personRate.projectBudgetPayRate / 100
                                    ).toFixed(2)}`
                                  : `Missing Rate`
                                : personRate.projectBudgetBillRate
                                ? `${currencySymbolMap[props.currency]} ${(
                                    personRate.projectBudgetBillRate / 100
                                  ).toFixed(2)}`
                                : `Missing Rate`}
                            </CommonText>
                          )}
                          <SetRateButton
                            onClick={() =>
                              budgetAssigneeRateSetFunc(
                                index,
                                personRate.setRateShow,
                                personRate.id
                              )
                            }
                            setRate={personRate.setRateShow}
                          >
                            {personRate.setRateShow ? `Set Rate` : `Edit Rate`}
                          </SetRateButton>
                        </SetRateContainer>
                      </Fragment>
                    ))}
                  </PersonHourlyRateContainer>
                )}
              <CommonFlex
                padding="15px 25px 0px"
                gap="15px"
                margin="15px 0 0 0"
              >
                <WhiteButton
                  padding="0 30px"
                  type="cancel"
                  onClick={() => cancelBudgetSave()}
                >
                  Cancel
                </WhiteButton>
                <PrimaryButton
                  padding={props.saveBudgetLoading ? "0 20px" : "0 40px"}
                  onClick={() => handleBudgetSave()}
                >
                  {props.saveBudgetLoading ? (
                    <ButtonTextLoader fontSize="13px" loadingText="Saving" />
                  ) : (
                    "Save"
                  )}
                </PrimaryButton>
              </CommonFlex>
            </>
            // --- PROJECT BUDGET
          )}
        </ProjectBillingSection>
      </CollapseContainer>
    </AdditionalContainer>
  );
};

export default AddBiilingHours;
